import FilePreview from "../../../components/Files/Preview";
import { useApi } from "@raiden/library/hooks/useApi";
import generateApiUrl from "@raiden/library/libraries/utils/generateApiUrl";
import { FileType } from "@raiden/library/types/File";
import { DataHandler } from "../../../components/DataHandler";

/**
 * @typedef Props
 * @property {import("@raiden/library/types/File").File} resource,
 */
/**
 * @param {import("react").PropsWithChildren<Props>} props
 * @return {import("react").FunctionComponentElement<Props>}
 */
export default function FileDetailPreview({ resource }) {
  // Type is a native file here, not a resource file
  /** @type {import("@raiden/library/hooks/useApi").UseApi<{ blob: File }>} */
  const { swrResponse: swrResponseFile } = useApi(
    generateApiUrl({
      id: "@api.files.download",
      parameters: {
        fileId: resource?.id,
      },
    }),
    {
      method: "GET",
      outputMode: "file",
    },
  );

  return (
    <DataHandler swrResponse={swrResponseFile} keepDataOnRevalidation>
      {({ data: file }) => <FilePreview blob={file.blob} />}
    </DataHandler>
  );
}

FileDetailPreview.propTypes = {
  resource: FileType,
};

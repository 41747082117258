// deps
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import useSWR from "swr";

// hooks
import useApiFetcher from "../../hooks/useApiFetcher";

// contexts
import { SearchContext } from ".";
import { usePreferences } from "../../contexts/Preferences";

// libraries
import encodeQuery from "@splitfire-agency/raiden-library/dist/libraries/utils/encodeQuery";
import parseQuery from "@splitfire-agency/raiden-library/dist/libraries/utils/parseQuery";

// constants
import { useRouter } from "next/router";
import { PER_PAGES } from "../../constants/api";
import { SESSION_STORAGE_BASE_SEARCH_SAVED_FIELDS } from "../../constants/storage";

// contexts
import initialRouterSyncUrl from "../defautlPushToUrl";

/**
 * Fonction de normalisation des query
 * @param {object} [query]
 * @returns {string}
 */
function normalizeQuery(query) {
  const rawQuery = [];

  for (const [fieldName, fieldValue] of Object.entries(query)) {
    if (Array.isArray(fieldValue)) {
      fieldValue.forEach(function (fieldValue) {
        rawQuery.push(`${fieldName}=${fieldValue}`);
      });
    } else {
      rawQuery.push(`${fieldName}=${fieldValue}`);
    }
  }

  return parseQuery(rawQuery.join("&"));
}

/**
 * Initiliase les valeurs par défaut du formulaire.
 * @param {object} param0
 * @param {boolean} param0.paginated
 * @param {number} param0.paginationPerPage
 * @param {object} [param0.defaultValues]
 * @param {object} [param0.queryValues]
 * @returns {object}
 */
const initialDefaultValues = function ({
  paginated,
  paginationPerPage,
  defaultValues = {},
  queryValues = {},
}) {
  const initialDefaultValues = { ...defaultValues, ...queryValues };
  if (paginated && null == initialDefaultValues.per_page) {
    initialDefaultValues.per_page = paginationPerPage;
  }
  return initialDefaultValues;
};

const initialPaginationUrl = function ({ fields }) {
  return `${encodeQuery(fields, {
    appendPrefix: true,
  })}`;
};

/**
 * @typedef {object} Props
 * @property {string} searchId
 * @property {(params: object) => string | null} endpointUrl
 * @property {false | ((params: { fields: object }) => void)} [paginationUrl]
 * @property {boolean} [paginated]
 * @property {Array<number>} [displayedPerPages]
 * @property {false | ((params: { fields: object }) => void)} [routerSyncUrl]
 * @property {boolean} [useSessionStorageHydration]
 * @property {boolean} [preventSubmitIfNotDirty]
 * @property {number} [refreshInterval]
 * @property {object} [defaultValues]
 * @property {boolean} [preventSubmitIfNotValid]
 */

/**
 * @param {import("react").PropsWithChildren<Props>} props
 * @return {import("react").FunctionComponentElement<Props>}
 */
function SearchProvider(props) {
  const {
    searchId,
    endpointUrl,
    paginated = true,
    displayedPerPages = PER_PAGES,
    preventSubmitIfNotDirty = false,
    paginationUrl = initialPaginationUrl,
    routerSyncUrl = initialRouterSyncUrl,
    useSessionStorageHydration = true,
    children,
    refreshInterval = 0,
  } = props;

  const { paginationPerPage } = usePreferences();

  const router = useRouter();

  const $mounted = useRef(false);

  /**
   * Valeurs par défaut du formulaire.
   * on initialise les paramètres avec la logique suivante
   *  1) on récupère les valeurs de la props defaultValues
   *  2) si on a un routerSyncUrl, on récupère les valeurs du router que l'on surcharge à l'objet retourné par le 1)
   */
  const defaultValues = initialDefaultValues({
    paginationPerPage,
    queryValues:
      "function" === typeof routerSyncUrl && router.query
        ? normalizeQuery(router.query)
        : {},
    defaultValues: props.defaultValues,
    paginated,
  });

  const [mounted, setMounted] = useState(false);

  const form = useForm({
    defaultValues,
  });
  const { watch, setValue, handleSubmit: _handleSubmit, reset } = form;

  const [submittedFields, setSubmittedFields] = useState(defaultValues);

  /**
   * Réinitialise la pagination lors d'un changement de filtre.
   */
  useEffect(
    function () {
      if (mounted && paginated) {
        const subscription = watch(function (value, { name }) {
          if (name !== "page") {
            setValue("page", 1, { shouldDirty: true });
          }
        });
        return () => subscription.unsubscribe();
      }
    },
    [paginated, watch, setValue, mounted],
  );

  const [swrOptions, setSwrOptions] = useState({
    refreshInterval: 0,
  });

  /**
   * Synchronise l'URL du router avec les valeurs du formulaire.
   * @param {object} options
   * @param {object} options.fields
   * @return {void}
   */
  const updateRouterUrl = useCallback(
    function updateRouterUrl({ fields }) {
      if ("function" === typeof routerSyncUrl) {
        router.replace(
          `${router.pathname}${encodeQuery(routerSyncUrl({ fields }), {
            appendPrefix: true,
          })}`,
          undefined,
          { shallow: true },
        );
      }
    },
    [router, routerSyncUrl],
  );

  const apiFetcher = useApiFetcher();

  // TODO: rajouter un useEffect quand isFormValid est initialisé à false (réfléchir aux conditions, vérification du formulaire, que le composant est monté)
  const response = useSWR(
    mounted && endpointUrl ? endpointUrl({ fields: submittedFields }) : null,
    apiFetcher,
    {
      refreshInterval: refreshInterval,
    },
  );

  const { mutate } = response;

  /**
   * Gestion du search.
   */
  const search = useMemo(() => {
    /**
     * @param {object} options
     * @param {boolean} [options.shouldUpdateRouterUrl]
     * @param {boolean} [options.preventMutate]
     */
    return function ({
      shouldUpdateRouterUrl = true,
      preventMutate = false,
    } = {}) {
      /**
       * @param {(fields) => void} callback
       */
      _handleSubmit(function (fields) {
        // ce teste permet de soumettre le formulaire uniquement si les valeurs n'ont pas changé (cas ou l'on soumet le formulaire sans avoir changé les valeurs)
        // on utilisera la fonction mutate de swr pour forcer le rafraichissement
        if (
          !preventMutate &&
          endpointUrl({ fields: submittedFields }) === endpointUrl({ fields })
        ) {
          mutate();
        } else {
          // récupère la liste des valeurs soumises (après un click sur le bouton ou au mount du search) du formulaire (visible) pour les soumettre
          setSubmittedFields(fields);
          if (shouldUpdateRouterUrl) {
            updateRouterUrl({ fields });
          }
          if (useSessionStorageHydration) {
            if (searchId) {
              window.sessionStorage.setItem(
                `${SESSION_STORAGE_BASE_SEARCH_SAVED_FIELDS}-${searchId}`,
                JSON.stringify({ ...fields, page: 1 }),
              );
            }
          }
        }
      })();
    };
  }, [
    _handleSubmit,
    endpointUrl,
    submittedFields,
    mutate,
    useSessionStorageHydration,
    updateRouterUrl,
    searchId,
  ]);

  /**
   * Gère la soumission du formulaire.
   */
  const handleSubmit = useMemo(() => {
    /**
     * @param {Event} [event]
     * @param {object} [options]
     * @param {boolean} [options.shouldUpdateRouterUrl]
     * @param {boolean} [options.preventMutate]
     * @return {void}
     */
    return function (event, options = {}) {
      event?.preventDefault();
      search(options);
    };
  }, [search]);

  const resetDefaultValues = useCallback(
    /**
     * Réinitialise les valeurs par défaut du formulaire.
     */
    async function resetDefaultValues() {
      const fields = initialDefaultValues({
        paginationPerPage,
        defaultValues: props.defaultValues,
        paginated,
      });
      reset(fields);
      handleSubmit();
    },
    [handleSubmit, paginated, paginationPerPage, props.defaultValues, reset],
  );

  /**
   * Gestion de l'hydratation du formulaire via le session storage.
   * si on trouve des champs on soumet le formulaire
   */
  useEffect(function () {
    if (!mounted) {
      const diffFields = {};
      if (useSessionStorageHydration) {
        // Si l’hydratation via le stockage session est activé et qu’il s’agit du premier rendu
        const key = `${SESSION_STORAGE_BASE_SEARCH_SAVED_FIELDS}-${searchId}`;
        const rawFields = window.sessionStorage.getItem(key);
        window.sessionStorage.removeItem(key);
        if (rawFields) {
          Object.assign(diffFields, JSON.parse(rawFields));
        }
      }

      if (Object.keys(diffFields).length > 0) {
        // on récupère les valeurs du router par défaut
        const routerValues = initialDefaultValues({
          paginationPerPage,
          queryValues:
            "function" === typeof routerSyncUrl && router.query
              ? normalizeQuery(router.query)
              : {},
          paginated: false,
        });
        // si dans l'url on dispose d'élèment, on utilise pas les valeurs du session storage, pour éviter les incohérences de fields
        if (Object.keys(routerValues).length > 0) {
          return;
        }
        for (const [fieldName, fieldValue] of Object.entries(diffFields)) {
          setValue(fieldName, fieldValue, { shouldDirty: true });
        }
        search({ shouldUpdateRouterUrl: true });
      }
    }
  });

  /**
   * Synchronise les valeurs du formulaire avec l'URL du router.
   */
  useEffect(() => {
    const handleRouteChange = () => {
      if (routerSyncUrl === false) {
        return;
      }

      if (!$mounted.current) {
        $mounted.current = true;
        return;
      }

      const queryFields = normalizeQuery(router.query);
      // On forge un objet fields en récuperant les valeurs du router et en y ajoutant les valeurs par défaut du formulaire
      // Cette partie est primordiale notamment pour les valeurs rendues a null (undefined ou "") mais qui ne sont pas présente en url.
      const fields = initialDefaultValues({
        paginationPerPage,
        queryValues: "function" === typeof routerSyncUrl ? queryFields : {},
        defaultValues: props.defaultValues,
        paginated: false,
      });

      for (const [fieldName, fieldValue] of Object.entries(fields)) {
        setValue(fieldName, fieldValue, { shouldDirty: true });
      }

      search({
        shouldUpdateRouterUrl: false,
        preventMutate: true,
      });
    };

    router.events?.on("routeChangeComplete", handleRouteChange);

    // If the component is unmounted, unsubscribe
    // from the event with the `off` method:
    return () => {
      router.events?.off("routeChangeComplete", handleRouteChange);
    };
  }, [
    form,
    paginationPerPage,
    props.defaultValues,
    router.events,
    router.query,
    search,
    routerSyncUrl,
    setValue,
  ]);

  const [tagsRefreshId, setTagsRefreshId] = useState({});

  /**
   * Tells to update tags
   */
  const updateTags = useCallback(function () {
    setTagsRefreshId({});
  }, []);

  /** @type {import("./Context").SearchContext} */
  const value = {
    searchId,
    tagsRefreshId,
    setSwrOptions,
    swrOptions,
    form,
    submittedFields,
    displayedPerPages,
    paginationUrl,
    preventSubmitIfNotDirty,
    response,
    onSubmit: handleSubmit,
    updateTags,
    reset: resetDefaultValues,
    routerSyncUrl,
    defaultValues,
  };

  useEffect(function () {
    setMounted(true);
  }, []);

  useEffect(
    function () {
      if (!mounted) {
        updateRouterUrl({ fields: submittedFields });
      }
    },
    [updateRouterUrl, mounted, submittedFields],
  );

  return (
    <SearchContext.Provider value={value}>
      <FormProvider {...form}>{children}</FormProvider>
    </SearchContext.Provider>
  );
}

export default SearchProvider;

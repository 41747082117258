// deps
import {
  Box,
  Icon,
  InputGroup,
  InputRightElement,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  useColorModeValue,
} from "@chakra-ui/react";
import { IoBanOutline, IoCalendarOutline } from "react-icons/io5";
import DateField from "./DateField";
import FieldButton from "./FieldButton";
import StyledField from "./StyledField";
import PropTypes from "prop-types";

/**
 * @typedef Props
 * @property {boolean} isOpen
 * @property {import("@react-stately/datepicker").DateFieldState} state
 * @property {Function} onClose
 * @property {import("react").FunctionComponentElement} children
 */
/**
 * @returns {import("react").FunctionComponentElement<Props>}
 */
function PopoverContainer(props) {
  let { isOpen, state, onClose, children } = props;

  const borderColor = useColorModeValue("gray.300", "gray.600");

  return (
    <Popover
      strategy="fixed"
      placement="bottom"
      isOpen={isOpen}
      onClose={onClose}>
      <InputGroup
        {...props.groupProps}
        ref={props.inputGroupRef}
        width="auto"
        display="inline-flex"
        onFocus={props.handleFocus}>
        {props.isDateRangePicker ? (
          <StyledField
            {...(props.shouldRenderIcon && {
              pr: "5.5rem",
            })}
            isOpen={state.isOpen}>
            <DateField {...props.startFieldProps} />

            <Box as="span" aria-hidden="true" paddingX="2">
              {"–"}
            </Box>

            <DateField {...props.endFieldProps} />

            {state.validationState === "invalid" && (
              <Icon
                as={IoBanOutline}
                color="red.600"
                position="absolute"
                right="12"
              />
            )}
          </StyledField>
        ) : (
          <StyledField pr="4.5rem" w="100%" isOpen={state.isOpen}>
            <DateField {...props.fieldProps} />

            {state.validationState === "invalid" && (
              <Icon
                as={IoBanOutline}
                color="red.600"
                position="absolute"
                right="12"
              />
            )}
          </StyledField>
        )}

        <PopoverTrigger>
          <InputRightElement zIndex="initial">
            <FieldButton {...props.buttonProps} isPressed={state.isOpen}>
              <Icon as={IoCalendarOutline} />
            </FieldButton>
          </InputRightElement>
        </PopoverTrigger>
      </InputGroup>

      <PopoverContent
        width={props.isDateRangePicker ? "630px" : "auto"}
        border="1px solid"
        borderColor={borderColor}
        borderRadius="md">
        <PopoverBody p="1rem">{children}</PopoverBody>
      </PopoverContent>
    </Popover>
  );
}

PopoverContainer.propsTypes = {
  isOpen: PropTypes.bool.isRequired,
  state: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  children: PropTypes.element.isRequired,
};

export default PopoverContainer;

import { useDisclosure } from "@chakra-ui/react";
import { AUTHORIZATIONS_AUTHORIZATION_VALUE_TASKS_VIEW_ANY } from "@raiden/library/constants/authorizations";
import {
  TASKS_STATE_VALUE_DISPATCHED,
  TASKS_STATE_VALUE_QUEUED,
  TASKS_STATE_VALUE_STARTED,
} from "@raiden/library/constants/tasks";
import { useDefaultEnvironments } from "@raiden/library/contexts/DefaultEnvironments";
import { useApi } from "@raiden/library/hooks/useApi";
import useCanPerform from "@raiden/library/hooks/useCanPerform";
import generateApiUrl from "@raiden/library/libraries/utils/generateApiUrl";
import { createContext, useCallback, useMemo, useState } from "react";

/**
 * @typedef {object} TaskContextValue
 * @property {boolean} isTaskDrawerOpen
 * @property {({ fields }) => void} onTaskDrawerOpen
 * @property {() => void} onTaskDrawerClose
 * @property {Object} fields
 * @property {number} numberOfStartedTasks
 * @property {() => void} mutateNumberOfStartedTasks
 */

/** @type {TaskContextValue} */
const DefaultValue = {
  isTaskDrawerOpen: false,
  onTaskDrawerOpen: () => {},
  onTaskDrawerClose: () => {},
  fields: {},
  numberOfStartedTasks: 0,
  mutateNumberOfStartedTasks: () => {},
};

/** @type {React.Context<TaskContextValue>} */
export const TasksContext = createContext(DefaultValue);

export const TASKS_REFRESH_INTERVAL = 10000;

/**
 * @typedef {Object} Props
 * @property {React.ReactNode} children
 *
 * @param {Props} props
 */
export const TaskProvider = ({ children }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const { defaultEnvironments } = useDefaultEnvironments();

  const canPerform = useCanPerform();

  const [customFields, setCustomFields] = useState({});

  /** @type {import("@raiden/library/hooks/useApi").UseApi<import("@raiden/library/types/Task").Task[]>} */
  const { swrResponse: swrResponseCountTask } = useApi(
    canPerform({
      authorizations: AUTHORIZATIONS_AUTHORIZATION_VALUE_TASKS_VIEW_ANY,
      environmentsId: defaultEnvironments,
    })
      ? generateApiUrl({
          id: "@api.tasks.viewAny",
          query: {
            state: [
              TASKS_STATE_VALUE_QUEUED,
              TASKS_STATE_VALUE_STARTED,
              TASKS_STATE_VALUE_DISPATCHED,
            ],
            environment_id: defaultEnvironments,
            per_page: 1,
          },
        })
      : null,
    {
      swrConfig: {
        refreshInterval: TASKS_REFRESH_INTERVAL,
      },
    },
  );

  const numberOfTasks = swrResponseCountTask.data?.meta?.total ?? 0;

  const onOpenDrawer = useCallback(
    ({ fields }) => {
      setCustomFields(fields);
      onOpen();
    },
    [onOpen],
  );

  const value = useMemo(() => {
    /** @type {TaskContextValue} */
    const value = {
      isTaskDrawerOpen: isOpen,
      onTaskDrawerOpen: onOpenDrawer,
      onTaskDrawerClose: onClose,
      fields: customFields,
      numberOfStartedTasks: numberOfTasks,
      mutateNumberOfStartedTasks: swrResponseCountTask.mutate,
    };
    return value;
  }, [
    customFields,
    isOpen,
    numberOfTasks,
    onClose,
    onOpenDrawer,
    swrResponseCountTask.mutate,
  ]);

  return (
    <TasksContext.Provider value={value}>{children}</TasksContext.Provider>
  );
};

// deps
import {
  MdFeed,
  MdFileCopy,
  MdOutlinePublic,
  MdOutlineVpnLock,
  MdPerson,
} from "react-icons/md";
import { defineMessage } from "react-intl";

/* Search object types */
export const SEARCH_RESULTS_OBJECT_TYPE_FILE = "file";
export const SEARCH_RESULTS_OBJECT_TYPE_CUSTOMER = "customer";
export const SEARCH_RESULTS_OBJECT_TYPE_POST = "post";

/**
 * @type {Record<import("../../types/search/Result").SearchResultObjectType, {
 * id: import("../../types/search/Result").SearchResultObjectType,
 * label: import("react-intl").MessageDescriptor,
 * icon: import("react-icons").IconType,
 * }>}
 */
export const SEARCH_RESULTS_OBJECT_TYPES = {
  [SEARCH_RESULTS_OBJECT_TYPE_FILE]: {
    id: SEARCH_RESULTS_OBJECT_TYPE_FILE,
    label: defineMessage({ defaultMessage: "Fichier" }),
    icon: MdFileCopy,
  },
  [SEARCH_RESULTS_OBJECT_TYPE_CUSTOMER]: {
    id: SEARCH_RESULTS_OBJECT_TYPE_CUSTOMER,
    label: defineMessage({ defaultMessage: "Compte client" }),
    icon: MdPerson,
  },
  [SEARCH_RESULTS_OBJECT_TYPE_POST]: {
    id: SEARCH_RESULTS_OBJECT_TYPE_POST,
    label: defineMessage({ defaultMessage: "Contenus" }),
    icon: MdFeed,
  },
};

export const SEARCH_RESULTS_OBJECT_TYPE_LIST = Object.values(
  SEARCH_RESULTS_OBJECT_TYPES,
);

/* Search source */
export const SEARCH_RESULTS_SOURCE_MANUAL = "manual";
export const SEARCH_RESULTS_SOURCE_SYSTEM = "system";

/**
 * @type {Record<import("../../types/search/Result").SearchResultSource, {
 * id: import("../../types/search/Result").SearchResultSource,
 * label: import("react-intl").MessageDescriptor,
 * }>}
 */
export const SEARCH_RESULTS_SOURCES = {
  [SEARCH_RESULTS_SOURCE_MANUAL]: {
    id: SEARCH_RESULTS_SOURCE_MANUAL,
    label: defineMessage({ defaultMessage: "manuel" }),
  },
  [SEARCH_RESULTS_SOURCE_SYSTEM]: {
    id: SEARCH_RESULTS_SOURCE_SYSTEM,
    label: defineMessage({ defaultMessage: "système" }),
  },
};

export const SEARCH_RESULTS_SOURCE_LIST = Object.values(SEARCH_RESULTS_SOURCES);

/* Search visibility */
export const SEARCH_RESULTS_VISIBILITY_LIMITED = "limited";
export const SEARCH_RESULTS_VISIBILITY_PUBLIC = "public";

/**
 * @type {Record<import("../../types/search/Result").SearchResultVisibility, {
 * id: import("../../types/search/Result").SearchResultVisibility,
 * label: import("react-intl").MessageDescriptor,
 * icon: import("react-icons").IconType,
 * colors: [string, string],
 * describeLabel: import("react-intl").MessageDescriptor,
 * }>}
 */
export const SEARCH_RESULTS_VISIBILITIES = {
  [SEARCH_RESULTS_VISIBILITY_LIMITED]: {
    id: SEARCH_RESULTS_VISIBILITY_LIMITED,
    label: defineMessage({ defaultMessage: "limitée" }),
    icon: MdOutlineVpnLock,
    colors: ["orange.600", "orange.300"],
    describeLabel: defineMessage({
      defaultMessage: "Visible uniquement par les administrateurs.",
    }),
  },
  [SEARCH_RESULTS_VISIBILITY_PUBLIC]: {
    id: SEARCH_RESULTS_VISIBILITY_PUBLIC,
    label: defineMessage({ defaultMessage: "public" }),
    icon: MdOutlinePublic,
    colors: ["green.600", "green.300"],
    describeLabel: defineMessage({
      defaultMessage: "Visible par tous les utilisateurs.",
    }),
  },
};

export const SEARCH_RESULTS_VISIBILITY_LIST = Object.values(
  SEARCH_RESULTS_VISIBILITIES,
);

/* Search RESULTS sorts */
export const SEARCH_RESULTS_SORT_VALUE_CREATED_ASC = "created_asc";
export const SEARCH_RESULTS_SORT_VALUE_CREATED_DESC = "created_desc";

export const SEARCH_RESULTS_SORTS = {
  [SEARCH_RESULTS_SORT_VALUE_CREATED_ASC]: {
    id: SEARCH_RESULTS_SORT_VALUE_CREATED_ASC,
    label: defineMessage({ defaultMessage: "date de création croissante" }),
  },
  [SEARCH_RESULTS_SORT_VALUE_CREATED_DESC]: {
    id: SEARCH_RESULTS_SORT_VALUE_CREATED_DESC,
    label: defineMessage({ defaultMessage: "date de création décroissante" }),
  },
};

export const SEARCH_RESULTS_SORT_LIST = Object.values(SEARCH_RESULTS_SORTS);

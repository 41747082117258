// deps
import { Icon } from "@chakra-ui/react";
import {
  MdAttachFile,
  MdOutlineArticle,
  MdOutlinePageview,
} from "react-icons/md";

// components
import PampaImage from "@raiden/library/components/PampaImage";
import { Icon as ResultIcon } from "../../../../components/Icon";
import UsersAvatar from "../../../../components/Users/Avatar";

// constants
import {
  FILES_CATEGORIES,
  FILES_TYPES,
  FILES_TYPE_VALUE_DIRECTORY,
  FILES_TYPE_VALUE_FILE,
} from "@raiden/library/constants/files";
import {
  POSTS_TYPE_VALUE_ARTICLE,
  POSTS_TYPE_VALUE_PAGE,
} from "@raiden/library/constants/posts";
import {
  SEARCH_RESULTS_OBJECT_TYPE_CUSTOMER,
  SEARCH_RESULTS_OBJECT_TYPE_FILE,
  SEARCH_RESULTS_OBJECT_TYPE_POST,
} from "@raiden/library/constants/search/results";

const thumbnailSize = "48px";

/**
 * @typedef {Object} Props
 * @property {import("@raiden/library/types/search/Result").SearchResult} searchResult
 */

/**
 * @param {Props} props
 */
export default function SearchGlobalDataThumbnail({ searchResult }) {
  // Show the picture
  if (searchResult?.picture?.pampa_uri) {
    return (
      <PampaImage
        image={searchResult.picture}
        mode="crop"
        format="default"
        maxContainerWidth={48}
        maxW="none"
        w="48px"
        alt=""
      />
    );
  }

  // Object type is customer
  if (searchResult.object_type === SEARCH_RESULTS_OBJECT_TYPE_CUSTOMER) {
    // Show the user avatar
    return (
      <UsersAvatar
        user={{ display_name: searchResult.metadata?.username }}
        shouldRenderDisplayName={false}
        size="md"
      />
    );
  }

  // Show personal icon
  if (searchResult?.icon) {
    return <ResultIcon size={thumbnailSize} icon={searchResult.icon} />;
  }

  // Show default icon for file
  if (searchResult.object_type === SEARCH_RESULTS_OBJECT_TYPE_FILE) {
    if (searchResult.metadata?.type === FILES_TYPE_VALUE_DIRECTORY) {
      return (
        <Icon
          as={FILES_TYPES[FILES_TYPE_VALUE_DIRECTORY].icon}
          boxSize={thumbnailSize}
        />
      );
    }

    if (searchResult.metadata?.type === FILES_TYPE_VALUE_FILE) {
      return (
        <Icon
          as={FILES_CATEGORIES[searchResult.metadata?.category]?.icon}
          boxSize={thumbnailSize}
        />
      );
    }
  }

  // Show default icon for post
  if (searchResult.object_type === SEARCH_RESULTS_OBJECT_TYPE_POST) {
    if (searchResult.metadata?.type === POSTS_TYPE_VALUE_ARTICLE) {
      return <Icon as={MdOutlineArticle} boxSize={thumbnailSize} />;
    }

    if (searchResult.metadata?.type === POSTS_TYPE_VALUE_PAGE) {
      return <Icon as={MdOutlinePageview} boxSize={thumbnailSize} />;
    }
  }

  // Show empty box
  return <Icon as={MdAttachFile} boxSize={thumbnailSize} />;
}

import {
  Badge,
  Box,
  Code,
  Flex,
  HStack,
  Icon,
  Text,
  useBreakpointValue,
  useColorModeValue,
} from "@chakra-ui/react";
import ResourceState from "@raiden/library/components/Resource/ResourceState";
import {
  FILES_TYPE_VALUE_DIRECTORY,
  FILES_TYPE_VALUE_FILE,
  FILES_VISIBILITIES,
} from "@raiden/library/constants/files";
import { resourcesIsRestrictedModeMessage } from "@raiden/library/constants/resources";
import { usePreferences } from "@raiden/library/contexts/Preferences";
import getFileOrDirectoryIcon from "@raiden/library/helpers/files/getFileOrDirectoryIcon";
import getFileSize from "@raiden/library/helpers/files/getFileSize";
import { FileType } from "@raiden/library/types/File";
import Table from "@splitfire-agency/raiden-library/dist/components/Table";
import { FormattedMessage, useIntl } from "react-intl";
import UsersAvatar from "../../../components/Users/Avatar";

/**
 * @typedef Props
 * @property {import("@raiden/library/types/File").File} resource
 */
/**
 * @param {Props} props
 * @return {import("react").FunctionComponentElement<Props>}
 */
export default function InfoTable({ resource }) {
  const intl = useIntl();

  const borderColor = useColorModeValue("gray.200", "gray.600");

  const responsive = useBreakpointValue({
    base: true,
    md: false,
  });

  const { shouldRenderEnvironments } = usePreferences();

  return (
    <Flex gap="1rem" flexDir="column">
      <Box p="1rem" border="1px" borderColor={borderColor}>
        <HStack mb="1rem">
          <Text flexGrow={1} fontSize="1.125rem" fontWeight="bold">
            {intl.formatMessage({
              defaultMessage: "Informations générales",
            })}
          </Text>
        </HStack>

        <Table variant="striped2" layout={responsive ? "column" : "row"}>
          <Table.Tbody>
            <Table.Tr>
              <Table.Td>
                {intl.formatMessage({
                  defaultMessage: "Id",
                })}
              </Table.Td>

              <Table.Td>
                <Code>{resource.id}</Code>
              </Table.Td>
            </Table.Tr>

            {resource?.environment && shouldRenderEnvironments && (
              <Table.Tr>
                <Table.Td>
                  {intl.formatMessage({ defaultMessage: "Environment" })}
                </Table.Td>

                <Table.Td>{resource.environment.name}</Table.Td>
              </Table.Tr>
            )}

            <Table.Tr>
              <Table.Td>
                {intl.formatMessage({ defaultMessage: "Propriétaire" })}
              </Table.Td>

              <Table.Td>
                <UsersAvatar size="sm" user={resource.owner} />
              </Table.Td>
            </Table.Tr>

            <Table.Tr>
              <Table.Td>
                {intl.formatMessage({
                  defaultMessage: "Nom",
                })}
              </Table.Td>

              <Table.Td>
                <Flex alignItems="center" gap=".5rem">
                  <Icon as={getFileOrDirectoryIcon({ file: resource })} />

                  <Text>{resource?.filename}</Text>
                </Flex>
              </Table.Td>
            </Table.Tr>

            <Table.Tr>
              <Table.Td>
                {intl.formatMessage({
                  defaultMessage: "Taille",
                })}
              </Table.Td>

              <Table.Td>
                <Badge
                  fontSize=".85rem"
                  p="4px"
                  borderRadius="8px"
                  marginRight="auto"
                  textTransform={
                    resource.type === FILES_TYPE_VALUE_DIRECTORY
                      ? "none"
                      : "uppercase"
                  }>
                  {resource.type === FILES_TYPE_VALUE_FILE
                    ? getFileSize({ size: resource.filesize, intl })
                    : intl.formatMessage(
                        {
                          defaultMessage:
                            "{count, plural, =0 {Aucun élément} one {1 élément} other {# éléments}}",
                        },
                        { count: resource.children?.count },
                      )}
                </Badge>
              </Table.Td>
            </Table.Tr>

            <Table.Tr>
              <Table.Td>
                {intl.formatMessage({
                  defaultMessage: "Visibilité",
                })}
              </Table.Td>

              <Table.Td>
                <ResourceState
                  state={resource.visibility}
                  customStates={FILES_VISIBILITIES}
                />
              </Table.Td>
            </Table.Tr>

            <Table.Tr>
              <Table.Td>
                {intl.formatMessage({
                  defaultMessage: "Restriction",
                })}
              </Table.Td>

              <Table.Td>
                <Text>
                  {intl.formatMessage(resourcesIsRestrictedModeMessage, {
                    isRestrictedMode: resource.is_restricted,
                  })}
                </Text>
              </Table.Td>
            </Table.Tr>

            {resource.created_at && (
              <Table.Tr>
                <Table.Td>
                  {intl.formatMessage({
                    defaultMessage: "Date d’ajout",
                  })}
                </Table.Td>

                <Table.Td>
                  <FormattedMessage
                    defaultMessage="Le {date} à {heure}"
                    values={{
                      date: intl.formatDate(resource.created_at, {
                        dateStyle: "short",
                      }),
                      heure: intl.formatDate(resource.created_at, {
                        timeStyle: "short",
                      }),
                    }}
                  />
                </Table.Td>
              </Table.Tr>
            )}

            {resource.updated_at && (
              <Table.Tr>
                <Table.Td>
                  {intl.formatMessage({
                    defaultMessage: "Date de mise à jour",
                  })}
                </Table.Td>

                <Table.Td>
                  <FormattedMessage
                    defaultMessage="Le {date} à {heure}"
                    values={{
                      date: intl.formatDate(resource.updated_at, {
                        dateStyle: "short",
                      }),
                      heure: intl.formatDate(resource.updated_at, {
                        timeStyle: "short",
                      }),
                    }}
                  />
                </Table.Td>
              </Table.Tr>
            )}

            {resource.deleted_at && (
              <Table.Tr>
                <Table.Td>
                  {intl.formatMessage({
                    defaultMessage: "Date de suppression",
                  })}
                </Table.Td>

                <Table.Td>
                  <FormattedMessage
                    defaultMessage="Le {date} à {heure}"
                    values={{
                      date: intl.formatDate(resource.deleted_at, {
                        dateStyle: "short",
                      }),
                      heure: intl.formatDate(resource.deleted_at, {
                        timeStyle: "short",
                      }),
                    }}
                  />
                </Table.Td>
              </Table.Tr>
            )}
          </Table.Tbody>
        </Table>
      </Box>
    </Flex>
  );
}

InfoTable.propTypes = {
  resource: FileType,
};

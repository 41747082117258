import { AUTHORIZATIONS_AUTHORIZATION_VALUE_TASKS_VIEW_ANY } from "@raiden/library/constants/authorizations";
import { useDefaultEnvironments } from "@raiden/library/contexts/DefaultEnvironments";
import useCanPerform from "@raiden/library/hooks/useCanPerform";

/**
 * @typedef {Object} Props
 * @property {import("react").ReactNode} children
 * @return {import("react").FunctionComponentElement<Props>}
 */

/**
 * @param {Props} props
 */
function TasksActionsViewAny(props) {
  const { children } = props;

  const canPerform = useCanPerform();

  const { defaultEnvironments } = useDefaultEnvironments();

  return (
    <>
      {canPerform({
        authorizations: AUTHORIZATIONS_AUTHORIZATION_VALUE_TASKS_VIEW_ANY,
        environmentsId: defaultEnvironments,
      }) && <>{children}</>}
    </>
  );
}

export default TasksActionsViewAny;

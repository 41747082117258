// deps
import PropTypes from "prop-types";

/**
 * @typedef {Object} FileFormValues
 * @property {Object} data
 * @property {number} data.environment_id
 * @property {string} data.filename
 * @property {string} data.visibility
 * @property {number} data.is_restricted
 * @property {string} data.type
 * @property {string} data.parent_id
 * @property {Object} [meta]
 * @property {boolean} meta.recursive_visibility
 * @property {Array<Object>} [files]
 *
 *
 * @typedef {"file" | "directory"} FileType
 *
 * @typedef {"public" | "private" | "limited"} FileVisibility
 *
 * @typedef {object} FilePermissions
 * @property {string} scope
 * @property {string[]} permissions
 *
 * @typedef {object} FileDimensions
 * @property {number} width
 * @property {number} height
 *
 * @typedef {object} FileMeta
 * @property {object} [children]
 * @property {number} [children.count]
 * @property {string[]} [policies]
 *
 * @typedef {object} FileChildren
 * @property {number} count
 *
 * @typedef {object} File
 * @property {number} id
 * @property {number} environment_id
 * @property {number | null} parent_id
 * @property {number} active_task_count
 * @property {FileType} type
 * @property {string} filename
 * @property {string} [mime_type]
 * @property {number} [filesize]
 * @property {string} [url]
 * @property {string | null} [pampa_uri]
 * @property {FileVisibility} visibility
 * @property {number} is_restricted
 * @property {import("./User").User} [owner]
 * @property {import("./Environment").Environment} [environment]
 * @property {File | null} parent
 * @property {FilePermissions[]} permissions
 * @property {FileDimensions} [dimensions]
 * @property {FileChildren} [children]
 * @property {string} created_at
 * @property {string} updated_at
 * @property {string | null} deleted_at
 * @property {FileMeta} meta
 */

export const FileType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  environment_id: PropTypes.number,
  parent_id: PropTypes.number,
  active_task_count: PropTypes.number,
  type: PropTypes.oneOf(["file", "directory"]),
  filename: PropTypes.string,
  mime_type: PropTypes.string,
  filesize: PropTypes.number,
  url: PropTypes.string,
  pampa_uri: PropTypes.string,
  visibility: PropTypes.oneOf(["public", "private", "limited"]),
  is_restricted: PropTypes.number,
  owner: PropTypes.object,
  environment: PropTypes.object,
  parent: PropTypes.object,
  permissions: PropTypes.arrayOf(
    PropTypes.shape({
      scope: PropTypes.string,
      permissions: PropTypes.arrayOf(PropTypes.string),
    }),
  ),
  children: PropTypes.shape({
    count: PropTypes.number,
  }),
  dimensions: PropTypes.shape({
    width: PropTypes.number,
    height: PropTypes.number,
  }),
  created_at: PropTypes.string,
  updated_at: PropTypes.string,
  deleted_at: PropTypes.string,
  meta: PropTypes.object,
});

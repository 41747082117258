import {
  MdFilePresent,
  MdOutlineAudioFile,
  MdOutlineFilePresent,
  MdOutlineImage,
  MdOutlineInsertDriveFile,
  MdOutlinePublic,
  MdOutlinePublicOff,
  MdOutlineVideoFile,
  MdOutlineVpnLock,
  MdPictureAsPdf,
  MdVideoFile,
} from "react-icons/md";
import { defineMessage } from "react-intl";

// Types
export const FILES_TYPE_VALUE_DIRECTORY = "directory";
export const FILES_TYPE_VALUE_FILE = "file";

export const FILES_TYPES = {
  [FILES_TYPE_VALUE_DIRECTORY]: {
    id: FILES_TYPE_VALUE_DIRECTORY,
  },
  [FILES_TYPE_VALUE_FILE]: {
    id: FILES_TYPE_VALUE_FILE,
  },
};

export const FILES_TYPE_LIST = Object.values(FILES_TYPES);

export const fileTypeMessage = defineMessage({
  defaultMessage:
    "{type, select, directory {Dossier} file {Fichier} other {{type}}}",
});

// Catégories
export const FILES_CATEGORY_VALUE_IMAGE = "image";
export const FILES_CATEGORY_VALUE_AUDIO = "audio";
export const FILES_CATEGORY_VALUE_DOCUMENT = "document";
export const FILES_CATEGORY_VALUE_VIDEO = "video";
export const FILES_CATEGORY_VALUE_OTHER = "other";

export const FILES_CATEGORIES = {
  [FILES_CATEGORY_VALUE_IMAGE]: {
    id: FILES_CATEGORY_VALUE_IMAGE,
    icon: MdOutlineImage,
  },
  [FILES_CATEGORY_VALUE_AUDIO]: {
    id: FILES_CATEGORY_VALUE_AUDIO,
    icon: MdOutlineAudioFile,
  },
  [FILES_CATEGORY_VALUE_DOCUMENT]: {
    id: FILES_CATEGORY_VALUE_DOCUMENT,
    icon: MdOutlineFilePresent,
  },
  [FILES_CATEGORY_VALUE_VIDEO]: {
    id: FILES_CATEGORY_VALUE_VIDEO,
    icon: MdOutlineVideoFile,
  },
  [FILES_CATEGORY_VALUE_OTHER]: {
    id: FILES_CATEGORY_VALUE_OTHER,
    icon: MdOutlineInsertDriveFile,
  },
};

export const FILES_CATEGORY_LIST = Object.values(FILES_CATEGORIES);

export const filesCategoryMessage = defineMessage({
  defaultMessage:
    "{category, select, image {Image} audio {Fichier audio} document {Document} video {Fichier vidéo} other {Autre}}",
});

// Tri
export const FILES_SORT_VALUE_NAME_ASC = "name_asc";
export const FILES_SORT_VALUE_NAME_DESC = "name_desc";
export const FILES_SORT_VALUE_FILESIZE_ASC = "filesize_asc";
export const FILES_SORT_VALUE_FILESIZE_DESC = "filesize_desc";
export const FILES_SORT_VALUE_CREATED_ASC = "created_asc";
export const FILES_SORT_VALUE_CREATED_DESC = "created_desc";

export const FILES_SORTS = {
  [FILES_SORT_VALUE_NAME_ASC]: {
    id: FILES_SORT_VALUE_NAME_ASC,
  },
  [FILES_SORT_VALUE_NAME_DESC]: {
    id: FILES_SORT_VALUE_NAME_DESC,
  },
  [FILES_SORT_VALUE_CREATED_ASC]: {
    id: FILES_SORT_VALUE_CREATED_ASC,
  },
  [FILES_SORT_VALUE_CREATED_DESC]: {
    id: FILES_SORT_VALUE_CREATED_DESC,
  },
  [FILES_SORT_VALUE_FILESIZE_ASC]: {
    id: FILES_SORT_VALUE_FILESIZE_ASC,
  },
  [FILES_SORT_VALUE_FILESIZE_DESC]: {
    id: FILES_SORT_VALUE_FILESIZE_DESC,
  },
};

export const FILES_SORT_LIST = Object.values(FILES_SORTS);

export const FILES_SORT_MESSAGE = defineMessage({
  defaultMessage:
    "{sort, select, name_asc {Nom croissant} name_desc {Nom décroissant} created_asc {Date de création croissante} created_desc {Date de création décroissante} filesize_asc {Taille croissante} filesize_desc {Taille décroissante} other {{sort}}}",
});

export const FILES_VISIBILITY_VALUE_PRIVATE = "private";
export const FILES_VISIBILITY_VALUE_PUBLIC = "public";
export const FILES_VISIBILITY_VALUE_LIMITED = "limited";

export const FILES_VISIBILITIES = {
  [FILES_VISIBILITY_VALUE_PRIVATE]: {
    id: FILES_VISIBILITY_VALUE_PRIVATE,
    label: defineMessage({ defaultMessage: "Privé" }),
    icon: MdOutlinePublicOff,
    colors: ["purple.600", "purple.300"],
  },
  [FILES_VISIBILITY_VALUE_LIMITED]: {
    id: FILES_VISIBILITY_VALUE_LIMITED,
    label: defineMessage({ defaultMessage: "Limité" }),
    icon: MdOutlineVpnLock,
    colors: ["orange.600", "orange.300"],
  },
  [FILES_VISIBILITY_VALUE_PUBLIC]: {
    id: FILES_VISIBILITY_VALUE_PUBLIC,
    label: defineMessage({ defaultMessage: "Publique" }),
    icon: MdOutlinePublic,
    colors: ["green.600", "green.300"],
  },
};

export const FILES_VISIBILITY_LIST = Object.values(FILES_VISIBILITIES);

export const fileVisibilityMessage = defineMessage({
  defaultMessage:
    "{visibility, select, public {Publique} private {Privée} limited {Limitée} other {visibility}}",
});

export const fileVisibilityDescriptionMessage = defineMessage({
  defaultMessage:
    "{visibility, select, public {Sera visible sur le site pour les utilisateurs connectés} private {Sera visible uniquement par vous} limited {Sera visible par les administrateurs} other {{visibility}}}",
});

export const FILES_MEDIA_TYPE_VALUE_VIDEO_MPEG = "video/mp4";
export const FILES_MEDIA_TYPE_VALUE_IMAGE_PNG = "image/png";
export const FILES_MEDIA_TYPE_VALUE_IMAGE_SVG = "image/svg+xml";
export const FILES_MEDIA_TYPE_VALUE_IMAGE_WBMP = "image/vnd.wap.wbmp";
export const FILES_MEDIA_TYPE_VALUE_IMAGE_WEBP = "image/webp";
export const FILES_MEDIA_TYPE_VALUE_IMAGE_GIF = "image/gif";
export const FILES_MEDIA_TYPE_VALUE_IMAGE_JPEG = "image/jpeg";
export const FILES_MEDIA_TYPE_VALUE_IMAGE_TIFF = "image/tiff";
export const FILES_MEDIA_TYPE_VALUE_IMAGE_BITMAP = "image/x-ms-bmp";
export const FILES_MEDIA_TYPE_VALUE_APPLICATION_PDF = "application/pdf";
export const FILES_MEDIA_TYPE_VALUE_APPLICATION_EXCEL =
  "application/vnd.ms-excel";
export const FILES_MEDIA_TYPE_VALUE_APPLICATION_WORD = "application/msword";

export const FILES_MEDIAS = {
  [FILES_MEDIA_TYPE_VALUE_VIDEO_MPEG]: {
    id: FILES_MEDIA_TYPE_VALUE_VIDEO_MPEG,
    icon: MdVideoFile,
  },
  [FILES_MEDIA_TYPE_VALUE_IMAGE_PNG]: {
    id: FILES_MEDIA_TYPE_VALUE_IMAGE_PNG,
    icon: MdOutlineImage,
  },
  [FILES_MEDIA_TYPE_VALUE_IMAGE_SVG]: {
    id: FILES_MEDIA_TYPE_VALUE_IMAGE_SVG,
    icon: MdOutlineImage,
  },
  [FILES_MEDIA_TYPE_VALUE_IMAGE_WBMP]: {
    id: FILES_MEDIA_TYPE_VALUE_IMAGE_WBMP,
    icon: MdOutlineImage,
  },
  [FILES_MEDIA_TYPE_VALUE_IMAGE_WEBP]: {
    id: FILES_MEDIA_TYPE_VALUE_IMAGE_WEBP,
    icon: MdOutlineImage,
  },
  [FILES_MEDIA_TYPE_VALUE_IMAGE_GIF]: {
    id: FILES_MEDIA_TYPE_VALUE_IMAGE_GIF,
    icon: MdOutlineImage,
  },
  [FILES_MEDIA_TYPE_VALUE_IMAGE_JPEG]: {
    id: FILES_MEDIA_TYPE_VALUE_IMAGE_JPEG,
    icon: MdOutlineImage,
  },
  [FILES_MEDIA_TYPE_VALUE_IMAGE_TIFF]: {
    id: FILES_MEDIA_TYPE_VALUE_IMAGE_TIFF,
    icon: MdOutlineImage,
  },
  [FILES_MEDIA_TYPE_VALUE_IMAGE_BITMAP]: {
    id: FILES_MEDIA_TYPE_VALUE_IMAGE_BITMAP,
    icon: MdOutlineImage,
  },
  [FILES_MEDIA_TYPE_VALUE_APPLICATION_PDF]: {
    id: FILES_MEDIA_TYPE_VALUE_APPLICATION_PDF,
    icon: MdPictureAsPdf,
  },
  [FILES_MEDIA_TYPE_VALUE_APPLICATION_EXCEL]: {
    id: FILES_MEDIA_TYPE_VALUE_APPLICATION_EXCEL,
    icon: MdFilePresent,
  },
  [FILES_MEDIA_TYPE_VALUE_APPLICATION_WORD]: {
    id: FILES_MEDIA_TYPE_VALUE_APPLICATION_WORD,
    icon: MdFilePresent,
  },
};

export const FILES_MEDIAS_LIST = Object.values(FILES_MEDIAS);

export const FILES_SIZE_VALUE_BYTE = "byte";
export const FILES_SIZE_VALUE_KILOBYTE = "kilobyte";
export const FILES_SIZE_VALUE_MEGABYTE = "megabyte";
export const FILES_SIZE_VALUE_GIGABYTE = "gigabyte";
export const FILES_SIZE_VALUE_TERABYTE = "terabyte";

export const FILES_SIZES = {
  [FILES_SIZE_VALUE_BYTE]: {
    id: FILES_SIZE_VALUE_BYTE,
  },
  [FILES_SIZE_VALUE_KILOBYTE]: {
    id: FILES_SIZE_VALUE_KILOBYTE,
  },
  [FILES_SIZE_VALUE_MEGABYTE]: {
    id: FILES_SIZE_VALUE_MEGABYTE,
  },
  [FILES_SIZE_VALUE_GIGABYTE]: {
    id: FILES_SIZE_VALUE_GIGABYTE,
  },
  [FILES_SIZE_VALUE_TERABYTE]: {
    id: FILES_SIZE_VALUE_TERABYTE,
  },
};

export const FILES_SIZE_LIST = Object.values(FILES_SIZES);

export const FILES_SIZE_SHORTENED_MESSAGE = defineMessage({
  defaultMessage:
    "{size, select, byte {B} kilobyte {kB} megabyte {MB} gigabyte {GB} terabyte {TB} other {{size}}}",
});

export const FILES_MAX_FILESIZE_FOR_DRAG_AND_DROP = 10485760; // 10 Mo

export const FILES_MIME_TYPES = {
  "image/png": {
    type: "image",
    preview: true,
  },
  "image/svg+xml": {
    type: "image",
    preview: false,
  },
  "image/tiff": {
    type: "image",
    preview: false,
  },
  "image/vnd.wap.wbmp": {
    type: "image",
    preview: true,
  },
  "image/webp": {
    type: "image",
    preview: true,
  },
  "image/x-icon": {
    type: "image",
    preview: true,
  },
  "image/x-jng": {
    type: "image",
    preview: true,
  },
  "image/x-ms-bmp": {
    type: "image",
    preview: true,
  },
  "image/gif": {
    type: "image",
    preview: true,
  },
  "image/jpeg": {
    type: "image",
    preview: true,
  },
  "text/plain": {
    type: "texte",
    preview: false,
  },
  "text/xml": {
    type: "texte",
    preview: false,
  },
  "application/pdf": {
    type: "document",
    preview: true,
  },
  "application/rtf": {
    type: "document",
    preview: false,
  },
  "application/msword": {
    type: "document",
    preview: false,
  },
  "application/vnd.ms-excel": {
    type: "document",
    preview: false,
  },
  "application/vnd.ms-fontobject": {
    type: "document",
    preview: false,
  },
  "application/vnd.ms-powerpoint": {
    type: "document",
    preview: false,
  },
  "application/vnd.oasis.opendocument.graphics": {
    type: "document",
    preview: false,
  },
  "application/vnd.oasis.opendocument.presentation": {
    type: "document",
    preview: false,
  },
  "application/vnd.oasis.opendocument.spreadsheet": {
    type: "document",
    preview: false,
  },
  "application/vnd.oasis.opendocument.text": {
    type: "document",
    preview: false,
  },
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document": {
    type: "document",
    preview: false,
  },
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": {
    type: "document",
    preview: false,
  },
  "application/vnd.openxmlformats-officedocument.presentationml.presentation": {
    type: "document",
    preview: false,
  },
  "video/3gpp": {
    type: "video",
    preview: true,
  },
  "video/mp2t": {
    type: "video",
    preview: true,
  },
  "video/mp4": {
    type: "video",
    preview: true,
  },
  "video/mpeg": {
    type: "video",
    preview: true,
  },
  "video/quicktime": {
    type: "video",
    preview: true,
  },
  "video/webm": {
    type: "video",
    preview: true,
  },
  "video/x-flv": {
    type: "video",
    preview: false,
  },
  "video/x-m4v": {
    type: "video",
    preview: false,
  },
  "video/x-mng": {
    type: "video",
    preview: false,
  },
  "video/x-ms-asf": {
    type: "video",
    preview: true,
  },
  "video/x-ms-wmv": {
    type: "video",
    preview: true,
  },
  "video/x-msvideo": {
    type: "video",
    preview: false,
  },
  "audio/midi": {
    type: "video",
    preview: true,
  },
  "audio/mpeg": {
    type: "audio",
    preview: true,
  },
  "audio/ogg": {
    type: "audio",
    preview: true,
  },
  "audio/x-m4a": {
    type: "audio",
    preview: true,
  },
  "audio/x-flac": {
    type: "audio",
    preview: true,
  },
  "audio/flac": {
    type: "audio",
    preview: true,
  },
  "audio/x-ogg": {
    type: "audio",
    preview: true,
  },
  "audio/x-realaudio": {
    type: "audio",
    preview: true,
  },
  "audio/x-wav": {
    type: "audio",
    preview: true,
  },
};

export const FILES_STATE_VALUE_PENDING = "pending";
export const FILES_STATE_VALUE_LOADING = "loading";
export const FILES_STATE_VALUE_ERROR = "error";
export const FILES_STATE_VALUE_SUCCESS = "success";

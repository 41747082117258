import { useColorModeValue } from "@chakra-ui/react";
import capitalize from "@splitfire-agency/raiden-library/dist/libraries/utils/capitalize";
import { memo } from "react";
import { useIntl } from "react-intl";
import { RESOURCES_STATES } from "../../../constants/resources";
import ResourceTag from "../ResourceTag";

const ResourceState = memo(
  /**
   * @param {{
   * state: string,
   * customStates?: {[key: string]: {icon?: import("react-icons").IconType, colors: string[], label: import("react-intl").MessageDescriptor}},
   * }} props
   */
  function ResourceState({ state: value, customStates = {} }) {
    const intl = useIntl();

    const localState = customStates[value] ?? RESOURCES_STATES[value];

    const color = useColorModeValue(
      localState?.colors[0],
      localState?.colors[1],
    );

    const Icon = localState?.icon;

    return (
      <ResourceTag
        icon={Icon}
        label={
          localState?.label
            ? capitalize(intl.formatMessage(localState.label))
            : value
        }
        color={color}
      />
    );
  },
);

export default ResourceState;

// deps
import { Button, Icon, Wrap } from "@chakra-ui/react";
import { MdRefresh } from "react-icons/md";
import { useIntl } from "react-intl";

// contexts
import { SearchTags, useSearch } from "@raiden/library/contexts/Search";

function SearchCommonResetAndTag() {
  const intl = useIntl();

  const { reset } = useSearch();

  /**
   * Gère la réinitialisation des champs.
   */
  async function handleReset() {
    reset();
  }

  return (
    <Wrap align="center" spacing="0.625rem">
      <Button
        flexShrink={0}
        size="sm"
        variant="outline"
        colorScheme="gray"
        onClick={handleReset}>
        <Icon as={MdRefresh} color="brandSecondary.500" />
        &nbsp;
        {intl.formatMessage({
          defaultMessage: "Réinitialiser les filtres",
        })}
      </Button>

      <SearchTags />
    </Wrap>
  );
}

export default SearchCommonResetAndTag;

import { Box, HStack } from "@chakra-ui/react";
import SearchGlobalDataDetails from "./Details";
import { SearchGlobalDataTags } from "./Tags";
import SearchGlobalDataThumbnail from "./Thumbnail";

/**
 * @param {object} params
 * @param {import("@raiden/library/types/search/Result").SearchResult} params.searchResult
 * @param {boolean} params.isClickable
 */
export function SearchGlobalItemTemplate({ searchResult, isClickable }) {
  return (
    <HStack
      alignItems="inherit"
      gap="inherit"
      width="full"
      cursor={isClickable ? "pointer" : "default"}>
      <SearchGlobalDataThumbnail searchResult={searchResult} />

      <Box width="full">
        <SearchGlobalDataDetails searchResult={searchResult} />

        <SearchGlobalDataTags searchResult={searchResult} />
      </Box>
    </HStack>
  );
}

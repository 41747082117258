import { Grid, Text } from "@chakra-ui/react";
import {
  tasksObjectTypeResultActionMessage,
  TASKS_OBJECT_TYPE_RESULT_ACTION_VALUE_DELETED,
  TASKS_OBJECT_TYPE_RESULT_ACTION_VALUE_RESTORED,
  TASKS_OBJECT_TYPE_RESULT_ACTION_VALUE_UPDATED,
} from "@raiden/library/constants/tasks";
import Table from "@splitfire-agency/raiden-library/dist/components/Table";
import { useIntl } from "react-intl";

/**
 * @typedef {object} Props
 * @property {import("@raiden/library/types/Task").TaskResult} results
 */

/**
 * @param {Props} props
 * @return {import("react").FunctionComponentElement<Props>}
 */
export default function FileResult({ results }) {
  const intl = useIntl();

  return (
    <>
      <Table.Tr>
        <Table.Td>
          {intl.formatMessage({
            defaultMessage: "Résultats (Dossier)",
          })}
        </Table.Td>

        <Table.Td>
          <Grid>
            <Text>
              {intl.formatMessage(
                { defaultMessage: "Nombre d'objet : {count}" },
                { count: results.directories.count },
              )}
            </Text>

            {results.directories.restored !== undefined && (
              <Text>
                {intl.formatMessage(tasksObjectTypeResultActionMessage, {
                  type: TASKS_OBJECT_TYPE_RESULT_ACTION_VALUE_RESTORED,
                  count: results.directories.restored,
                })}
              </Text>
            )}

            {results.directories.deleted !== undefined && (
              <Text>
                {intl.formatMessage(tasksObjectTypeResultActionMessage, {
                  type: TASKS_OBJECT_TYPE_RESULT_ACTION_VALUE_DELETED,
                  count: results.directories.deleted,
                })}
              </Text>
            )}

            {results.directories.updated !== undefined && (
              <Text>
                {intl.formatMessage(tasksObjectTypeResultActionMessage, {
                  type: TASKS_OBJECT_TYPE_RESULT_ACTION_VALUE_UPDATED,
                  count: results.directories.updated,
                })}
              </Text>
            )}

            <Text>
              {intl.formatMessage(
                {
                  defaultMessage:
                    "{unauthorized, plural, =0 {Non autorisé} other {Non autorisés}}: {unauthorized}",
                },
                { unauthorized: results.directories.unauthorized },
              )}
            </Text>
          </Grid>
        </Table.Td>
      </Table.Tr>

      <Table.Tr>
        <Table.Td>
          {intl.formatMessage({
            defaultMessage: "Résultats (Fichier)",
          })}
        </Table.Td>

        <Table.Td>
          <Grid>
            <Text>
              {intl.formatMessage(
                { defaultMessage: "Nombre d'objet : {count}" },
                { count: results.files.count },
              )}
            </Text>

            {results.directories.restored !== undefined && (
              <Text>
                {intl.formatMessage(tasksObjectTypeResultActionMessage, {
                  type: TASKS_OBJECT_TYPE_RESULT_ACTION_VALUE_RESTORED,
                  count: results.files.restored,
                })}
              </Text>
            )}

            {results.directories.deleted !== undefined && (
              <Text>
                {intl.formatMessage(tasksObjectTypeResultActionMessage, {
                  type: TASKS_OBJECT_TYPE_RESULT_ACTION_VALUE_DELETED,
                  count: results.files.deleted,
                })}
              </Text>
            )}

            {results.directories.updated !== undefined && (
              <Text>
                {intl.formatMessage(tasksObjectTypeResultActionMessage, {
                  type: TASKS_OBJECT_TYPE_RESULT_ACTION_VALUE_UPDATED,
                  count: results.files.updated,
                })}
              </Text>
            )}

            <Text>
              {intl.formatMessage(
                {
                  defaultMessage:
                    "{unauthorized, plural, =0 {Non autorisé} other {Non autorisés}}: {unauthorized}",
                },
                { unauthorized: results.files.unauthorized },
              )}
            </Text>
          </Grid>
        </Table.Td>
      </Table.Tr>
    </>
  );
}

import { useCallback } from "react";
import useSWR from "swr";
import { SWR_CONFIG_FOR_STATIC_DATA } from "../constants/swr/swrConfigForStaticData";
import useApiFetcher from "./useApiFetcher";

/**
 * @template T,[M=object]
 * @typedef {{swrResponse: import("swr").SWRResponse<import("../types/Api/ApiResponse").ApiResponse<T, M>>}} UseApi
 **/

/**
 * @template T,[M=object]
 * @param {string | null} url
 * @param {object} [options]
 * @param {"GET" | "POST" | "PUT" | "DELETE"} [options.method]
 * @param {"json" | "file"} [options.outputMode]
 * @param {Partial<import("swr/_internal").PublicConfiguration>} [options.swrConfig]
 * @returns {UseApi<T,M>}
 **/

export function useApi(url, options = { method: "GET", outputMode: "json" }) {
  const apiFetcher = useApiFetcher();

  const fetcher = useCallback(
    (url) => {
      return apiFetcher(url, undefined, {
        method: options.method,
        outputMode: options.outputMode,
      });
    },
    [apiFetcher, options.method, options.outputMode],
  );

  const swrResponse =
    /** @type {import("swr").SWRResponse<import("../types/Api/ApiResponse").ApiResponse<T, M>>} */ (
      useSWR(url, fetcher, {
        ...SWR_CONFIG_FOR_STATIC_DATA,
        ...options.swrConfig,
      })
    );

  return { swrResponse };
}

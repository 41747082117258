import { useFormContext, useWatch } from "react-hook-form";

/**
 * An observer is a component that watches a form field and exposes its value via a render function
 *
 * @param {{
 * names: string[],
 * render: (params: { values: any[] }) => React.ReactNode,
 * }} param0
 * @returns
 */
function ObserverRHF({ names, render }) {
  const { control } = useFormContext();

  const values = useWatch({ name: names, control });

  return <>{render?.({ values })}</>;
}

export default ObserverRHF;

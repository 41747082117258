// deps
import { useDisclosure } from "@chakra-ui/react";
import PropTypes from "prop-types";

// components
import View from "../../Files/View";

/**
 * @callback childrenRender
 * @param {{disclosure: import("@chakra-ui/react").UseDisclosureReturn}} disclosure
 */

/**
 * @typedef {Object} Props
 * @property {number} fileId
 * @property {childrenRender} children
 * @property {() => void} [onSuccess]
 * @property {boolean} [isTrash]
 *
 */

/**
 * @param {Props} props
 * @return {import("react").FunctionComponentElement<Props>}
 */
function SearchActionsViewFile(props) {
  const { fileId, children, onSuccess, isTrash = false } = props;

  const disclosure = useDisclosure();

  const { isOpen, onClose } = disclosure;

  return (
    <>
      {isOpen && (
        <View
          fileId={fileId}
          isOpen={isOpen}
          onClose={onClose}
          onSuccess={onSuccess}
          isTrash={isTrash}
        />
      )}

      {children({ disclosure })}
    </>
  );
}

SearchActionsViewFile.propTypes = {
  fileId: PropTypes.number.isRequired,
  onSuccess: PropTypes.func,
  isTrash: PropTypes.bool,
  children: PropTypes.func.isRequired,
};

export default SearchActionsViewFile;

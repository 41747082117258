// deps
import {
  Flex,
  Grid,
  GridItem,
  Icon,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { useIntl } from "react-intl";

// components
import Radio from "../../Radio";

// constants
import {
  resourcesIsRestrictedModeDescriptionMessage,
  resourcesIsRestrictedModeMessage,
  RESOURCES_IS_RESTRICTED_MODE_LIST,
} from "@raiden/library/constants/resources";
import { forwardRef } from "react";
import RadioGroupRaiden from "../RadioGroupRaiden";

const Restriction = forwardRef(
  /**
   * @param {object} params
   * @param {string} params.subject
   * @param {boolean} params.withIcon
   * @param {string} params.name
   * @param {any} params.value
   * @param {(event: {target: {name: string, value: any}}) => void} params.onChange
   * @param {"row" | "column"} [params.variant]
   */
  function _Restriction(
    {
      subject,
      name,
      withIcon = false,
      value,
      onChange,
      variant = "row",
      ...otherProps
    },
    ref,
  ) {
    const intl = useIntl();

    const colorLabel = useColorModeValue("black", "white");
    const colorDescription = useColorModeValue("gray.700", "whiteAlpha.600");

    return (
      <RadioGroupRaiden
        {...otherProps}
        ref={ref}
        name={name}
        value={String(value)}
        onChange={onChange}>
        <Grid
          w="full"
          templateColumns={
            "row" === variant ? { lg: "repeat(3, 1fr)" } : undefined
          }
          gap="1rem"
          alignItems="baseline">
          {RESOURCES_IS_RESTRICTED_MODE_LIST.map((restriction) => (
            <GridItem alignSelf="stretch" key={restriction.id}>
              <Radio value={String(restriction.id)} alignSelf="stretch">
                <Flex alignItems="center">
                  {withIcon && restriction.icon && (
                    <Icon as={restriction.icon} mr="0.5rem" />
                  )}

                  <Text color={colorLabel}>
                    {intl.formatMessage(resourcesIsRestrictedModeMessage, {
                      isRestrictedMode: restriction.id,
                    })}
                  </Text>
                </Flex>

                <Text color={colorDescription} fontSize="0.875rem">
                  {intl.formatMessage(
                    resourcesIsRestrictedModeDescriptionMessage,
                    {
                      isRestrictedMode: restriction.id,
                      subject,
                    },
                  )}
                </Text>
              </Radio>
            </GridItem>
          ))}
        </Grid>
      </RadioGroupRaiden>
    );
  },
);

export default Restriction;

// deps
import PropTypes from "prop-types";
import { MenuItem } from "@chakra-ui/react";

export default function MenuSelectItem(props) {
  const passProps = { ...props };

  delete passProps.value;

  return <MenuItem {...passProps} />;
}

MenuSelectItem.propTypes = {
  value: PropTypes.string,
};

import { Center, Spinner } from "@chakra-ui/react";
import { buildDataHandlerComponent } from "@raiden/library/components/DataHandler";
import Splash from "@raiden/library/components/Splash";
import { apiGetErrorDetail } from "@raiden/library/helpers/api";
import { useIntl } from "react-intl";

function SplashNoResult() {
  const intl = useIntl();

  return (
    <Splash
      image="empty"
      title={intl.formatMessage({ defaultMessage: "Aucun résultat" })}
    />
  );
}

/**
 * @param {object} params
 * @param {import('@raiden/library/types/Api/ApiError').ApiError} params.error
 */
function SplashError({ error }) {
  const intl = useIntl();

  return (
    <Splash
      image="error"
      title={intl.formatMessage({
        defaultMessage: "Une erreur est survenue",
      })}
      description={apiGetErrorDetail({ error })}
    />
  );
}

const loadingElement = (
  <Center>
    <Spinner size="lg" />
  </Center>
);

export const DataHandler = buildDataHandlerComponent({
  SplashNoResult,
  SplashError,
  loadingElement,
});

// deps
import { forwardRef, useCallback } from "react";
import generateApiUrl from "../../../libraries/utils/generateApiUrl";
import { useIntl } from "react-intl";
import useLocale from "../../../hooks/useLocale";

// components
import Autocomplete from "../Autocomplete";

export const SearchCategoriesAutocomplete = forwardRef(
  /**
   * @typedef {Object} Props
   * @property {number[]} [environmentId]
   * @property {object} [queryParams]
   */
  /**
   * @param {Props & import("../Autocomplete").AutocompleteCommonProps<number[]>} props
   */
  function SearchCategoriesAutocomplete(
    { environmentId = [], queryParams = {}, ...otherProps },
    ref,
  ) {
    const { locale } = useLocale();

    const resolveItemValue = useCallback(({ item }) => item?.id, []);

    const resolveItemName = useCallback(
      ({ item }) => (item && item.name ? item.name[locale] : undefined),
      [locale],
    );

    const intl = useIntl();

    const generateUrl = useCallback(
      ({ page, fields }) =>
        generateApiUrl({
          id: "@api.search.categories.viewAny",
          query: {
            page,
            per_page: 2,
            environment_id: environmentId,
            ...fields,
            ...queryParams,
          },
        }),
      [environmentId, queryParams],
    );

    const generateHydrationUrl = useCallback(
      ({ valuesToHydrate }) =>
        generateApiUrl({
          id: "@api.search.categories.viewAny",
          query: {
            only_id: valuesToHydrate,
          },
        }),
      [],
    );

    return (
      <Autocomplete
        ref={ref}
        generateUrl={generateUrl}
        generateHydrationUrl={generateHydrationUrl}
        resolveItemValue={resolveItemValue}
        resolveItemName={resolveItemName}
        isMultiple={true}
        placeholder={intl.formatMessage({
          defaultMessage: "Catégories",
        })}
        {...otherProps}
      />
    );
  },
);

// deps
import { Box, IconButton, VisuallyHidden } from "@chakra-ui/react";
import { Children, isValidElement } from "react";
import { useController } from "react-hook-form";
import { useIntl } from "react-intl";

// components
import MenuSelect from "../MenuSelect";

// contexts
import { useSearch } from "@raiden/library/contexts/Search";
import { MdSort } from "react-icons/md";

/**
 * @typedef {object} Props
 */

/**
 * @param {import("react").PropsWithChildren<Props>} props
 * @return {import("react").FunctionComponentElement<Props>}
 */
function SearchCommonSortListRHF(props) {
  const { children } = props;

  const intl = useIntl();

  const { searchId, onSubmit } = useSearch();

  const { field } = useController({ name: "sort" });

  /**
   * Gère la modification du tri.
   * @param {object} event
   */
  function handleChange(event) {
    field.onChange(event);
    onSubmit();
  }

  return (
    <Box>
      <VisuallyHidden data-search-id={searchId}>
        {intl.formatMessage({
          defaultMessage: "Tri",
        })}
      </VisuallyHidden>

      <MenuSelect
        name="sort"
        value={field.value}
        onChange={handleChange}
        placeholder={intl.formatMessage({
          defaultMessage: "Tri",
        })}
        icon={
          <IconButton
            variant="outline"
            colorScheme="gray"
            size="sm"
            aria-label={intl.formatMessage({
              defaultMessage: "Trier",
            })}
            icon={<MdSort />}
          />
        }>
        {Children.toArray(children)
          .filter(
            (child) =>
              isValidElement(child) &&
              "option" === child.type &&
              "props" in child,
          )
          .map((child) => {
            const { value, children } = child.props;
            return (
              <MenuSelect.Item key={value} value={value}>
                {children}
              </MenuSelect.Item>
            );
          })}
      </MenuSelect>
    </Box>
  );
}

SearchCommonSortListRHF.propTypes = {};

export default SearchCommonSortListRHF;

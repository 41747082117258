import {
  Box,
  ButtonGroup,
  CircularProgress,
  CircularProgressLabel,
  Flex,
  Icon,
  IconButton,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import ResourceState from "@raiden/library/components/Resource/ResourceState";
import { SHORT_DATETIME_OPTIONS } from "@raiden/library/constants/intl";
import {
  tasksTypesMessage,
  TASKS_OBJECT_TYPES,
  TASKS_STATES,
  TASKS_TYPES,
} from "@raiden/library/constants/tasks";
import {
  getTaskProgress,
  getTaskProgressLabel,
} from "@raiden/library/helpers/tasks";
import { TaskType } from "@raiden/library/types/Task";
import Table from "@splitfire-agency/raiden-library/dist/components/Table";
import PropTypes from "prop-types";
import { MdOutlineCancel, MdOutlineRedo, MdSearch } from "react-icons/md";
import { useIntl } from "react-intl";
import TasksActionsCancel from "../Actions/Cancel";
import TasksActionsRetry from "../Actions/Retry";
import TasksActionsView from "../Actions/View";

/**
 * @typedef Props
 * @property {import("@raiden/library/types/Task").Task} resource
 * @property {() => void} mutate
 */

/**
 * @param {Props} props
 * @return {import("react").FunctionComponentElement<Props>}
 */
function TaskSearchItem({ resource, mutate }) {
  const intl = useIntl();

  return (
    <Table.Tr key={resource.id}>
      <Table.Td>
        <Flex>
          {TASKS_OBJECT_TYPES[resource?.object_type]?.icon && (
            <Icon
              as={TASKS_OBJECT_TYPES[resource.object_type].icon}
              mr="0.5rem"
            />
          )}

          <Flex flexDir="column">
            {TASKS_TYPES[resource?.type] ? (
              <Text>
                {intl.formatMessage(
                  tasksTypesMessage,

                  { type: resource.type.split("\\").join("") },
                )}
              </Text>
            ) : (
              <Text>
                {intl.formatMessage({ defaultMessage: "Tâche inconnue" })}
              </Text>
            )}

            {resource?.object?.display_name && (
              <Text fontSize=".75rem">{resource?.object?.display_name}</Text>
            )}
          </Flex>
        </Flex>
      </Table.Td>

      <Table.Td>
        <Text>
          <ResourceState state={resource.state} customStates={TASKS_STATES} />
        </Text>
      </Table.Td>

      <Table.Td>
        {resource.progress !== null && resource.max_progress !== null ? (
          <Tooltip
            label={intl.formatMessage(
              {
                defaultMessage:
                  "{taskProgression} {maxProgress, plural, =0 {tâche} other {tâches}}",
              },
              {
                maxProgress: resource.max_progress,
                taskProgression: `${resource.progress}/${resource.max_progress}`,
              },
            )}
            placement="bottom-start">
            <Box cursor="pointer">
              <CircularProgress
                value={getTaskProgress({ task: resource })}
                color={TASKS_STATES[resource.state].colors[0]}>
                <CircularProgressLabel>
                  {getTaskProgressLabel({ task: resource })}
                </CircularProgressLabel>
              </CircularProgress>
            </Box>
          </Tooltip>
        ) : (
          <Text>
            {intl.formatMessage({
              defaultMessage: "Non renseigné",
            })}
          </Text>
        )}
      </Table.Td>

      <Table.Td>
        <Flex direction="column" gap="5px">
          <Text>
            {intl.formatDate(resource?.created_at, SHORT_DATETIME_OPTIONS)}
          </Text>

          <Text>
            {intl.formatDate(resource?.started_at, SHORT_DATETIME_OPTIONS)}
          </Text>
        </Flex>
      </Table.Td>

      <Table.Td>
        <ButtonGroup>
          <TasksActionsView resource={resource} mutate={mutate}>
            {({ disclosure }) => (
              <Tooltip
                label={intl.formatMessage({
                  defaultMessage: "Voir le détail",
                })}>
                <Box>
                  <IconButton
                    size="sm"
                    variant="outline"
                    colorScheme="darkGray"
                    aria-label={intl.formatMessage({
                      defaultMessage: "Voir le détail",
                    })}
                    icon={<Icon as={MdSearch} />}
                    onClick={disclosure.onOpen}
                  />
                </Box>
              </Tooltip>
            )}
          </TasksActionsView>

          <TasksActionsCancel resource={resource} onSuccess={mutate}>
            {function ({ disclosure }) {
              return (
                <Box>
                  <Tooltip
                    label={intl.formatMessage({
                      defaultMessage: "Annuler la tâche",
                    })}>
                    <IconButton
                      size="sm"
                      variant="outline"
                      colorScheme="darkGray"
                      aria-label={intl.formatMessage({
                        defaultMessage: "Annuler la tâche",
                      })}
                      icon={
                        <Icon
                          as={MdOutlineCancel}
                          onClick={disclosure.onOpen}
                        />
                      }
                    />
                  </Tooltip>
                </Box>
              );
            }}
          </TasksActionsCancel>

          <TasksActionsRetry resource={resource} onSuccess={mutate}>
            {function ({ disclosure }) {
              return (
                <Box>
                  <Tooltip
                    label={intl.formatMessage({
                      defaultMessage: "Réesayer la tâche",
                    })}>
                    <IconButton
                      size="sm"
                      variant="outline"
                      colorScheme="darkGray"
                      aria-label={intl.formatMessage({
                        defaultMessage: "Réesayer la tâche",
                      })}
                      icon={
                        <Icon as={MdOutlineRedo} onClick={disclosure.onOpen} />
                      }
                    />
                  </Tooltip>
                </Box>
              );
            }}
          </TasksActionsRetry>
        </ButtonGroup>
      </Table.Td>
    </Table.Tr>
  );
}

TaskSearchItem.propTypes = {
  resource: TaskType.isRequired,
  mutate: PropTypes.func.isRequired,
};

export default TaskSearchItem;

import { Tag, TagLabel, TagLeftIcon } from "@chakra-ui/react";

/**
 * @typedef {Object} Props
 * @property {string} label
 * @property {string} [color]
 * @property {import("react-icons").IconType} [icon]
 *
 * @param {Props} props
 */
function ResourceTag({ icon: Icon, color, label }) {
  return (
    <Tag
      size="lg"
      borderRadius="full"
      borderWidth="0.0625rem"
      borderColor={color}>
      {Icon && <TagLeftIcon color={color} boxSize="1.25rem" as={Icon} />}

      <TagLabel color={color}>{label}</TagLabel>
    </Tag>
  );
}

export default ResourceTag;

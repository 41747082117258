// deps
import { createContext } from "react";

/**
 * @typedef {object} ResetValue
 * @property {boolean} [shouldSubmit]
 */

/**
 * @template {any} T
 * @typedef {object} SearchContext
 * @property {string} searchId the value to use inject into the `data-search-id` attributes of labels
 * @property {any} tagsRefreshId a references that changes when the tags should be refreshed
 * @property {false | ((params: {fields: Record<string, any>}) => void)} paginationUrl
 * @property {Array<number>} displayedPerPages
 * @property {boolean} preventSubmitIfNotDirty
 * @property {import("react-hook-form").UseFormReturn} form
 * @property {object} submittedFields
 * @property {import("swr").SWRResponse<import("../../types/Api/ApiResponse").ApiResponse<T, object>>} response
 * @property {import("react").Dispatch<import("react").SetStateAction<{refreshInterval: number}>>} setSwrOptions
 * @property {import("swr").SWRConfiguration} swrOptions
 * @property {(Event?, object?) => void} onSubmit
 * @property {() => void} updateTags
 * @property {(data?: ResetValue) => void} reset
 * @property {object} defaultValues
 * @property {false|Function} routerSyncUrl
 */

/** @type {SearchContext<any>} */
const defaultValues = {
  searchId: "",
  tagsRefreshId: {},
  paginationUrl: false,
  setSwrOptions: () => {},
  swrOptions: { refreshInterval: 0 },
  displayedPerPages: [],
  preventSubmitIfNotDirty: false,
  isFormValid: false,
  // @ts-ignore ignored because it can't be mocked
  form: {},
  submittedFields: {},
  response: {
    data: undefined,
    error: undefined,
    isValidating: false,
    isLoading: false,
    // @ts-ignore ignored because it can't be mocked
    mutate: () => {},
  },
  onSubmit: () => {},
  updateTags: () => {},
  reset: () => {},
  routerSyncUrl: false,
};

const SearchContext = createContext(defaultValues);

export default SearchContext;

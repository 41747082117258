import { Text } from "@chakra-ui/react";
import getRecursiveBreadcrumbItems from "../../../helpers/search/getRecursiveBreadcrumbItems";
import { useIntl } from "react-intl";

/**
 * @typedef Props
 * @property {import("@raiden/library/types/File").File} resource
 * @property {import("react").ReactNode} [children]
 * @return {import("react").FunctionComponentElement<Props>}
 */
/**
 * @param {Props} props
 */
export default function FilePath({ resource, children }) {
  const parent = resource?.parent ?? null;

  const intl = useIntl();

  const rootFallback = { name: "root", id: null };

  const recursiveBreadcrumbItems = parent
    ? getRecursiveBreadcrumbItems({
        parent: parent,
      })
    : [rootFallback];

  const filePath = recursiveBreadcrumbItems
    .map((item) =>
      intl.formatMessage(
        {
          defaultMessage:
            "{name, select, root {Dossier racine} dots {...} other {{name}}}",
        },
        { name: item?.name },
      ),
    )
    .join("/");

  return (
    <>
      {typeof children === "function" ? (
        children(filePath)
      ) : (
        <Text colorScheme="gray" fontSize=".85rem" width="full">
          {filePath}
        </Text>
      )}
    </>
  );
}

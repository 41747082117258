import Data from "./Data";
import PropTypes from "prop-types";
import generateApiUrl from "@raiden/library/libraries/utils/generateApiUrl";
import { ShowProvider } from "@raiden/library/contexts/Show";

function endpointUrl(fileId) {
  return generateApiUrl({
    id: "@api.files.view",
    parameters: {
      fileId: fileId,
    },
    query: {
      fields: [
        "file.policies",
        "file.environment",
        "file.owner",
        "file.parent",
        "file.children.count",
      ],
    },
  });
}

/**
 * @typedef Props
 * @property {number} fileId,
 * @property {boolean} isOpen
 * @property {() => void} onClose
 * @property {() => void} [onSuccess]
 * @property {boolean} isTrash
 */
/**
 * @param {import("react").PropsWithChildren<Props>} props
 * @return {import("react").FunctionComponentElement<Props>}
 */
export default function View({ fileId, isOpen, onClose, onSuccess, isTrash }) {
  return (
    <ShowProvider endpointUrl={endpointUrl(fileId)}>
      <Data
        isOpen={isOpen}
        onClose={onClose}
        mutateSearch={onSuccess}
        isTrash={isTrash}
      />
    </ShowProvider>
  );
}

View.propTypes = {
  fileId: PropTypes.number.isRequired,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onSuccess: PropTypes.func,
  isTrash: PropTypes.bool,
};

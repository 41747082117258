import { Box, DrawerBody, DrawerFooter } from "@chakra-ui/react";
import { usePreferences } from "@raiden/library/contexts/Preferences";
import { SearchPagination, useSearch } from "@raiden/library/contexts/Search";
import TaskDrawerData from "./Data";
import TaskDrawerSearchFilters from "./Filters";

export default function TaskDrawerBody() {
  const { response } = useSearch();
  const { data, mutate } = response;

  const taskList = data?.data ?? [];

  const { globalColorMode } = usePreferences();

  return (
    <>
      <DrawerBody m="0" p="0" overflowY="auto">
        <Box
          // Sticky header with high z-index to stay above and at top of list while content is scrolled
          position="sticky"
          top="0"
          zIndex="9999"
          padding=".5rem 1rem"
          background={globalColorMode === "light" ? "white" : "gray.700"}>
          <TaskDrawerSearchFilters />
        </Box>

        <Box padding="0 .5rem">
          <TaskDrawerData taskList={taskList} mutate={mutate} />
        </Box>
      </DrawerBody>

      <DrawerFooter>{taskList.length > 0 && <SearchPagination />}</DrawerFooter>
    </>
  );
}

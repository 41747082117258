// deps
import { useCallback } from "react";

// hooks
import useLocale from "./useLocale";

/**
 * @callback Translate
 * @param {import("../types/common/TranslatedField").TranslatedField | null} [obj]
 * @returns {string | undefined}
 */

/**
 * @param {object} params
 * @param {string} [params.currentLocale]
 * @param {string} [params.defaultLocale]
 * @returns {Translate}
 */
export function _getTranslate({ currentLocale, defaultLocale }) {
  /** @type {Translate} */
  return function translate(obj) {
    return _translate({ obj, currentLocale, defaultLocale });
  };
}

/**
 * @param {object} params
 * @param {import("../types/common/TranslatedField").TranslatedField | null} [params.obj]
 * @param {string} [params.currentLocale]
 * @param {string} [params.defaultLocale]
 */
export function _translate({ obj, currentLocale, defaultLocale }) {
  if (
    null != obj &&
    currentLocale !== undefined &&
    Object.prototype.hasOwnProperty.call(obj, currentLocale)
  ) {
    return obj[currentLocale];
  }

  return defaultLocale !== undefined ? obj?.[defaultLocale] : undefined;
}

/**
 * Hook qui retourne une fonction qui prend en paramètre un champ issue de l’API
 *   (de la forme { [lang]: string }) et qui va automatiquement
 *   afficher la valeur de la langue courante (si la valeur existe), ou à défaut la langue de base.
 *  Il est possible de forcer la locale en utilisant le paramètre forceLocale
 * @param {object} [options]
 * @param {string} [options.forceLocale]
 */
export default function useTranslate(options = {}) {
  const { forceLocale } = options;
  const { locale, defaultLocale } = useLocale();
  const currentLocale = forceLocale ?? locale;

  const interpolate = useCallback(
    /**
     * @type {Translate}
     */
    function (obj) {
      return _translate({ obj, currentLocale, defaultLocale });
    },
    [currentLocale, defaultLocale],
  );

  return interpolate;
}

// deps
import { mode } from "@chakra-ui/theme-tools";

export default {
  global: (props) => ({
    "html, body": {
      height: "100%",
      bgColor: "gray.700",
    },
    body: {
      fontFamily: "Helvetica, sans-serif",
    },
    "#__next": {
      height: "100%",
    },
    "*::placeholder": {
      color: mode("gray.400", "gray.500")(props),
    },
    ".renderer h1": {
      fontFamily: "'Cocon', sans-serif",
      fontSize: "2.5rem",
      fontWeight: 300,
      lineHeight: "normal",
      color: "brandPrimary.900",
    },
    ".renderer h2": {
      fontFamily: "'Cocon', sans-serif",
      fontSize: "2rem",
      fontWeight: 300,
      lineHeight: "normal",
      color: "brandPrimary.700",
    },
    ".renderer h3": {
      fontFamily: "'Cocon', sans-serif",
      fontSize: "1.5rem",
      fontWeight: 300,
      lineHeight: "normal",
      color: "#3D557B",
    },
    ".renderer h4": {
      fontFamily: "'Cocon', sans-serif",
      fontSize: "1.375rem",
      fontWeight: 300,
      lineHeight: "normal",
      color: "#3D557B",
    },
    ".renderer h5": {
      fontFamily: "'Cocon', sans-serif",
      fontSize: "1.25rem",
      fontWeight: 300,
      lineHeight: "normal",
      color: "#3D557B",
    },
    ".renderer h6": {
      fontFamily: "'Cocon', sans-serif",
      fontSize: "1.125rem",
      fontWeight: 300,
      lineHeight: "normal",
      color: "#3D557B",
    },
    ".renderer p, .renderer .paragraph, .renderer ul": {
      fontSize: "1rem",
      fontWeight: 400,
      lineHeight: "1.3125rem",
      color: "#3D557B",
      mt: "0.875rem",
      ":first-child": {
        mt: 0,
      },
    },
    ".renderer ul": {
      marginLeft: "1.5rem",
    },
  }),
};

import PropTypes from "prop-types";
import { useDisclosure } from "@chakra-ui/react";
import FilesRestore from "../Restore";
import { POLICIES_POLICY_VALUE_RESTORE } from "@raiden/library/constants/policies";
import useCanPerform from "@raiden/library/hooks/useCanPerform";
import { FileType } from "@raiden/library/types/File";

/**
 * @callback childrenRender
 * @param {{disclosure: import("@chakra-ui/react").UseDisclosureReturn}} disclosure
 */

/**
 * @typedef {Object} Props
 * @property {import("@raiden/library/types/File").File} resource
 * @property {() => void} onSuccess
 * @property {childrenRender} children
 * @property {boolean} [isInModal]
 */

/**
 * @param {Props} props
 * @return {import("react").FunctionComponentElement<Props>}
 */
function FilesActionsRestore(props) {
  const { resource, onSuccess, children, isInModal = false } = props;

  const disclosure = useDisclosure();

  const { isOpen, onClose } = disclosure;

  /**
   * Gère le succès de la suppression d'un menu
   */
  function handleSuccess() {
    onClose();
    onSuccess();
  }

  const canPerform = useCanPerform();

  const filePolicies = resource?.meta?.policies ?? [];

  return (
    <>
      {canPerform({
        policies: POLICIES_POLICY_VALUE_RESTORE,
        resourcePolicies: filePolicies,
      }) && (
        <>
          <FilesRestore
            file={resource}
            isOpen={isOpen}
            onClose={onClose}
            onSuccess={handleSuccess}
            isInModal={isInModal}
          />

          {children({ disclosure })}
        </>
      )}
    </>
  );
}

FilesActionsRestore.propTypes = {
  children: PropTypes.func,
  onSuccess: PropTypes.func,
  resource: FileType,
  isInModal: PropTypes.bool,
};

export default FilesActionsRestore;

/* istanbul ignore file */
import { Box, Text, VStack } from "@chakra-ui/react";
import ResourceLoading from "@splitfire-agency/raiden-library/dist/components/Resource/Loading";
import { useIntl } from "react-intl";
import { useSearch } from ".";
import Error from "../../components/Error";
import Splash from "../../components/Splash";

/**
 * @typedef {object} Props
 * @property {Function} renderData
 * @property {Function} [renderNoResult]
 * @property {Function} [renderNoResponse]
 */

/**
 * @param {import("react").PropsWithChildren<Props>} props
 * @return {import("react").FunctionComponentElement<Props>}
 */
export default function SearchData(props) {
  const { renderData, renderNoResult, renderNoResponse } = props;

  const intl = useIntl();

  const {
    response: { data, error, isValidating },
    form,
  } = useSearch();

  // Si soumis mais pas de succès
  if (form.formState.isSubmitted && !form.formState.isSubmitSuccessful) {
    return (
      <Box>
        <VStack spacing="1rem">
          <Text fontWeight="bold">
            {intl.formatMessage({
              defaultMessage: "Informations manquantes",
            })}
          </Text>

          <Text color="gray.500">
            {intl.formatMessage({
              defaultMessage: "Un champ obligatoire n’a pas été rempli.",
            })}
          </Text>
        </VStack>
      </Box>
    );
  }

  // Si en cours de chargement
  if (isValidating) {
    return (
      <ResourceLoading
        // @ts-ignore
        padding="1rem"
        label={intl.formatMessage({
          defaultMessage: "Chargement des résultats…",
        })}
      />
    );
  }

  // Si pas de réponse
  if (!data && !error && renderNoResponse) {
    return renderNoResponse();
  }

  // Si reponse
  if (data && !error) {
    // Si résultats
    if (data?.data?.length > 0) {
      return renderData();
    }

    // Si pas de résultats (rendu personnalisé)
    if (renderNoResult) {
      return renderNoResult();
    }

    // Si pas de résultats (rendu par défaut)
    return (
      <Box>
        <VStack spacing="1rem">
          <Splash
            title={intl.formatMessage({
              defaultMessage: "Pas de résultats",
            })}
            description={intl.formatMessage({
              defaultMessage:
                "Aucun résultat trouvé, essayez d’élargir votre recherche.",
            })}
            image="empty"
          />
        </VStack>
      </Box>
    );
  }

  // Si erreur
  return <Error.Global type="viewAny" error={error} />;
}

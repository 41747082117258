import { useEffect, useRef } from "react";
import { useWatch } from "react-hook-form";

let timeoutId = undefined;

/**
 * @param {Function} func
 * @param {number} wait
 */
function debounce(func, wait) {
  clearTimeout(timeoutId);
  timeoutId = setTimeout(() => func(), wait);
}

/**
 * Un composant responsable de la soumission automatique d'un formulaire
 *
 * @typedef {Object} Props
 * @property {() => void} onSubmit
 *
 * @param {Props} props
 */
export function FormAutoSubmit({ onSubmit }) {
  const values = useWatch();

  const refLastValues = useRef(values);

  useEffect(() => {
    if (JSON.stringify(values) !== JSON.stringify(refLastValues.current)) {
      debounce(onSubmit, 500);
    }
    refLastValues.current = values;
  }, [onSubmit, values]);

  return null;
}

export default FormAutoSubmit;

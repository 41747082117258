import {
  Flex,
  Grid,
  GridItem,
  Icon,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import {
  FILES_VISIBILITY_LIST,
  fileVisibilityDescriptionMessage,
  fileVisibilityMessage,
} from "@raiden/library/constants/files";
import { forwardRef } from "react";
import { useIntl } from "react-intl";
import RadioGroupRaiden from "../../../../components/Form/RadioGroupRaiden";
import Radio from "../../../../components/Radio";

const FileVisibility = forwardRef(
  /**
   * @param {object} params
   * @param {boolean} [params.withIcon]
   * @param {string} params.name
   * @param {any} params.value
   * @param {(event: {target: {name: string, value: any}}) => void} params.onChange
   * @param {"row" | "column"} [params.variant]
   */
  function _Visibility(
    { name, withIcon = false, variant = "row", value, onChange, ...otherProps },
    ref,
  ) {
    const intl = useIntl();

    const colorLabel = useColorModeValue("black", "white");
    const colorDescription = useColorModeValue("gray.700", "whiteAlpha.600");

    return (
      <RadioGroupRaiden
        {...otherProps}
        ref={ref}
        name={name}
        value={String(value)}
        onChange={onChange}>
        <Grid
          w="full"
          templateColumns={
            "row" === variant ? { lg: "repeat(2, 1fr)" } : undefined
          }
          gap="1rem"
          alignItems="baseline">
          {FILES_VISIBILITY_LIST.map((visibility) => (
            <GridItem key={visibility.id}>
              <Radio value={visibility.id}>
                <Flex alignItems="center">
                  {withIcon && visibility.icon && (
                    <Icon as={visibility.icon} mr="0.5rem" />
                  )}

                  <Text color={colorLabel}>
                    {intl.formatMessage(fileVisibilityMessage, {
                      visibility: visibility.id,
                    })}
                  </Text>
                </Flex>

                <Text color={colorDescription} fontSize="0.875rem">
                  {intl.formatMessage(fileVisibilityDescriptionMessage, {
                    visibility: visibility.id,
                  })}
                </Text>
              </Radio>
            </GridItem>
          ))}
        </Grid>
      </RadioGroupRaiden>
    );
  },
);

export default FileVisibility;

const MAX_ITEMS = 5;
const BREADCRUMB_ROOT = "root";
export const SEARCH_COMMON_BREADCRUMB_DOTS = "dots";

function getParentName({ parent }) {
  return parent?.name ? parent?.name : parent?.filename ? parent?.filename : "";
}

/**
 * @typedef {object} META_PARENT
 * @property {number} id
 * @property {string} name
 * @property {META_PARENT} parent
 */

/**
 * @typedef {Object} Props
 * @property {META_PARENT} parent
 * @property {number} [depth]
 */
/**
 * @param {Props} props
 * @return {Array}
 */
export default function getRecursiveBreadcrumbItems({ parent, depth = 0 }) {
  let items = [];
  let maxDepth = 0;

  if (parent?.parent) {
    items = getRecursiveBreadcrumbItems({
      parent: parent.parent,
      depth: depth + 1,
    });
  } else {
    maxDepth = depth;
    items.push({ id: null, name: BREADCRUMB_ROOT });
  }

  const acceptedDepth = [0, 1, maxDepth - 1, maxDepth];

  if ((maxDepth && maxDepth < MAX_ITEMS) || acceptedDepth.includes(depth)) {
    if (parent) {
      items.push({ id: parent?.id, name: getParentName({ parent }) });
    }
  } else if (items[items.length - 1].name !== SEARCH_COMMON_BREADCRUMB_DOTS) {
    items.push({
      id: null,
      name: SEARCH_COMMON_BREADCRUMB_DOTS,
    });
  }

  return items;
}

// deps
import PropTypes from "prop-types";
import { Box, Flex, HStack, Text } from "@chakra-ui/react";
import { useIntl } from "react-intl";

// components
import PaginationButton from "./PaginationButton";

function getPages({ page, pageTotal, href, onClick }) {
  let index = 0;

  const pages = [
    <PaginationButton
      key={index++}
      href={href ? href({ page: 1 }) : undefined}
      isActive={page === 1}
      onClick={function (event) {
        onClick(event, { page: 1 });
      }}>
      {1}
    </PaginationButton>,
    <PaginationButton
      key={index++}
      href={href ? href({ page: pageTotal }) : undefined}
      isActive={page === pageTotal}
      onClick={function (event) {
        onClick(event, { page: pageTotal });
      }}>
      {pageTotal}
    </PaginationButton>,
  ];

  if (pageTotal - 1 > 1 && page !== 1 && page !== pageTotal) {
    pages.splice(
      1,
      0,
      <PaginationButton
        key={index++}
        href={href ? href({ page }) : undefined}
        isActive={true}
        onClick={function (event) {
          onClick(event, { page: page });
        }}>
        {page}
      </PaginationButton>,
    );
  }

  if (page - 1 > 1) {
    pages.splice(
      1,
      0,
      <PaginationButton
        key={index++}
        href={href ? href({ page: page - 1 }) : undefined}
        isActive={false}
        onClick={function (event) {
          onClick(event, { page: page - 1 });
        }}>
        {page - 1}
      </PaginationButton>,
    );
  }

  if (page + 1 < pageTotal) {
    pages.splice(
      pages.length - 1,
      0,
      <PaginationButton
        key={index++}
        href={href ? href({ page: page + 1 }) : undefined}
        isActive={false}
        onClick={function (event) {
          onClick(event, { page: page + 1 });
        }}>
        {page + 1}
      </PaginationButton>,
    );
  }

  if (page - 2 > 2) {
    pages.splice(1, 0, <PaginationButton key={index++}>…</PaginationButton>);
  } else if (page - 2 === 2) {
    pages.splice(
      1,
      0,
      <PaginationButton
        key={index++}
        href={href ? href({ page: 2 }) : undefined}
        isActive={page === 2}
        onClick={function (event) {
          onClick(event, { page: 2 });
        }}>
        {2}
      </PaginationButton>,
    );
  }

  if (page + 2 < pageTotal - 1) {
    pages.splice(
      pages.length - 1,
      0,
      <PaginationButton key={index++}>…</PaginationButton>,
    );
  } else if (page + 2 === pageTotal - 1) {
    pages.splice(
      pages.length - 1,
      0,
      <PaginationButton
        key={index++}
        href={href ? href({ page: pageTotal - 1 }) : undefined}
        isActive={page === pageTotal - 1}
        onClick={function (event) {
          onClick(event, { page: pageTotal - 1 });
        }}>
        {pageTotal - 1}
      </PaginationButton>,
    );
  }

  return pages;
}

export default function Pagination(props) {
  const {
    total,
    href,
    perPage,
    page,
    responsive,
    displayedPerPages,
    shouldDisablePerPageOverflow,
    onChangePage,
    onChangePerPage,
  } = props;

  const intl = useIntl();

  const pageTotal = Math.ceil(total / perPage);

  const pages = getPages({ page, pageTotal, href, onClick: onChangePage });

  return (
    <Flex w="full" role="navigation" alignItems="center" flexWrap="wrap">
      <Box>
        <Text>
          {intl.formatMessage(
            {
              defaultMessage:
                "{total, plural, =0 {aucun résultats} one {1 résultat} other {# résultats}}",
            },
            { total },
          )}
        </Text>
      </Box>

      <Flex flexGrow={1} justifyContent={responsive ? "flex-end" : "center"}>
        {pageTotal > 1 && (
          <HStack spacing="0.625rem">
            {pages[0] && pages[0]}

            {pages[1] && pages[1]}

            {pages[2] && pages[2]}

            {pages[3] && pages[3]}

            {pages[4] && pages[4]}

            {pages[5] && pages[5]}

            {pages[6] && pages[6]}
          </HStack>
        )}
      </Flex>

      {displayedPerPages.length > 0 && (
        <HStack
          justifyContent={responsive ? "flex-end" : undefined}
          flexBasis={responsive ? "100%" : undefined}
          mt={responsive ? "0.625rem" : undefined}>
          <Text>
            {intl.formatMessage(
              {
                defaultMessage: "Éléments par page :",
              },
              { total },
            )}
          </Text>

          <HStack spacing={"0.625rem"}>
            {displayedPerPages.map(function (displayedPerPage) {
              return (
                <PaginationButton
                  isActive={perPage === displayedPerPage}
                  isDisabled={
                    shouldDisablePerPageOverflow &&
                    displayedPerPage > Math.ceil(total / perPage)
                  }
                  href={href ? href({ perPage: displayedPerPage }) : undefined}
                  key={displayedPerPage}
                  onClick={function (event) {
                    onChangePerPage(event, {
                      perPage: displayedPerPage,
                    });
                  }}>
                  {displayedPerPage}
                </PaginationButton>
              );
            })}
          </HStack>
        </HStack>
      )}
    </Flex>
  );
}

Pagination.propTypes = {
  perPage: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  responsive: PropTypes.bool,
  href: PropTypes.func,
  displayedPerPages: PropTypes.array,
  shouldDisablePerPageOverflow: PropTypes.bool,
  onChangePage: PropTypes.func,
  onChangePerPage: PropTypes.func,
};

Pagination.defaultProps = {
  shouldDisablePerPageOverflow: false,
  onChangePage() {},
  onChangePerPage() {},
};

import PropTypes from "prop-types";
import { POLICIES_POLICY_VALUE_DOWNLOAD } from "@raiden/library/constants/policies";
import useCanPerform from "@raiden/library/hooks/useCanPerform";

/**
 * @typedef {Object} Props
 * @property {import("@raiden/library/types/File").File} resource
 * @property {React.ReactNode} children
 */

/**
 * @param {Props} props
 * @return {import("react").FunctionComponentElement<Props>}
 */
export default function FilesActionsDownload(props) {
  const { resource, children } = props;

  const canPerform = useCanPerform();

  const policies = resource?.meta?.policies ?? [];

  return (
    <>
      {canPerform({
        policies: POLICIES_POLICY_VALUE_DOWNLOAD,
        resourcePolicies: policies,
      }) && <>{children}</>}
    </>
  );
}

FilesActionsDownload.propTypes = {
  children: PropTypes.node,
  resource: PropTypes.object,
};

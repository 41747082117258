import { MdPause, MdPlayArrow } from "react-icons/md";
import { defineMessage } from "react-intl";

export const CUSTOMERS_STATES_VALUE_ENABLED = "enabled";
export const CUSTOMERS_STATES_VALUE_DISABLED = "disabled";

export const CUSTOMERS_STATES = {
  [CUSTOMERS_STATES_VALUE_ENABLED]: {
    id: CUSTOMERS_STATES_VALUE_ENABLED,
    colors: ["green.600", "green.300"],
    color: "green",
    icon: MdPlayArrow,
    isVisible: true,
  },
  [CUSTOMERS_STATES_VALUE_DISABLED]: {
    id: CUSTOMERS_STATES_VALUE_DISABLED,
    colors: ["purple.600", "purple.300"],
    color: "purple",
    icon: MdPause,
    isVisible: true,
  },
};

export const CUSTOMERS_STATES_LIST = Object.values(CUSTOMERS_STATES);

export const CUSTOMERS_STATES_MESSAGE = defineMessage({
  defaultMessage:
    "{state, select, enabled {activé} disabled {désactivé} other {inconnu}}",
});

export const CUSTOMERS_STATES_VERB_MESSAGE = defineMessage({
  defaultMessage:
    "{state, select, enabled {activer} disabled {désactiver} other {inconnu}}",
});

// ============================================================

export const CUSTOMERS_TYPES_VALUE_PARTICULAR = "particular";
export const CUSTOMERS_TYPES_VALUE_SOCIETY = "society";

export const CUSTOMERS_TYPES = {
  [CUSTOMERS_TYPES_VALUE_PARTICULAR]: {
    id: CUSTOMERS_TYPES_VALUE_PARTICULAR,
    label: defineMessage({ defaultMessage: "Particulier" }),
  },
  [CUSTOMERS_TYPES_VALUE_SOCIETY]: {
    id: CUSTOMERS_TYPES_VALUE_SOCIETY,
    label: defineMessage({ defaultMessage: "Société" }),
  },
};

export const CUSTOMERS_TYPES_LIST = Object.values(CUSTOMERS_TYPES);

export const CUSTOMERS_TYPES_MESSAGE = defineMessage({
  defaultMessage:
    "{type, select, particular {particulier} society {société} other {inconnu}}",
});

// ============================================================

export const CUSTOMERS_SEARCH_TABS_MESSAGE = defineMessage({
  defaultMessage:
    "{tab, select, all {Tous} customers {Clients} sellers {Vendeurs} trash {Corbeille} pending_moderation {En attente de confirmation} moderated {Modéré} other {Inconnu}}",
  description: "tab",
});

export const CUSTOMERS_SEARCH_TABS_VALUE_ALL = {
  value: "all",
  fields: [],
  defaultFields: [],
  colors: null,
  metaKey: "count",
};
export const CUSTOMERS_SEARCH_TABS_VALUE_TRASH = {
  value: "trash",
  fields: [{ name: "only_trashed", value: 1 }],
  defaultFields: [{ name: "only_trashed", value: undefined }],
  colors: ["red.600", "red.400"],
  metaKey: null,
};
export const CUSTOMERS_SEARCH_TABS = [
  CUSTOMERS_SEARCH_TABS_VALUE_ALL,
  CUSTOMERS_SEARCH_TABS_VALUE_TRASH,
];

// ============================================================

export const CUSTOMERS_SORT_VALUE_CREATE_AT_ASC = "created_asc";
export const CUSTOMERS_SORT_VALUE_CREATE_AT_DESC = "created_desc";
export const CUSTOMERS_SORT_VALUE_USERNAME_AT_ASC = "username_asc";
export const CUSTOMERS_SORT_VALUE_USERNAME_AT_DESC = "username_desc";
export const CUSTOMERS_SORT_VALUE_EMAIL_ASC = "email_asc";
export const CUSTOMERS_SORT_VALUE_EMAIL_DESC = "email_desc";

export const CUSTOMERS_SORTS = {
  [CUSTOMERS_SORT_VALUE_CREATE_AT_ASC]: {
    id: CUSTOMERS_SORT_VALUE_CREATE_AT_ASC,
  },
  [CUSTOMERS_SORT_VALUE_CREATE_AT_DESC]: {
    id: CUSTOMERS_SORT_VALUE_CREATE_AT_DESC,
  },
  [CUSTOMERS_SORT_VALUE_USERNAME_AT_ASC]: {
    id: CUSTOMERS_SORT_VALUE_USERNAME_AT_ASC,
  },
  [CUSTOMERS_SORT_VALUE_USERNAME_AT_DESC]: {
    id: CUSTOMERS_SORT_VALUE_USERNAME_AT_DESC,
  },
  [CUSTOMERS_SORT_VALUE_EMAIL_ASC]: {
    id: CUSTOMERS_SORT_VALUE_EMAIL_ASC,
  },
  [CUSTOMERS_SORT_VALUE_EMAIL_DESC]: {
    id: CUSTOMERS_SORT_VALUE_EMAIL_DESC,
  },
};

export const CUSTOMERS_SORT_LIST = Object.values(CUSTOMERS_SORTS);

export const CUSTOMERS_SORT_MESSAGE = defineMessage({
  defaultMessage:
    "{sort, select, created_asc {Date de création croissante} created_desc {Date de création décroissante} username_asc {Pseudonyme A ⇨ Z} username_desc {Pseudonyme Z ⇨ A}email_asc {Email A ⇨ Z} email_desc {Email Z ⇨ A} other {{sort}}}",
});

// ============================================================

export const CUSTOMERS_GENDERS_VALUE_MALE = "male";
export const CUSTOMERS_GENDERS_VALUE_FEMALE = "female";
export const CUSTOMERS_GENDERS_VALUE_OTHER = "other";

export const CUSTOMERS_GENDERS_MESSAGE = defineMessage({
  defaultMessage:
    "{gender, select, male {Monsieur} female {Madame} other {Autre}}",
});

export const CUSTOMERS_GENDERS_LIST = [
  CUSTOMERS_GENDERS_VALUE_MALE,
  CUSTOMERS_GENDERS_VALUE_FEMALE,
  CUSTOMERS_GENDERS_VALUE_OTHER,
];

// ============================================================

import { useDisclosure } from "@chakra-ui/react";
import { POLICIES_POLICY_VALUE_VIEW } from "@raiden/library/constants/policies";
import useCanPerform from "@raiden/library/hooks/useCanPerform";
import { TaskType } from "@raiden/library/types/Task";
import PropTypes from "prop-types";
import View from "../View";

/**
 * @callback childrenRender
 * @param {{disclosure: import("@chakra-ui/react").UseDisclosureReturn}} disclosure
 */

/**
 * @typedef {Object} Props
 * @property {import("@raiden/library/types/Task").Task} resource
 * @property {childrenRender} children
 * @property {() => void} mutate
 * @return {import("react").FunctionComponentElement<Props>}
 *
 */

/**
 * @param {Props} props
 */
function TasksActionsView(props) {
  const { resource, children, mutate } = props;

  const disclosure = useDisclosure();

  const { isOpen, onClose } = disclosure;

  const canPerform = useCanPerform();

  const filePolicies = resource?.meta?.policies ?? [];

  return (
    <>
      {canPerform({
        policies: POLICIES_POLICY_VALUE_VIEW,
        resourcePolicies: filePolicies,
      }) && (
        <>
          {isOpen && (
            <View
              resource={resource}
              isOpen={isOpen}
              onClose={onClose}
              mutate={mutate}
            />
          )}

          {children({ disclosure })}
        </>
      )}
    </>
  );
}

TasksActionsView.propTypes = {
  children: PropTypes.func,
  resource: TaskType,
  mutate: PropTypes.func,
};

export default TasksActionsView;

export const POLICIES_POLICY_VALUE_VIEW = "view";
export const POLICIES_POLICY_VALUE_UPDATE = "update";
export const POLICIES_POLICY_VALUE_DELETE = "delete";
export const POLICIES_POLICY_VALUE_SOFT_DELETE = "soft_delete";
export const POLICIES_POLICY_VALUE_RESTORE = "restore";
export const POLICIES_POLICY_VALUE_ENABLE = "enable";
export const POLICIES_POLICY_VALUE_DISABLE = "disable";
export const POLICIES_POLICY_VALUE_DOWNLOAD = "download";
export const POLICIES_POLICY_VALUE_UPDATE_RECORDS_ACCOUNTS =
  "update_records_accounts";
export const POLICIES_POLICY_VALUE_PASSWORD_RESETS = "password_resets";
export const POLICIES_POLICY_VALUE_ACCESS_TOKENS = "access_tokens";

export const POLICIES_POLICY_VALUE_REVIEW = "review";
export const POLICIES_POLICY_VALUE_REQUEST_REVIEW = "request_review";
export const POLICIES_POLICY_VALUE_QUOTAS = "quotas";
export const POLICIES_POLICY_VALUE_PUBLISH = "publish";
export const POLICIES_POLICY_VALUE_UPDATE_ITEMS = "update_items";
export const POLICIES_POLICY_VALUE_UPDATE_DEFAULTS = "update_defaults";
export const POLICIES_POLICY_VALUE_CANCEL = "cancel";
export const POLICIES_POLICY_VALUE_RETRY = "retry";
export const POLICIES_POLICY_VALUE_CONFIRM = "confirm";
export const POLICIES_POLICY_VALUE_REFUSE = "refuse";

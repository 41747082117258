// deps
import { useRef } from "react";
import { useButton } from "@react-aria/button";
import { IconButton } from "@chakra-ui/react";
import PropTypes from "prop-types";

/**
 * @typedef {"previous" | "next"} CANCEL_ICON_BUTTON_TYPE
 */
/**
 * @typedef Props
 * @property {import("react").ReactElement} icon
 * @property {object} state
 * @property {CANCEL_ICON_BUTTON_TYPE} type
 * @property {import("react").Dispatch<import("react").SetStateAction<any>>} onChange
 */
/**
 * @returns {import("react").FunctionComponentElement<Props>}
 */
function CalendarIconButton(props) {
  const { icon, ...otherProps } = props;
  let ref = useRef(null);
  let { buttonProps } = useButton(props, ref);

  return (
    <IconButton
      {...otherProps}
      {...buttonProps}
      aria-label={`${props.type} month`}
      ref={ref}
      size="sm"
      icon={icon}
      type="button"
    />
  );
}

export default CalendarIconButton;

CalendarIconButton.propsTypes = {
  icon: PropTypes.element,
  state: PropTypes.object,
  type: PropTypes.string,
  onChange: PropTypes.func,
};

/* istanbul ignore file */

// deps
import { useMemo } from "react";
import PropTypes from "prop-types";
import NextLink from "next/link";
import { Button } from "@chakra-ui/react";

// components
import ConditionalWrapper from "@splitfire-agency/raiden-library/dist/components/ConditionalWrapper";

export default function PaginationButton(props) {
  const { children, href, mode, isActive, isDisabled, onClick, ...otherProps } =
    props;

  const wrapper = useMemo(
    function () {
      return function (children) {
        return (
          <NextLink href={href} passHref={true}>
            {children}
          </NextLink>
        );
      };
    },
    [href],
  );

  return (
    <>
      {"normal" === mode && (
        <>
          <ConditionalWrapper
            wrapper={wrapper}
            hasWrapper={Boolean(href) && !isDisabled}>
            <Button
              {...otherProps}
              as={href && !isDisabled ? "a" : undefined}
              size="sm"
              isActive={isActive}
              isDisabled={isDisabled}
              variant={"pagination"}
              colorScheme="gray"
              aria-current={isActive ? "true" : undefined}
              onClick={!isDisabled ? onClick : undefined}>
              {children}
            </Button>
          </ConditionalWrapper>
        </>
      )}

      {"dots" === mode && (
        <Button
          {...otherProps}
          colorScheme="gray"
          variant="paginationDots"
          size="sm">
          {children}
        </Button>
      )}
    </>
  );
}

PaginationButton.propTypes = {
  mode: PropTypes.oneOf(["normal", "dots"]),
  href: PropTypes.string,
  isActive: PropTypes.bool,
  isDisabled: PropTypes.bool,
  children: PropTypes.node,
  onClick: PropTypes.func,
};

PaginationButton.defaultProps = {
  mode: "normal",
};

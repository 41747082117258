// deps
import {
  Box,
  Button,
  DarkMode,
  Flex,
  Grid,
  GridItem,
  HStack,
  Icon,
  Kbd,
  Menu,
  MenuButton,
  Portal,
  Skeleton,
  Text,
  useBreakpointValue,
  useColorMode,
  useDisclosure,
} from "@chakra-ui/react";
import PropTypes from "prop-types";
import { useCallback, useEffect } from "react";
import {
  MdExpandMore,
  MdMenu,
  MdOutlineSearch,
  MdSearch,
} from "react-icons/md";
import { FormattedMessage, useIntl } from "react-intl";

// components
import EnvironmentSelect from "../../../components/EnvironmentSelectWithoutAuthorizations";
import Logo from "../../../components/Logo";
import UsersAvatar from "../../../components/Users/Avatar";

// containers
import UserMenu from "../../UserMenu";

// hooks
import useAuth from "@raiden/library/hooks/useAuth";

// contexts
import ResetColorMode from "@raiden/library/components/ResetColorMode";
import { useDefaultEnvironments } from "@raiden/library/contexts/DefaultEnvironments";
import { usePreferences } from "@raiden/library/contexts/Preferences";
import { SearchModal } from "../../SearchGlobal/Modal";
import ActionTaskViewAny from "../../Tasks/Actions/ViewAny";
import TaskSearchDrawer from "../../Tasks/Search";
import ThemeSwitch from "../ThemeSwitch";
import TasksButton from "./TasksButton";
import { useRouter } from "next/router";

export default function SignedInLayoutHeader(props) {
  const { onClickMenu } = props;

  const { user, loading } = useAuth();

  const router = useRouter();

  const { defaultEnvironments, setDefaultEnvironments } =
    useDefaultEnvironments();

  const intl = useIntl();

  const { colorMode, setColorMode } = useColorMode();

  const isMobile = useBreakpointValue({
    base: true,
    lg: false,
  });

  const { shouldRenderEnvironments } = usePreferences();

  /**
   * Gère le changement d’environnement par défaut.
   */
  const handleChangeDefaultEnvironment = useCallback(
    function (event) {
      setDefaultEnvironments(event.target.value);
    },
    [setDefaultEnvironments],
  );

  const {
    isOpen: isOpenSearch,
    onOpen: onOpenSearch,
    onClose: onCloseSearch,
  } = useDisclosure();

  // close modal when navigation occurs
  useEffect(() => {
    router.events.on("routeChangeStart", onCloseSearch);

    return () => {
      router.events.off("routeChangeStart", onCloseSearch);
    };
  }, [onCloseSearch, router]);

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (
        ((window.navigator.userAgent.includes("Mac") && event.metaKey) ||
          event.ctrlKey) &&
        (event.key === "k" || event.key === "K")
      ) {
        event.preventDefault();
        onOpenSearch();
      }
    };

    document.addEventListener("keydown", handleKeyPress);

    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, [onOpenSearch]);

  return (
    <>
      <DarkMode>
        <Flex
          pos="fixed"
          zIndex="1"
          left="0"
          right="0"
          top="0"
          h="4rem"
          borderBottomColor="gray.600"
          borderBottomWidth="0.0625rem"
          borderBottomStyle="solid"
          alignItems="center"
          px="0.625rem"
          py="0.75rem"
          bgColor="gray.700"
          color="white">
          {isMobile ? (
            <Grid templateColumns="repeat(3, 1fr)" gap="0.3125rem" w="full">
              <GridItem>
                <HStack
                  spacing="0.625rem"
                  h="full"
                  as="button"
                  onClick={onClickMenu}>
                  <Icon as={MdMenu} boxSize="1.5rem" />

                  <Text>
                    {intl.formatMessage({
                      id: "raiden.admin.containers.SignedInLayout.texts.menu",
                      defaultMessage: "Menu",
                    })}
                  </Text>
                </HStack>
              </GridItem>

              <GridItem>
                <Flex h="3rem" justifyContent="center">
                  <Logo dimensions={[130, 48]} />
                </Flex>
              </GridItem>

              <GridItem>
                <HStack
                  height="100%"
                  alignItems="center"
                  justifyContent="flex-end">
                  <Button size="md" variant="outline" onClick={onOpenSearch}>
                    <Icon as={MdOutlineSearch} />
                  </Button>

                  <TasksButton />

                  <ActionTaskViewAny>
                    <ResetColorMode>
                      <TaskSearchDrawer />
                    </ResetColorMode>
                  </ActionTaskViewAny>
                </HStack>
              </GridItem>
            </Grid>
          ) : (
            <HStack flexGrow={1}>
              <Box flexGrow={1} flexBasis="0">
                <Box h="3rem">
                  <Logo dimensions={[130, 48]} />
                </Box>
              </Box>

              <Box flexGrow={1} flexBasis="0">
                <Button
                  onClick={onOpenSearch}
                  leftIcon={<Icon as={MdSearch} />}
                  justifyContent="flex-start"
                  alignItems="center"
                  textAlign="left"
                  w="full">
                  <HStack w="full">
                    <Box flexGrow={1}>
                      <Text as="span" fontWeight="normal">
                        <FormattedMessage defaultMessage="Rechercher" />
                      </Text>
                    </Box>

                    <HStack>
                      <Kbd>
                        {window.navigator.userAgent.includes("Mac")
                          ? "cmd"
                          : "ctrl"}
                      </Kbd>

                      <Text as="span">+</Text>

                      <Kbd>k</Kbd>
                    </HStack>
                  </HStack>
                </Button>
              </Box>

              <HStack flexGrow={1} flexBasis="0" justify="flex-end">
                <ThemeSwitch
                  colorMode={colorMode}
                  setColorMode={setColorMode}
                />

                {shouldRenderEnvironments && (
                  <EnvironmentSelect
                    width="15.625rem"
                    isMultiple={true}
                    value={defaultEnvironments}
                    onChange={handleChangeDefaultEnvironment}
                  />
                )}

                <TasksButton />

                <ActionTaskViewAny>
                  <ResetColorMode>
                    <TaskSearchDrawer />
                  </ResetColorMode>
                </ActionTaskViewAny>

                {loading ? (
                  <Skeleton w="15.625rem" h="2.5rem" />
                ) : (
                  <Menu placement="bottom">
                    <MenuButton
                      paddingInlineStart="0"
                      as={Button}
                      rightIcon={<MdExpandMore />}
                      variant="ghost"
                      fontWeight="normal">
                      <UsersAvatar size="md" user={user} />
                    </MenuButton>

                    <Portal>
                      <UserMenu />
                    </Portal>
                  </Menu>
                )}
              </HStack>
            </HStack>
          )}
        </Flex>
      </DarkMode>

      <SearchModal isOpen={isOpenSearch} onClose={onCloseSearch} />
    </>
  );
}

SignedInLayoutHeader.propTypes = {
  onClickMenu: PropTypes.func,
};

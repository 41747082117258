// deps
import { HStack, Text } from "@chakra-ui/react";
import getFileSize from "@raiden/library/helpers/files/getFileSize";
import capitalize from "@splitfire-agency/raiden-library/dist/libraries/utils/capitalize";
import { useIntl } from "react-intl";

// components
import FilePath from "@raiden/library/components/File/FilePath";

// constants
import { CUSTOMERS_TYPES } from "@raiden/library/constants/customers";
import {
  FILES_CATEGORY_VALUE_OTHER,
  FILES_TYPES,
  FILES_TYPE_VALUE_DIRECTORY,
  filesCategoryMessage,
} from "@raiden/library/constants/files";
import {
  postsStateMessage,
  postsTypeMessage,
} from "@raiden/library/constants/posts";
import { resourcesVisibilityMessage } from "@raiden/library/constants/resources";
import {
  SEARCH_RESULTS_OBJECT_TYPE_CUSTOMER,
  SEARCH_RESULTS_OBJECT_TYPE_FILE,
  SEARCH_RESULTS_OBJECT_TYPE_POST,
} from "@raiden/library/constants/search/results";

// hooks
import useTranslate from "@raiden/library/hooks/useTranslate";

const textSize = "10px";
const textColor = "gray.400";

/**
 * @typedef {Object} Props
 * @property {import("@raiden/library/types/search/Result").SearchResult} searchResult
 */

/**
 * @param {Props} props
 */
export function SearchGlobalDataTags({ searchResult }) {
  const intl = useIntl();

  const translate = useTranslate();

  switch (searchResult.object_type) {
    case SEARCH_RESULTS_OBJECT_TYPE_FILE:
      return (
        <>
          <FilePath
            resource={searchResult.metadata}
            children={(filepath) => (
              <Text fontSize={textSize} color={textColor} noOfLines={1}>
                {filepath}
              </Text>
            )}
          />

          <HStack>
            {searchResult.metadata?.type === FILES_TYPE_VALUE_DIRECTORY ? (
              <Text fontSize={textSize} color={textColor}>
                {capitalize(
                  intl.formatMessage(
                    FILES_TYPES[FILES_TYPE_VALUE_DIRECTORY]?.label,
                  ),
                )}
              </Text>
            ) : (
              <Text fontSize={textSize} color={textColor}>
                {capitalize(
                  intl.formatMessage(filesCategoryMessage, {
                    category:
                      searchResult.metadata?.category ??
                      FILES_CATEGORY_VALUE_OTHER,
                  }),
                )}
              </Text>
            )}

            {searchResult.metadata?.type === FILES_TYPE_VALUE_DIRECTORY ? (
              <Text fontSize={textSize} color={textColor}>
                {intl.formatMessage(
                  {
                    defaultMessage:
                      "{count, plural, =0 {Aucun élément} one {1 élément} other {# éléments}}",
                  },
                  { count: searchResult.metadata.children_count },
                )}
              </Text>
            ) : (
              <Text fontSize={textSize} color={textColor}>
                {getFileSize({ size: searchResult.metadata?.filesize, intl })}
              </Text>
            )}
          </HStack>
        </>
      );
    case SEARCH_RESULTS_OBJECT_TYPE_CUSTOMER:
      return (
        <>
          <Text fontSize={textSize} color={textColor}>
            {`${capitalize(
              intl.formatMessage(
                CUSTOMERS_TYPES[searchResult.metadata.type]?.label,
              ),
            )}, ${searchResult.metadata?.zipcode}, ${capitalize(
              searchResult.metadata?.city,
            )}`}
          </Text>

          <Text fontSize={textSize} color={textColor}>
            {searchResult.metadata.email}
          </Text>
        </>
      );
    case SEARCH_RESULTS_OBJECT_TYPE_POST:
      return (
        <>
          <HStack>
            {searchResult.metadata?.categories?.[0] && (
              <Text fontSize={textSize} color={textColor}>
                {capitalize(
                  translate(searchResult.metadata?.categories[0]?.name),
                )}
              </Text>
            )}

            {searchResult.metadata?.type && (
              <Text fontSize={textSize} color={textColor}>
                {capitalize(
                  intl.formatMessage(postsTypeMessage, {
                    type: searchResult.metadata?.type,
                  }),
                )}
              </Text>
            )}
          </HStack>

          <HStack>
            {searchResult.metadata?.state && (
              <Text fontSize={textSize} color={textColor}>
                {capitalize(
                  intl.formatMessage(
                    postsStateMessage,

                    { state: searchResult.metadata.state },
                  ),
                )}
              </Text>
            )}

            {searchResult.metadata?.visibility && (
              <Text fontSize={textSize} color={textColor}>
                {capitalize(
                  intl.formatMessage(
                    resourcesVisibilityMessage,

                    { visibility: searchResult.metadata.visibility },
                  ),
                )}
              </Text>
            )}
          </HStack>
        </>
      );
    default:
      return <></>;
  }
}

import { forwardRef, RadioGroup } from "@chakra-ui/react";
import { useCallback } from "react";
const RadioGroupRaiden = forwardRef(
  /**
   * Chakra radios can be used inside this component.
   * This component is compatible with useForm hook.
   * @param {{
   * name: string,
   * value: any,
   * onChange: (event: {target: {name: string, value: any}}) => void,
   * isRequired?: boolean,
   * isInvalid?: boolean,
   * children: React.ReactNode,
   * }} props
   */
  function RadioGroupRaiden(props, ref) {
    const { name, onChange: _onChange, children } = props;
    const normalizedProps = { ...props };
    delete normalizedProps.isInvalid;
    delete normalizedProps.isRequired;
    const onChange = useCallback(
      (value) => {
        _onChange?.({ target: { name, value } });
      },
      [_onChange, name],
    );
    return (
      <RadioGroup ref={ref} {...normalizedProps} onChange={onChange}>
        {children}
      </RadioGroup>
    );
  },
);
export default RadioGroupRaiden;

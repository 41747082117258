// deps
import {
  Box,
  Button,
  ButtonGroup,
  Flex,
  Grid,
  IconButton,
  Text,
  useBreakpointValue,
  useColorModeValue,
} from "@chakra-ui/react";
import { usePreferences } from "@raiden/library/contexts/Preferences";
import useLocale from "@raiden/library/hooks/useLocale";
import capitalize from "@splitfire-agency/raiden-library/dist/libraries/utils/capitalize";
import { Fragment, useState } from "react";
import { MdClose } from "react-icons/md";
import { useIntl } from "react-intl";

// components
import { SearchData, useSearch } from "@raiden/library/contexts/Search";
import SearchGlobalFilters from "./Filters";
import SearchGlobalItem from "./Item";

// constants
import { SEARCH_LOCAL_STORAGE_RECENT_SEARCH } from "@raiden/library/constants/search";

/**
 * @typedef {object} Props
 * @property {React.MutableRefObject<HTMLInputElement | null>} inputRef
 * @property {() => void} onCloseSearch
 */
/**
 * @param {Props} props
 */
export function SearchGlobal({ inputRef, onCloseSearch }) {
  const isMobile = useBreakpointValue({ base: true, md: false });

  const {
    response: { data },
    form,
    onSubmit,
  } = useSearch();

  const intl = useIntl();

  const { bestEnvironment } = usePreferences();

  const { locale } = useLocale();

  // Group search results by object type
  const searchList = (data?.data ?? []).reduce((acc, item) => {
    // Use the object_type as the key, or default to "default"
    const key = item?.categories?.[0]?.name[locale] || "default";
    if (!acc[key]) {
      acc[key] = [];
    }
    acc[key].push(item);
    return acc;
  }, {});

  const [recentSearchList, setRecentSearchList] = useState(
    JSON.parse(
      window.localStorage.getItem(`${SEARCH_LOCAL_STORAGE_RECENT_SEARCH}`) ??
        "[]",
    ),
  );

  const backgroundColor = useColorModeValue("white", "gray.700");

  const borderColor = useColorModeValue("darkGray.200", "darkGray.600");

  /**
   * @param {string} recentSearch
   * @param {string[]} recentSearchList
   */
  function deleteRecentSearch(recentSearch, recentSearchList) {
    const newRecentSearchList = recentSearchList.filter(
      (search) => search !== recentSearch,
    );

    window.localStorage.setItem(
      `${SEARCH_LOCAL_STORAGE_RECENT_SEARCH}`,
      JSON.stringify(newRecentSearchList),
    );

    setRecentSearchList(newRecentSearchList);
  }

  /**
   * @param {string} recentSearch
   */
  function handleSubmit(recentSearch) {
    form.setValue("term", recentSearch, {
      shouldValidate: true,
      shouldDirty: true,
    });

    let recentSearchesList = JSON.parse(
      window.localStorage.getItem(`${SEARCH_LOCAL_STORAGE_RECENT_SEARCH}`) ??
        "[]",
    );

    // Remove the recentSearch from the list
    recentSearchesList = recentSearchesList.filter(
      /** @param {string} search */
      (search) => search !== recentSearch,
    );

    // Add the recentSearch to the beginning of the list
    recentSearchesList.unshift(recentSearch);

    window.localStorage.setItem(
      `${SEARCH_LOCAL_STORAGE_RECENT_SEARCH}`,
      JSON.stringify(recentSearchesList),
    );

    onSubmit();

    setRecentSearchList(
      JSON.parse(
        window.localStorage.getItem(`${SEARCH_LOCAL_STORAGE_RECENT_SEARCH}`) ??
          "[]",
      ),
    );
  }

  return (
    <>
      <Box
        // Sticky header with high z-index to stay above and at top of list while content is scrolled
        position="sticky"
        top="0"
        zIndex="9999"
        backgroundColor={backgroundColor}
        px="1rem"
        py=".5rem">
        <SearchGlobalFilters
          inputRef={inputRef}
          onCloseSearch={onCloseSearch}
        />
      </Box>

      <Box px="1rem" pb=".5rem">
        <SearchData
          renderNoResponse={() => (
            <>
              <Flex marginY="1rem" justifyContent="center" alignItems="center">
                <Text fontWeight={700}>
                  {intl.formatMessage({
                    defaultMessage: "Recherches récentes",
                  })}
                </Text>

                {recentSearchList.length > 0 && (
                  <Text
                    marginLeft="1rem"
                    fontSize="12px"
                    color="gray.400"
                    textDecoration="underline"
                    cursor="pointer"
                    onClick={() => {
                      window.localStorage.removeItem(
                        `${SEARCH_LOCAL_STORAGE_RECENT_SEARCH}`,
                      );
                      setRecentSearchList([]);
                    }}
                    _hover={{ color: "red.500" }}>
                    {capitalize(
                      intl.formatMessage({
                        defaultMessage: "supprimer l'historique",
                      }),
                    )}
                  </Text>
                )}
              </Flex>

              {(recentSearchList ?? []).length > 0 ? (
                <Grid
                  templateColumns={
                    isMobile ? "repeat(1, 1fr)" : "repeat(2, 1fr)"
                  }
                  gap={isMobile ? ".5rem" : "1rem"}>
                  {recentSearchList.map((recentSearch, index) => (
                    <Box
                      key={index}
                      width="250px"
                      marginLeft={
                        isMobile || index % 2 === 0 ? "auto" : "initial"
                      }
                      marginRight={
                        isMobile || index % 2 !== 0 ? "auto" : "initial"
                      }>
                      <ButtonGroup width="full" variant="outline" isAttached>
                        <Button
                          width="full"
                          borderRight="none"
                          onClick={() => {
                            handleSubmit(recentSearch);
                          }}>
                          <Text align="left" width="full" noOfLines={1}>
                            {recentSearch}
                          </Text>
                        </Button>

                        <IconButton
                          borderLeft="none"
                          aria-label="Supprimer"
                          icon={<MdClose />}
                          onClick={() =>
                            deleteRecentSearch(recentSearch, recentSearchList)
                          }
                        />
                      </ButtonGroup>
                    </Box>
                  ))}
                </Grid>
              ) : (
                <Text align="center">
                  {capitalize(
                    intl.formatMessage({
                      defaultMessage:
                        "aucune recherche récente n'a été trouvée",
                    }),
                  )}
                </Text>
              )}
            </>
          )}
          renderData={() => (
            <>
              {Object.keys(searchList).map((key, index) => {
                const searchByObjectTypeList = searchList[key];

                if ((searchByObjectTypeList ?? []).length > 0) {
                  return (
                    <Fragment key={index}>
                      <Box
                        marginBottom=".5rem"
                        marginTop={index > 0 ? "1rem" : ".5rem"}
                        paddingTop={index > 0 ? ".5rem" : "0"}
                        borderTop={index > 0 ? "1px solid" : "none"}
                        borderColor={borderColor}>
                        {bestEnvironment ? (
                          <Text fontWeight={600}>{capitalize(key)}</Text>
                        ) : (
                          <>
                            <Text fontWeight={600}>{capitalize(key)}</Text>

                            <Text fontSize="12px" fontWeight={400}>
                              {capitalize(
                                intl.formatMessage(
                                  {
                                    defaultMessage: 'environnement "{name}"',
                                  },
                                  {
                                    name: searchByObjectTypeList[0].environment
                                      ?.name,
                                  },
                                ),
                              )}
                            </Text>
                          </>
                        )}
                      </Box>

                      <Flex wrap="wrap" gap="1rem">
                        {searchByObjectTypeList.map((search) => (
                          <SearchGlobalItem
                            key={search.id}
                            searchResult={search}
                          />
                        ))}
                      </Flex>
                    </Fragment>
                  );
                }
              })}
            </>
          )}
        />
      </Box>
    </>
  );
}

import {
  Box,
  Button,
  ButtonGroup,
  Code,
  Flex,
  Text,
  VStack,
} from "@chakra-ui/react";
import FormControlRHF from "@raiden/library/components/ReactHookForm/FormController";
import ObserverRHF from "@raiden/library/components/ReactHookForm/ObserverRHF";
import SplashImage from "@raiden/library/components/Splash/Image";
import {
  POLICIES_POLICY_VALUE_DELETE,
  POLICIES_POLICY_VALUE_SOFT_DELETE,
} from "@raiden/library/constants/policies";
import { resourceIsDeleted } from "@raiden/library/helpers/resources";
import useCanPerform from "@raiden/library/hooks/useCanPerform";
import { FileType } from "@raiden/library/types/File";
import { useFormContext, useWatch } from "react-hook-form";
import { useIntl } from "react-intl";
import ApiErrorMessage from "@raiden/library/components/ReactHookForm/ApiErrorMessage";
import ButtonAction from "../../../components/ButtonAction";

/**
 * @typedef {Object} Props
 * @property {import("@raiden/library/types/File").File} file
 * @property {boolean} isLoading
 * @property {() => void} onClose

/** 
 * @param {Props} props
 * @return {import("react").FunctionComponentElement<Props>}
 */
export default function Form({ file, isLoading, onClose }) {
  const intl = useIntl();

  const { control, setValue } = useFormContext();

  const canPerform = useCanPerform();

  const filePolicies = file?.meta?.policies ?? [];

  const watchDeletionMethod = useWatch({
    name: "deletionMethod",
  });

  function onButtonActionChange(value) {
    setValue("deletionMethod", value, { shouldDirty: true });
  }

  function validateFilename(name) {
    return name === file?.filename
      ? true
      : intl.formatMessage({
          defaultMessage: "Le nom du fichier ne correspond pas",
        });
  }

  return (
    <VStack spacing="1rem" alignItems="stretch">
      <ApiErrorMessage />

      <Box maxW="18.75rem" mx="auto">
        <SplashImage image="delete" />
      </Box>

      <Box>
        {intl.formatMessage(
          {
            defaultMessage:
              "{type, select, file { Vous êtes sur le point de supprimer le fichier « {name} ». } other { Vous êtes sur le point de supprimer le dossier « {name} ». } }",
          },
          {
            name: file?.filename,
            type: file?.type,
          },
        )}
      </Box>

      <ObserverRHF
        names={["deletionMethod"]}
        render={({ values: [deletionMethod] }) => (
          <>
            {deletionMethod === "hard_delete" && (
              <Box>
                <Text mb=".5rem">
                  {intl.formatMessage(
                    {
                      defaultMessage:
                        "{type, select, file { Veuillez réécrire le nom du fichier « <code> {name} </code>» pour confirmer la suppression définitive. } other { Veuillez réécrire le nom du dossier «  <code> {name} </code> » pour confirmer la suppression définitive. } }",
                    },
                    {
                      name: file?.filename,
                      type: file?.type,
                      code(chunks) {
                        return <Code key="code">{chunks}</Code>;
                      },
                    },
                  )}
                </Text>

                <FormControlRHF
                  name="filename"
                  control={control}
                  placeholder={file?.filename}
                  rules={{
                    required: true,
                    validate: (value) => validateFilename(value),
                  }}
                />
              </Box>
            )}
          </>
        )}
      />

      <Flex justifyContent="flex-end">
        <ButtonGroup>
          <Button variant="outline" isDisabled={isLoading} onClick={onClose}>
            {intl.formatMessage({
              defaultMessage: "Annuler",
            })}
          </Button>

          <ButtonAction
            onChange={(e) => onButtonActionChange(e.target.value)}
            value={watchDeletionMethod}
            isLoading={isLoading}
            actionList={[
              {
                label: intl.formatMessage({
                  defaultMessage: "Déplacer dans la corbeille",
                }),
                colorScheme: "red",
                type: "submit",
                value: "",
                isEnabled:
                  canPerform({
                    policies: POLICIES_POLICY_VALUE_SOFT_DELETE,
                    resourcePolicies: filePolicies,
                  }) && !resourceIsDeleted({ resource: file }),
              },
              {
                label: intl.formatMessage({
                  defaultMessage: "Supprimer définitivement",
                }),
                colorScheme: "red",
                type: "submit",
                value: "hard_delete",
                isEnabled: canPerform({
                  policies: POLICIES_POLICY_VALUE_DELETE,
                  resourcePolicies: filePolicies,
                }),
              },
            ]}
          />
        </ButtonGroup>
      </Flex>
    </VStack>
  );
}

Form.propTypes = {
  file: FileType.isRequired,
};

// deps
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useBreakpointValue,
} from "@chakra-ui/react";
import { useDefaultEnvironments } from "@raiden/library/contexts/DefaultEnvironments";
import generateApiUrl from "@raiden/library/libraries/utils/generateApiUrl";
import { useRef } from "react";
import { FormattedMessage } from "react-intl";

// components
import { SearchGlobal } from "../Search";
import { SearchProvider } from "@raiden/library/contexts/Search";

/**
 * @return {string | null}
 */
export function getGlobalSearchEndpointUrl({ fields }) {
  if (fields.term.length < 2) {
    return null;
  }
  const url = generateApiUrl({
    id: "@api.search",
    query: {
      ...fields,
      // Remove the "other" object type
      object_type: fields.object_type.filter(
        (objectType) => objectType !== "other",
      ),
      fields: [
        "search.result.environment",
        "search.result.categories",
        "search.result.translations",
        "search.result.picture",
        "search.result.cover",
      ],
    },
  });
  return url;
}

export function SearchModal({ isOpen, onClose }) {
  const isMobile = useBreakpointValue({
    base: true,
    lg: false,
  });

  const { defaultEnvironments } = useDefaultEnvironments();

  const inputRef = useRef(/** @type {HTMLInputElement | null} */ (null));

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      isCentered={false}
      scrollBehavior="inside"
      size={isMobile ? "full" : "4xl"}
      initialFocusRef={inputRef}>
      <ModalOverlay />

      <ModalContent overflow="hidden">
        {isMobile && (
          <>
            <ModalCloseButton />

            <ModalHeader>
              <FormattedMessage defaultMessage="Recherche" />
            </ModalHeader>
          </>
        )}

        <ModalBody padding="0" marginBottom=".5rem">
          <SearchProvider
            searchId="global-search"
            routerSyncUrl={false}
            useSessionStorageHydration={false}
            preventSubmitIfNotValid={true}
            defaultValues={{
              term: "",
              environment_id:
                defaultEnvironments.length > 0
                  ? defaultEnvironments.map((environmentId) =>
                      String(environmentId),
                    )
                  : [],
              object_type: [],
              category_id: [],
            }}
            endpointUrl={getGlobalSearchEndpointUrl}>
            <SearchGlobal inputRef={inputRef} onCloseSearch={onClose} />
          </SearchProvider>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

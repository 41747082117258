import { useIntl } from "react-intl";
import {
  Button,
  ButtonGroup,
  Grid,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useBreakpointValue,
} from "@chakra-ui/react";
import { FILES_TYPE_VALUE_FILE } from "@raiden/library/constants/files";
import { ShowData, useShow } from "@raiden/library/contexts/Show";
import PropTypes from "prop-types";
import FileDetailPreview from "../Preview";
import InfoTable from "./InfoTable";
import FilesActionsDownload from "../Actions/Download";
import generateApiUrl from "@raiden/library/libraries/utils/generateApiUrl";
import ButtonDownloadFile from "../../../components/ButtonDownloadFile";
import FilesActionsDelete from "../Actions/Delete";
import FilesActionsRestore from "../Actions/Restore";
import FilesActionsUpdate from "../Actions/Update";

/**
 * @typedef Props
 * @property {boolean} isOpen
 * @property {() => void} onClose
 * @property {() => void} mutateSearch
 * @property {boolean} isTrash
 * @param {import("react").PropsWithChildren<Props>} props
 * @return {import("react").FunctionComponentElement<Props>}
 */
function Data({ isOpen, onClose, mutateSearch, isTrash }) {
  const intl = useIntl();

  const { data: dataResponse, mutate } = useShow();

  const responsive = useBreakpointValue({
    base: true,
    md: false,
  });

  function mutateAll() {
    mutateSearch();
    mutate();
  }

  const resource = dataResponse?.data ?? null;

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size={resource?.type === FILES_TYPE_VALUE_FILE ? "6xl" : "lg"}>
      <ModalOverlay />

      <ModalContent>
        <ModalCloseButton />

        <ModalHeader>
          {resource?.filename && resource?.id
            ? intl.formatMessage(
                {
                  defaultMessage:
                    "Détail du {resourceType, select, file {fichier} directory {dossier} other {}} {id} {name}",
                },
                {
                  resourceType: resource?.type,
                  id: `#${resource?.id}`,
                  name: resource?.filename,
                },
              )
            : intl.formatMessage({ defaultMessage: "Detail du fichier" })}
        </ModalHeader>

        <ShowData
          data={function () {
            return (
              <>
                <ModalBody>
                  {resource?.type === FILES_TYPE_VALUE_FILE ? (
                    <Grid
                      gridTemplateColumns={responsive ? "1fr" : "1fr 1fr"}
                      gap="1rem">
                      <InfoTable resource={resource} />

                      <FileDetailPreview resource={resource} />
                    </Grid>
                  ) : (
                    <InfoTable resource={resource} />
                  )}
                </ModalBody>

                <ModalFooter>
                  <ButtonGroup size="sm">
                    <FilesActionsUpdate
                      onSuccess={mutateAll}
                      isInModal={true}
                      resource={resource}
                      key="actionUpdate">
                      {function ({ disclosure }) {
                        return (
                          <Button onClick={disclosure.onOpen}>
                            {intl.formatMessage({
                              defaultMessage: "Modifier",
                            })}
                          </Button>
                        );
                      }}
                    </FilesActionsUpdate>

                    <FilesActionsRestore
                      isInModal={true}
                      resource={resource}
                      onSuccess={mutateAll}
                      key="actionRestore">
                      {function ({ disclosure }) {
                        return (
                          <Button onClick={disclosure.onOpen}>
                            {intl.formatMessage({
                              defaultMessage: "Restaurer",
                            })}
                          </Button>
                        );
                      }}
                    </FilesActionsRestore>

                    <FilesActionsDelete
                      isInModal={true}
                      resource={resource}
                      // Mutate search only here since it is a hard delete so the view would be a 404 if
                      onSuccess={mutateSearch}
                      key="actionDelete">
                      {function ({ disclosure }) {
                        return (
                          <Button onClick={disclosure.onOpen} colorScheme="red">
                            {isTrash
                              ? intl.formatMessage({
                                  defaultMessage: "Supprimer",
                                })
                              : intl.formatMessage({
                                  defaultMessage: "Archiver",
                                })}
                          </Button>
                        );
                      }}
                    </FilesActionsDelete>

                    <FilesActionsDownload resource={resource}>
                      <ButtonDownloadFile
                        label={intl.formatMessage({
                          defaultMessage: "Télécharger le fichier",
                        })}
                        size="sm"
                        colorScheme="darkGray"
                        variant="outline"
                        filename={resource?.filename}
                        endpointUrl={generateApiUrl({
                          id: "@api.files.download",
                          parameters: {
                            fileId: resource?.id,
                          },
                        })}
                        aria-label={intl.formatMessage({
                          defaultMessage: "Télécharger le fichier",
                        })}
                        outputMode="file"
                      />
                    </FilesActionsDownload>

                    <Button variant="outline" onClick={onClose}>
                      {intl.formatMessage({
                        defaultMessage: "Fermer",
                      })}
                    </Button>
                  </ButtonGroup>
                </ModalFooter>
              </>
            );
          }}
        />
      </ModalContent>
    </Modal>
  );
}

export default Data;

Data.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  mutateSearch: PropTypes.func,
  isTrash: PropTypes.bool,
};

import { Button, Checkbox, HStack, Icon, VStack } from "@chakra-ui/react";
import ApiErrorMessage from "@raiden/library/components/ReactHookForm/ApiErrorMessage";
import FormControlRHF from "@raiden/library/components/ReactHookForm/FormController";
import { usePreferences } from "@raiden/library/contexts/Preferences";
import useAuth from "@raiden/library/hooks/useAuth";
import { useEffect, useState } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { MdAdd, MdRemove } from "react-icons/md";
import { FormattedMessage, useIntl } from "react-intl";
import EnvironmentSelect from "../../../../components/EnvironmentSelect";
import FormRestriction from "../../../../components/Form/Restriction";
import FileVisibility from "../Visibility";

import { DirectoriesAutocomplete } from "@raiden/library/components/Form/DirectoriesAutcomplete";
import ObserverRHF from "@raiden/library/components/ReactHookForm/ObserverRHF";

/**
 * @typedef {Object} Props
 * @property {"create" | "edit"} context
 */

/**
 * @param {Object} Props
 * @return {import("react").FunctionComponentElement<Props>}
 */
export default function Form({ context, defaultFile }) {
  const intl = useIntl();

  /** @type {import("react-hook-form").UseFormReturn<import("@raiden/library/types/File").FileFormValues>} */
  const { control, setValue } = useFormContext();

  const { shouldRenderEnvironments, environments } = usePreferences();

  const [isMoreOptions, setIsMoreOptions] = useState(false);

  const { root: isRoot } = useAuth();

  const watchVisibility = useWatch({
    name: "data.visibility",
  });

  useEffect(() => {
    if (context === "edit") {
      setValue(
        "meta.recursive_visibility",
        watchVisibility !== defaultFile.visibility,
        { shouldDirty: true },
      );
    }
  }, [context, defaultFile?.visibility, setValue, watchVisibility]);

  function onAdvancedClick() {
    setIsMoreOptions(!isMoreOptions);
  }

  return (
    <>
      <ApiErrorMessage />

      <VStack spacing="1rem" align="stretch">
        <FormControlRHF
          control={control}
          rules={{ required: true }}
          name="data.filename"
          label={intl.formatMessage({
            defaultMessage: "Nom du dossier",
          })}
        />

        {context !== "edit" && shouldRenderEnvironments && (
          <FormControlRHF
            control={control}
            name="data.environment_id"
            rules={{ required: true }}
            label={intl.formatMessage({
              defaultMessage: "Environnement",
            })}
            renderWithFormControl={(fields) => (
              <EnvironmentSelect
                {...fields}
                environments={environments}
                placeholder={intl.formatMessage({
                  defaultMessage: "Choisir un environnement...",
                })}
              />
            )}
          />
        )}

        <ObserverRHF
          names={["data.environment_id"]}
          render={({ values: [environmentId] }) => (
            <>
              <FormControlRHF
                control={control}
                name="data.parent_id"
                label={intl.formatMessage({
                  defaultMessage: "Choisir un dossier parent",
                })}
                helperText={intl.formatMessage({
                  defaultMessage:
                    "Par défaut, si aucun dossier n'est sélectionné, le fichier ou dossier sera créé dans le dossier racine",
                })}
                renderWithFormControl={(field) => (
                  <DirectoriesAutocomplete
                    environmentId={[environmentId]}
                    {...field}
                  />
                )}
              />
            </>
          )}
        />

        <FormControlRHF
          control={control}
          name="data.visibility"
          label={intl.formatMessage({
            defaultMessage: "Visibilité",
          })}
          renderWithFormControl={(field) => (
            <FileVisibility variant="column" {...field} withIcon={true} />
          )}
        />

        {context === "edit" && watchVisibility !== defaultFile?.visibility && (
          <FormControlRHF
            name="meta.recursive_visibility"
            render={(fields) => (
              <Checkbox isChecked={Boolean(fields.value)} {...fields}>
                <FormattedMessage defaultMessage="Mettre à jour la visibilité pour tous les fichiers et les sous-dossiers" />
              </Checkbox>
            )}
          />
        )}

        {isRoot && (
          <>
            <HStack spacing="1rem" align="stretch">
              <Button
                size="sm"
                onClick={onAdvancedClick}
                marginLeft="auto"
                variant="outline"
                leftIcon={<Icon as={isMoreOptions ? MdRemove : MdAdd} />}
                colorScheme="brandPrimary">
                {intl.formatMessage(
                  {
                    defaultMessage:
                      "{advanced, select, true {Moins d'options} other {Plus d'options}}",
                  },
                  {
                    advanced: isMoreOptions,
                  },
                )}
              </Button>
            </HStack>

            {isMoreOptions && (
              <>
                <FormControlRHF
                  control={control}
                  name="data.is_restricted"
                  label={intl.formatMessage({
                    defaultMessage: "Restriction",
                  })}
                  renderWithFormControl={(field) => (
                    <FormRestriction
                      variant="column"
                      withIcon={true}
                      subject={intl.formatMessage({
                        defaultMessage: "le dossier",
                      })}
                      {...field}
                    />
                  )}
                />
              </>
            )}
          </>
        )}
      </VStack>
    </>
  );
}

import { forwardRef, useCallback } from "react";
import { FILES_TYPE_VALUE_DIRECTORY } from "../../../constants/files";
import generateApiUrl from "../../../libraries/utils/generateApiUrl";
import Autocomplete from "../Autocomplete";

export const DirectoriesAutocomplete = forwardRef(
  /**
   * @typedef {Object} Props
   * @property {number[]} [environmentId]
   * @property {object} [queryParams]
   */
  /**
   * @param {Props & import("../Autocomplete").AutocompleteCommonProps<string>} props
   */
  function DirectoriesAutocomplete(
    { environmentId = [], queryParams = {}, ...otherProps },
    ref,
  ) {
    const resolveItemValue = useCallback(({ item }) => String(item?.id), []);

    const resolveItemName = useCallback(({ item }) => item?.filename, []);

    const generateUrl = useCallback(
      ({ page, fields }) =>
        generateApiUrl({
          id: "@api.files.viewAny",
          query: {
            page,
            per_page: 100,
            environment_id: environmentId,
            type: [FILES_TYPE_VALUE_DIRECTORY],
            ...fields,
            ...queryParams,
          },
        }),
      [environmentId, queryParams],
    );

    const generateHydrationUrl = useCallback(
      ({ valuesToHydrate }) =>
        generateApiUrl({
          id: "@api.files.viewAny",
          query: {
            only_id: valuesToHydrate,
          },
        }),
      [],
    );

    return (
      <Autocomplete
        ref={ref}
        generateUrl={generateUrl}
        generateHydrationUrl={generateHydrationUrl}
        resolveItemValue={resolveItemValue}
        resolveItemName={resolveItemName}
        isMultiple={false}
        {...otherProps}
      />
    );
  },
);

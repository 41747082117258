// deps
import PropTypes from "prop-types";
import { Box, Radio as ChakraRadio, useColorModeValue } from "@chakra-ui/react";

/**
 * @param {import("@chakra-ui/react").RadioProps & {
 * panel?: import("react").ReactNode,
 * isChecked?: boolean,
 * }} props
 */
export default function Radio({ panel, isChecked, ...otherProps }) {
  const borderColor = useColorModeValue("gray.200", "whiteAlpha.200");

  return (
    <Box
      borderColor={borderColor}
      borderWidth="0.0625rem"
      borderStyle="solid"
      height="full">
      <ChakraRadio
        p="0.5rem"
        w="full"
        h="full"
        isChecked={isChecked}
        {...otherProps}
      />

      {isChecked && panel && <Box mt="1rem">{panel}</Box>}
    </Box>
  );
}

Radio.propTypes = {
  isChecked: PropTypes.bool,
  panel: PropTypes.node,
  children: PropTypes.node,
};

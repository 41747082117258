// containers
import { Box, HStack, Image, Text, useColorModeValue } from "@chakra-ui/react";
import { useIntl } from "react-intl";
import PropTypes from "prop-types";
import Splash from "@raiden/library/components/Splash";
import getTypeFromBlobMimeType from "@raiden/library/helpers/files/getTypeFromBlobMimeType";
import canPreviewFromBlobMimeType from "@raiden/library/helpers/files/canPreviewFromBlobMimeType";

/**
 * @typedef Props
 * @property {Object} blob
 * @return {import("react").FunctionComponentElement<Props>}
 */
export default function FilePreviewData({ blob }) {
  const borderColor = useColorModeValue("gray.200", "gray.600");

  const url = URL.createObjectURL(blob);

  const mimeTypeInfo = getTypeFromBlobMimeType({ blob });

  const canPreview = canPreviewFromBlobMimeType({ blob });

  const intl = useIntl();

  return (
    <Box p="1rem" border="1px" borderColor={borderColor}>
      <HStack mb="1rem">
        <Text flexGrow={1} fontSize="1.125rem" fontWeight="bold">
          {intl.formatMessage({
            defaultMessage: "Prévisualisation",
          })}
        </Text>
      </HStack>

      <Box height="100%" width="100%">
        {canPreview ? (
          <>
            {mimeTypeInfo === "image" && (
              <Box>
                <Image
                  src={url}
                  alt={intl.formatMessage({
                    defaultMessage: "Détail de l'image",
                  })}
                />
              </Box>
            )}

            {mimeTypeInfo === "video" && (
              <video controls>
                <source src={url} type={blob?.type} />
              </video>
            )}

            {mimeTypeInfo === "audio" && (
              <audio controls>
                <source src={url} type={blob?.type} />
              </audio>
            )}

            {mimeTypeInfo === "document" && (
              <object data={url} type={blob?.type} width="100%" height="95%">
                <Splash
                  image="empty"
                  title={intl.formatMessage({
                    defaultMessage: "Une erreur est survenue",
                  })}
                  description={intl.formatMessage({
                    defaultMessage: "Le document ne peut pas être affiché",
                  })}
                />
              </object>
            )}
          </>
        ) : (
          <div>
            <Splash
              image="reject"
              title={intl.formatMessage({
                defaultMessage: "Fichier non supporté",
              })}
              description={intl.formatMessage({
                defaultMessage: "Le type de fichier n'est pas supporté",
              })}
            />
          </div>
        )}
      </Box>
    </Box>
  );
}

FilePreviewData.propTypes = {
  blob: PropTypes.object,
};

import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";

import generateApiUrl from "@raiden/library/libraries/utils/generateApiUrl";

import { useApiError } from "@raiden/library/components/ReactHookForm/ApiError";
import useRequest from "@raiden/library/hooks/useRequest";
import { FileType } from "@raiden/library/types/File";
import { FormProvider, useForm } from "react-hook-form";
import Form from "./Form";
import { useTaskDrawer } from "../../../hooks/useTaskDrawer";

/**
 * @typedef Props
 * @property {import("@raiden/library/types/File").File} file
 * @property {boolean} isOpen
 * @property {() => void} onClose
 * @property {boolean} isInModal
 * @property {() => void} onSuccess
 */
/**
 * @param {Props} props
 * @return {import("react").FunctionComponentElement<Props>}
 */
export default function FileDelete(props) {
  const { file, isOpen, onClose, onSuccess, isInModal } = props;

  const intl = useIntl();

  const { request, isLoading, toastSuccess } = useRequest();

  const { mutateNumberOfStartedTasks } = useTaskDrawer();

  const { resolveApiError, resetApiErrors } = useApiError();

  const form = useForm({
    defaultValues: {
      deletionMethod: "",
      filename: "",
    },
  });

  const { handleSubmit } = form;

  function onSubmit(fields) {
    request(
      generateApiUrl({
        id: "@api.files.delete",
        parameters: {
          fileId: [file?.id],
        },
        query: {
          hard_delete:
            "hard_delete" === fields?.deletionMethod ? "1" : undefined,
        },
      }),
      {
        method: "DELETE",
      },
      {
        getResponseStatus: true,
      },
    )
      .then(({ status }) => {
        resetApiErrors();

        if (status === 202) {
          mutateNumberOfStartedTasks();
        }
        toastSuccess({
          title: intl.formatMessage(
            {
              defaultMessage:
                "{isTaskAssociated, select, true {Une tâche de suppression est en cours, vous pouvez consulter son évolution dans l'onglet tâches.} other { Le {type, select, file {fichier} other {dossier}} a bien été supprimé ! }}",
            },
            {
              isTaskAssociated: status === 202,
              type: file?.type,
            },
          ),
        });

        onSuccess();
      })
      .catch((response) => {
        resolveApiError({ response, form });
      });
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      scrollBehavior="inside"
      blockScrollOnMount={!isInModal}
      size="lg">
      <ModalOverlay />

      <ModalContent>
        <ModalHeader>
          {intl.formatMessage({
            defaultMessage: "Confirmer la suppression",
          })}
        </ModalHeader>

        <ModalCloseButton isDisabled={isLoading} />

        <FormProvider {...form}>
          <ModalBody>
            <form
              onSubmit={handleSubmit(onSubmit)}
              autoComplete="off"
              id="deleteFile"
              noValidate>
              <Form file={file} isLoading={isLoading} onClose={onClose} />
            </form>
          </ModalBody>
        </FormProvider>
      </ModalContent>
    </Modal>
  );
}

FileDelete.propTypes = {
  file: FileType.isRequired,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onSuccess: PropTypes.func,
  isInModal: PropTypes.bool,
};

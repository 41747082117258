import Data from "./Data";
import PropTypes from "prop-types";
import generateApiUrl from "@raiden/library/libraries/utils/generateApiUrl";
import { ShowProvider } from "@raiden/library/contexts/Show";
import { TaskType } from "@raiden/library/types/Task";

function endpointUrl(resource) {
  return generateApiUrl({
    id: "@api.tasks.view",
    parameters: {
      taskId: resource?.id,
    },
    query: {
      fields: ["task.policies", "task.object", "task.issuer"],
    },
  });
}

/**
 * @typedef Props
 * @property {import("@raiden/library/types/Task").Task} resource,
 * @property {boolean} isOpen
 * @property {() => void} onClose
 * @property {() => void} mutate
 */

/**
 * @param {import("react").PropsWithChildren<Props>} props
 * @return {import("react").FunctionComponentElement<Props>}
 */
export default function View({ resource, isOpen, onClose, mutate }) {
  return (
    <ShowProvider endpointUrl={endpointUrl(resource)}>
      <Data isOpen={isOpen} onClose={onClose} mutateSearch={mutate} />
    </ShowProvider>
  );
}

View.propTypes = {
  resource: TaskType,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  mutate: PropTypes.func,
};

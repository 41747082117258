import {
  FILES_SIZE_LIST,
  FILES_SIZE_SHORTENED_MESSAGE,
} from "../../../constants/files";

export default function getFileSize({ size, intl }) {
  const i = size === 0 ? 0 : Math.floor(Math.log(size) / Math.log(1024));
  const matchedFileUnit = FILES_SIZE_LIST[i];

  return intl.formatMessage(
    { defaultMessage: "{size} {unit}" },
    {
      size: Number((size / Math.pow(1024, i)).toFixed(2)),
      unit: intl.formatMessage(FILES_SIZE_SHORTENED_MESSAGE, {
        size: matchedFileUnit?.id,
      }),
    },
  );
}

// deps
import { useEffect, useRef } from "react";
import {
  Button,
  ButtonGroup,
  Icon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
} from "@chakra-ui/react";
import { useIntl } from "react-intl";
import { IoCheckmarkOutline, IoChevronDownOutline } from "react-icons/io5";

/**
 * @typedef {object} ActionListValues
 * @property {any} label
 * @property {any} value
 * @property {() => void} [onClick]
 * @property {"button" | "submit" | "reset" | undefined} [type]
 * @property {string} [form]
 * @property {string} [colorScheme]
 * @property {boolean | undefined} [isEnabled]
 */

/**
 * @typedef {object} EventValues
 * @property {object} target
 * @property {string} target.name
 * @property {any} target.value
 */

/**
 * @typedef Props
 * @property {string} [name]
 * @property {string} [value]
 * @property {string} [size]
 * @property {(EventValues) => void} [onChange]
 * @property {ActionListValues[]} actionList
 * @property {object} [actions]
 * @property {boolean} [isDisabled]
 * @property {boolean} [isLoading]
 * @property {boolean} [shouldAutoSelect]
 */

/**
 * @param {Props} props
 */
export default function ButtonAction(props) {
  const {
    name,
    value,
    size,
    isDisabled = false,
    isLoading,
    shouldAutoSelect = false,
    onChange,
  } = props;

  const intl = useIntl();

  const actionList = props.actionList.filter(function (action) {
    return action.isEnabled || action?.isEnabled === undefined;
  });

  const currentAction = actionList.find(function (action) {
    return action.value === value;
  });

  const hasMoreThanOneAction = actionList.length > 1;
  const hasMoreThanZeroAction = actionList.length > 0;

  const hydrated = useRef(false);

  // Permet d’hydrater le champs s’il n’y a qu’une seule valeur.
  useEffect(
    function () {
      if (
        shouldAutoSelect &&
        !hydrated.current &&
        actionList.length === 1 &&
        !currentAction
      ) {
        onChange?.({
          target: {
            name,
            value: actionList[0].value,
          },
        });

        hydrated.current = true;
      }
    },
    [name, onChange, shouldAutoSelect, currentAction, actionList],
  );

  const $currentButton = (
    <>
      {currentAction ? (
        <Button
          size={size}
          isDisabled={isDisabled || !currentAction}
          isLoading={isLoading}
          {...(currentAction?.onClick && {
            onClick: currentAction?.onClick,
          })}
          value={currentAction?.value}
          colorScheme={currentAction?.colorScheme ?? "green"}
          form={currentAction?.form}
          type={currentAction?.type}>
          {currentAction?.label}
        </Button>
      ) : (
        <Button
          isDisabled={isDisabled || !hasMoreThanOneAction || !currentAction}
          isLoading={isLoading}>
          {intl.formatMessage(
            {
              id: "raiden.admin.components.ButtonAction.texts.placeholder",
              defaultMessage:
                "{hasActions, select, true {Sélectionner une action…} other {Aucune action disponible}}",
            },
            {
              hasActions: hasMoreThanZeroAction,
            },
          )}
        </Button>
      )}
    </>
  );

  return (
    <>
      {hasMoreThanOneAction || !currentAction ? (
        <Menu placement="bottom-end">
          <ButtonGroup isAttached={true} size={size}>
            {$currentButton}

            <MenuButton
              as={Button}
              isDisabled={isDisabled || isLoading}
              ml="0.0625rem"
              colorScheme={currentAction?.colorScheme ?? "green"}>
              <Icon as={IoChevronDownOutline} />
            </MenuButton>
          </ButtonGroup>

          <MenuList right={0}>
            {actionList.map(function (action, actionIndex) {
              return (
                <MenuItem
                  key={actionIndex}
                  onClick={function () {
                    onChange?.({
                      target: {
                        name,
                        value: action.value,
                      },
                    });
                  }}>
                  {currentAction === action && (
                    <Icon as={IoCheckmarkOutline} mr=".5rem" />
                  )}

                  <Text ml={currentAction !== action ? "1.5rem" : 0}>
                    {action.label}
                  </Text>
                </MenuItem>
              );
            })}
          </MenuList>
        </Menu>
      ) : (
        $currentButton
      )}
    </>
  );
}

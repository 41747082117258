// libraries
import encodeFormData from "@splitfire-agency/raiden-library/dist/libraries/utils/encodeFormData";

export default function filesSerializeCreateOrUpdate({ fields }) {
  return encodeFormData({
    data: {
      environment_id: fields?.data?.environment_id
        ? fields.data.environment_id
        : undefined,

      parent_id: fields?.data?.parent_id ? fields.data.parent_id : "",

      type: fields?.data?.type ? fields.data.type : undefined,

      filename: fields?.data?.filename ? fields.data.filename : undefined,

      visibility: fields?.data?.visibility ? fields.data.visibility : undefined,

      is_restricted: fields?.data?.is_restricted
        ? fields.data.is_restricted
        : undefined,
    },
    file: fields?.file instanceof File ? fields.file : undefined,
    meta: {
      recursive_visibility: fields?.meta?.recursive_visibility
        ? fields.meta.recursive_visibility
        : undefined,
    },
  });
}

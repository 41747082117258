//deps
import { Button, Icon, IconButton } from "@chakra-ui/react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";

// helpers
import { apiGetErrorDetail } from "@raiden/library/helpers/api";
import useRequest from "@raiden/library/hooks/useRequest";
import downloadBlob from "@raiden/library/libraries/utils/downloadBlob";
import { MdOutlineDownload } from "react-icons/md";

/**
 * @typedef {Object} Props
 * @property {string} endpointUrl
 * @property {string} [label]
 * @property {string} [filename = "export.csv"]
 * @property {string} [outputMode = "csv"]
 * @return {import ("react").FunctionComponentElement<Props>}
 * @param {Props & import("@chakra-ui/react").IconButtonProps} props
 */

export default function ButtonDownloadFile(props) {
  const {
    endpointUrl,
    label,
    filename = "export.csv",
    outputMode = "csv",
    ...otherProps
  } = props;

  const intl = useIntl();

  const { request, isLoading, toastSuccess } = useRequest();

  /**
   * Gère la soumission du formulaire.
   */
  function handleClick() {
    request(
      endpointUrl,
      {
        method: "GET",
      },
      {
        outputMode,
      },
    )
      .then(function (response) {
        if (response?.data?.contentType === "blob") {
          downloadBlob({
            blob: response?.data?.blob,
            filename: response?.data?.filename ?? filename,
          });
          toastSuccess({
            title: intl.formatMessage({
              defaultMessage: "Le téléchargement va débuter.",
            }),
          });
        }
      })
      .catch((response) => {
        toastSuccess({
          title: response?.body?.errors?.[0]?.detail
            ? apiGetErrorDetail({ error: response })
            : intl.formatMessage({
                defaultMessage:
                  "Une erreur est survenue lors du téléchargement du fichier.",
              }),
          status: "error",
        });
      });
  }

  if (label) {
    return (
      <Button
        isLoading={isLoading}
        onClick={handleClick}
        leftIcon={<Icon as={MdOutlineDownload} />}
        colorScheme="brandSecondary"
        size="sm"
        {...otherProps}>
        {label}
      </Button>
    );
  }

  return (
    <IconButton
      isLoading={isLoading}
      onClick={handleClick}
      as="button"
      size="sm"
      colorScheme="brandSecondary"
      icon={<Icon as={MdOutlineDownload} />}
      {...otherProps}
    />
  );
}

ButtonDownloadFile.propTypes = {
  endpointUrl: PropTypes.string.isRequired,
  label: PropTypes.string,
  filename: PropTypes.string,
};

// deps
import { Select } from "@chakra-ui/react";
import { useCallback } from "react";

// constants
import { LOCALES_LANG_NAMES } from "../../constants/locales";

// hooks
import useLocale from "../../hooks/useLocale";

export default function LocaleUISelect(props) {
  const { ...otherProps } = props;

  const { locale, locales, setLocale } = useLocale();

  /**
   * Change la langue utilisée.
   */
  const handleChangeLocale = useCallback(
    function (event) {
      const locale = event.target.value;

      setLocale(locale, { saveInCookie: true });
    },
    [setLocale],
  );

  if (locales.length < 2) {
    return null;
  }

  return (
    <Select value={locale} onChange={handleChangeLocale} {...otherProps}>
      {locales.map(function (locale) {
        if (locale === "default") {
          return;
        }
        return (
          <option key={locale} value={locale}>
            {LOCALES_LANG_NAMES[locale].name}
          </option>
        );
      })}
    </Select>
  );
}

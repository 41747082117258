import PropTypes from "prop-types";

/**
 *
 * @typedef {"queued" | "dispatched" | "started" | "completed" | "canceled" | "failed"} TaskStateType
 *
 * @typedef {"public" | "private" | "limited"} TaskVisibilityType
 *
 * @typedef {object} TaskResultCountObjectType
 * @property {number} count
 * @property {number} [deleted]
 * @property {number} [restored]
 * @property {number} [updated]
 * @property {number} unauthorized
 *
 * @typedef {object} TaskResult
 * @property {TaskResultCountObjectType} directories
 * @property {TaskResultCountObjectType} files
 *
 * @typedef {object} Task
 * @property {number} id
 * @property {number} environment_id
 * @property {string | null} issuer_type
 * @property {number | null} issuer_id
 * @property {string} object_type
 * @property {number} object_id
 * @property {string} type
 * @property {TaskVisibilityType} visibility
 * @property {TaskStateType} state
 * @property {string} job_queue
 * @property {string} [job_serialized]
 * @property {number} attempts
 * @property {number} max_attempts
 * @property {number} progress
 * @property {number} max_progress
 * @property {TaskResult} results
 * @property {string | null} available_at
 * @property {string | null} dispatched_at
 * @property {string | null} started_at
 * @property {string | null} completed_at
 * @property {string | null} canceled_at
 * @property {string | null} failed_at
 * @property {string | null} created_at
 * @property {object} issuer
 * @property {object} object
 * @property {object} [meta]
 */

export const TaskType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  environment_id: PropTypes.number,
  issuer_type: PropTypes.string,
  issuer_id: PropTypes.number,
  object_type: PropTypes.string,
  object_id: PropTypes.number,
  type: PropTypes.string,
  visibility: PropTypes.string,
  state: PropTypes.string,
  job_queue: PropTypes.string,
  job_serialized: PropTypes.string,
  attempts: PropTypes.number,
  max_attempts: PropTypes.number,
  progress: PropTypes.number,
  max_progress: PropTypes.number,
  results: PropTypes.object,
  available_at: PropTypes.string,
  dispatched_at: PropTypes.string,
  started_at: PropTypes.string,
  completed_at: PropTypes.string,
  canceled_at: PropTypes.string,
  failed_at: PropTypes.string,
  created_at: PropTypes.string,
  issuer: PropTypes.object,
  object: PropTypes.object,
  meta: PropTypes.object,
});

import { MdFilePresent, MdFolderOpen } from "react-icons/md";
import {
  FILES_MEDIAS_LIST,
  FILES_TYPE_VALUE_FILE,
} from "../../../constants/files";

/**
 * Helper to get the icon of a file (image, file, pdf...) or directory
 *
 * @typedef {Object} Props
 * @property {import("../../../types/File").File} file
 */

/**
 * @param {Props} props
 * @return {import("react-icons").IconType}
 */
export default function getFileOrDirectoryIcon({ file }) {
  if (file.type === FILES_TYPE_VALUE_FILE) {
    return (
      FILES_MEDIAS_LIST.find((fileMedia) => fileMedia.id === file.mime_type)
        ?.icon ?? MdFilePresent
    );
  } else {
    return MdFolderOpen;
  }
}

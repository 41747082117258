import { useState } from "react";
import {
  Button,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";
import Splash from "@raiden/library/components/Splash";
import useApiFetcher from "@raiden/library/hooks/useApiFetcher";
import useForm from "@splitfire-agency/raiden-library/dist/hooks/useForm";
import generateApiUrl from "@raiden/library/libraries/utils/generateApiUrl";
import { FileType } from "@raiden/library/types/File";
import { useTaskDrawer } from "../../../hooks/useTaskDrawer";

/**
 * @typedef Props
 * @property {import("@raiden/library/types/File").File} file
 * @property {boolean} isOpen
 * @property {() => void} onClose
 * @property {() => void} onSuccess
 * @property {boolean} [isInModal]
 *
 */

/**
 * @param {Props} props
 * @return {import("react").FunctionComponentElement<Props>}
 */
export default function FilesRestore(props) {
  const { file, isOpen, onClose, onSuccess, isInModal } = props;

  const intl = useIntl();

  const apiFetcher = useApiFetcher();

  const toast = useToast();

  const { mutateNumberOfStartedTasks } = useTaskDrawer();

  const colorText = useColorModeValue("gray.700", "white.700");
  const colorDescription = useColorModeValue("gray.500", "white.500");

  const [filesRestoreResponse, setFilesRestoreResponse] = useState({
    loading: false,
  });

  const form = useForm({ onSubmit: handleSubmit });

  /**
   * Gère la soumission du formulaire.
   */
  function handleSubmit() {
    setFilesRestoreResponse({ loading: true });

    apiFetcher(
      generateApiUrl({
        id: "@api.files.restore",
        parameters: {
          fileId: file?.id,
        },
        query: {},
      }),
      {
        method: "POST",
      },
      {
        getResponseStatus: true,
      },
    ).then(({ status }) => {
      if (status === 202) {
        mutateNumberOfStartedTasks();
      }
      toast({
        status: "success",

        title: intl.formatMessage(
          {
            defaultMessage:
              "{isTaskAssociated, select, true {Une tâche de restauration est en cours, vous pouvez consulter son évolution dans l'onglet tâches.} other { Le {type, select, file {fichier} other {dossier}} a été restauré ! }}",
          },
          {
            isTaskAssociated: status === 202,
            type: file?.type,
          },
        ),
        duration: 5000,
        isClosable: true,
      });

      onSuccess();
    });
  }

  const isLoading = filesRestoreResponse.loading;

  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        scrollBehavior="inside"
        blockScrollOnMount={!isInModal}>
        <ModalOverlay />

        <ModalContent as="form" onSubmit={form.onSubmit}>
          <ModalHeader color={colorText} fontSize="xl">
            {intl.formatMessage(
              {
                defaultMessage:
                  "Restaurer le {type, select, file {fichier} other {dossier}}",
              },
              {
                type: file?.type,
              },
            )}
          </ModalHeader>

          <ModalCloseButton />

          <ModalBody textAlign="center">
            <Splash
              image="delete"
              title={intl.formatMessage(
                {
                  defaultMessage:
                    "Restaurer le {type, select, file {fichier} other {dossier}}",
                },
                {
                  type: file?.type,
                },
              )}
              description={
                <Text color={colorDescription}>
                  {intl.formatMessage(
                    {
                      defaultMessage:
                        "Êtes-vous sûr de vouloir restaurer le {type, select, file {fichier} other {dossier}} « {name} » ?",
                    },
                    {
                      type: file?.type,
                      name: file?.filename,
                    },
                  )}
                </Text>
              }
            />
          </ModalBody>

          <ModalFooter>
            <HStack spacing=".5rem">
              <Button
                colorScheme="gray"
                isDisabled={isLoading}
                onClick={onClose}
                variant="outline"
                size="sm">
                {intl.formatMessage({
                  defaultMessage: "Annuler",
                })}
              </Button>

              <Button type="submit" isLoading={isLoading} size="sm">
                {intl.formatMessage({
                  defaultMessage: "Restaurer",
                })}
              </Button>
            </HStack>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

FilesRestore.propTypes = {
  file: FileType.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSuccess: PropTypes.func,
};

FilesRestore.defaultProps = {};

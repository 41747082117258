import { useDisclosure } from "@chakra-ui/react";
import { POLICIES_POLICY_VALUE_UPDATE } from "@raiden/library/constants/policies";
import useCanPerform from "@raiden/library/hooks/useCanPerform";
import { FILES_TYPE_VALUE_FILE } from "@raiden/library/constants/files";
import { FileType } from "@raiden/library/types/File";
import PropTypes from "prop-types";
import UpdateFile from "../Update/File";
import UpdateDirectory from "../Update/Directory";

/**
 * @callback childrenRender
 * @param {{disclosure: import("@chakra-ui/react").UseDisclosureReturn}} disclosure
 */

/**
 * @typedef Props
 * @property {import("@raiden/library/types/File").File} resource
 * @property {() => void} onSuccess
 * @property {childrenRender} children
 * @property {boolean} [isInModal]*
 */
/**
 * @param {Props} props
 * @return {import("react").FunctionComponentElement<Props>}
 */
export default function FilesActionsUpdate(props) {
  const { resource, onSuccess, children, isInModal = false } = props;

  const disclosure = useDisclosure();

  const { isOpen, onClose } = disclosure;

  /**
   * Gère le succès de la modification d’une resource
   */
  function handleSuccess() {
    onClose();
    onSuccess();
  }

  const canPerform = useCanPerform();

  const policies = resource?.meta?.policies ?? [];

  return (
    <>
      {canPerform({
        policies: POLICIES_POLICY_VALUE_UPDATE,
        resourcePolicies: policies,
      }) && (
        <>
          {resource?.type === FILES_TYPE_VALUE_FILE ? (
            <UpdateFile
              id={resource.id}
              isOpen={isOpen}
              onClose={onClose}
              onSuccess={handleSuccess}
              isInModal={isInModal}
            />
          ) : (
            <UpdateDirectory
              id={resource.id}
              isOpen={isOpen}
              onClose={onClose}
              onSuccess={handleSuccess}
              isInModal={isInModal}
            />
          )}

          {children({ disclosure })}
        </>
      )}
    </>
  );
}

FilesActionsUpdate.propTypes = {
  children: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  resource: FileType.isRequired,
  isInModal: PropTypes.bool,
};

import getBasePath from "@splitfire-agency/raiden-library/dist/libraries/utils/getBasePath";
import cookie from "cookie";
import dayjs from "dayjs";
import { createContext, memo, useContext, useMemo, useRef } from "react";
import { COOKIES_NAME_VALUE_PAGINATION_PER_PAGE } from "../../constants/cookies";
import { useConfiguration } from "../../hooks/useConfiguration";
// deps
import { useColorMode } from "@chakra-ui/react";

/**
 * @typedef {object} PreferencesContextValue
 * @property {number} paginationPerPage
 * @property {(value: number) => void} setPaginationPerPage
 * @property {import('../../types/Configuration').ConfigurationEnvironment[]} environments
 * @property {import("../../types/Configuration").ConfigurationEnvironment | null} bestEnvironment
 * @property {import("@chakra-ui/react").ColorMode} globalColorMode
 * @property {boolean} shouldRenderEnvironments
 */

/** @type {PreferencesContextValue} */
const DEFAULT_VALUE = {
  paginationPerPage: 25,
  setPaginationPerPage: () => {},
  environments: [],
  bestEnvironment: null,
  shouldRenderEnvironments: false,
  globalColorMode: "light",
};

export const PreferencesContext = createContext(DEFAULT_VALUE);

export const PreferencesProvider = memo(
  /**
   * @typedef {object} props
   * @property {number} [initialPaginationPerPage]
   * @property {boolean} [initialPreferMenuShrink]
   * @property {import("react").ReactNode} children
   *
   * @param {props} props
   */
  function PreferencesProvider(props) {
    const { initialPaginationPerPage = 25, children } = props;

    const { configuration } = useConfiguration();

    const environments = useMemo(() => {
      return configuration?.environments ?? [];
    }, [configuration?.environments]);

    const paginationPerPage = useRef(Number(initialPaginationPerPage));
    const { colorMode } = useColorMode();

    const value = useMemo(
      /** @return {PreferencesContextValue} */
      function () {
        return {
          paginationPerPage: paginationPerPage.current,
          setPaginationPerPage(newPaginationPerPage) {
            document.cookie = cookie.serialize(
              COOKIES_NAME_VALUE_PAGINATION_PER_PAGE,
              String(newPaginationPerPage),
              {
                expires: dayjs().add(1, "years").toDate(),
                path: getBasePath(
                  process.env.NEXT_PUBLIC_ADMIN_BASE_PATH ?? "",
                ),
                secure: true,
              },
            );

            paginationPerPage.current = newPaginationPerPage;
          },
          globalColorMode: colorMode,
          shouldRenderEnvironments: environments.length > 1,
          bestEnvironment: environments.length === 1 ? environments[0] : null,
          // TODO make sure it is not used and remove it
          environments: environments,
        };
      },
      [environments, colorMode],
    );

    return (
      <PreferencesContext.Provider value={value}>
        {children}
      </PreferencesContext.Provider>
    );
  },
);

export const usePreferences = function () {
  return useContext(PreferencesContext);
};

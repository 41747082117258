// deps
import { Icon, IconButton } from "@chakra-ui/react";
import { MdSearch } from "react-icons/md";
import { useIntl } from "react-intl";

// contexts
import { useSearch } from "@raiden/library/contexts/Search";

/**
 * @typedef {object} Props
 */

/**
 * @param {Props} props
 * @return {import("react").FunctionComponentElement<Props>}
 */
function SearchCommonSubmit(props) {
  const intl = useIntl();

  const { form, preventSubmitIfNotDirty } = useSearch();

  return (
    <IconButton
      aria-label={intl.formatMessage({
        defaultMessage: "Soumettre",
      })}
      isDisabled={preventSubmitIfNotDirty && !form.formState.isDirty}
      colorScheme="brandPrimary"
      alignSelf="flex-end"
      type="submit"
      icon={<Icon as={MdSearch} />}
      {...props}
    />
  );
}

export default SearchCommonSubmit;

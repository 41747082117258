import { Box, Button, Grid, HStack, Icon, VStack } from "@chakra-ui/react";
import { DirectoriesAutocomplete } from "@raiden/library/components/Form/DirectoriesAutcomplete";
import ApiErrorMessage from "@raiden/library/components/ReactHookForm/ApiErrorMessage";
import FileDragAndDropRHF from "@raiden/library/components/ReactHookForm/FileDragAndDropRHF";
import FormControlRHF from "@raiden/library/components/ReactHookForm/FormController";
import ObserverRHF from "@raiden/library/components/ReactHookForm/ObserverRHF";
import { FILES_MAX_FILESIZE_FOR_DRAG_AND_DROP } from "@raiden/library/constants/files";
import { usePreferences } from "@raiden/library/contexts/Preferences";
import useAuth from "@raiden/library/hooks/useAuth";
import PropTypes from "prop-types";
import { useState } from "react";
import { useFormContext } from "react-hook-form";
import { MdAdd, MdRemove } from "react-icons/md";
import { useIntl } from "react-intl";
import EnvironmentSelect from "../../../../components/EnvironmentSelect";
import FormRestriction from "../../../../components/Form/Restriction";
import FileVisibility from "../Visibility";

/**
 * @typedef {Object} Props
 * @property {string} context
 * @property {Array<Object>} [fileStates]
 * @property {(fileStates: Array<Object>) => void} [setFileStates]
 *
 */

/**
 * @param {Props} props
 * @return {import("react").FunctionComponentElement<Props>}
 */
export default function Form({ context, fileStates, setFileStates }) {
  const intl = useIntl();

  /** @type {import("react-hook-form").UseFormReturn<import("@raiden/library/types/File").FileFormValues>} */
  const { control } = useFormContext();

  const { shouldRenderEnvironments, environments } = usePreferences();

  const [isMoreOptions, setIsMoreOptions] = useState(false);

  const { root: isRoot } = useAuth();

  const constraints = {
    maxFileSize: FILES_MAX_FILESIZE_FOR_DRAG_AND_DROP,
    maxNumberOfFiles: 30,
  };

  function onAdvancedClick() {
    setIsMoreOptions(!isMoreOptions);
  }

  return (
    <>
      <ApiErrorMessage />

      {context === "edit" && (
        <Box mb="1rem" width="full">
          <FormControlRHF
            control={control}
            name="data.filename"
            label={intl.formatMessage({
              defaultMessage: "Nom du fichier",
            })}
          />
        </Box>
      )}

      <Grid
        templateColumns={
          shouldRenderEnvironments && context === "create" ? "1fr 1fr" : "1fr"
        }
        gap="1rem"
        mb="1rem">
        {shouldRenderEnvironments && context !== "edit" && (
          <FormControlRHF
            control={control}
            name="data.environment_id"
            rules={{ required: true }}
            label={intl.formatMessage({
              defaultMessage: "Environnement",
            })}
            renderWithFormControl={(fields) => (
              <EnvironmentSelect
                {...fields}
                environments={environments}
                placeholder={intl.formatMessage({
                  defaultMessage: "Choisir un environnement...",
                })}
              />
            )}
          />
        )}

        <ObserverRHF
          names={["data.environment_id"]}
          render={({ values: [environmentId] }) => (
            <>
              <FormControlRHF
                control={control}
                name="data.parent_id"
                label={intl.formatMessage({
                  defaultMessage: "Choisir un dossier parent",
                })}
                helperText={intl.formatMessage({
                  defaultMessage:
                    "Par défaut, si aucun dossier n'est sélectionné, le fichier ou dossier sera créé dans le dossier racine",
                })}
                renderWithFormControl={(field) => (
                  <DirectoriesAutocomplete
                    environmentId={[environmentId]}
                    {...field}
                  />
                )}
              />
            </>
          )}
        />
      </Grid>

      <VStack spacing="1rem" align="stretch">
        <FormControlRHF
          control={control}
          name="data.visibility"
          label={intl.formatMessage({
            defaultMessage: "Visibilité",
          })}
          renderWithFormControl={(field) => (
            <FileVisibility variant="column" {...field} withIcon={true} />
          )}
        />

        {isRoot && (
          <>
            <HStack spacing="1rem" align="stretch">
              <Button
                size="sm"
                onClick={onAdvancedClick}
                marginLeft="auto"
                variant="outline"
                leftIcon={<Icon as={isMoreOptions ? MdRemove : MdAdd} />}
                colorScheme="brandPrimary">
                {intl.formatMessage(
                  {
                    defaultMessage:
                      "{advanced, select, true {Moins d'options} other {Plus d'options}}",
                  },
                  {
                    advanced: isMoreOptions,
                  },
                )}
              </Button>
            </HStack>

            {isMoreOptions && (
              <FormControlRHF
                control={control}
                name="data.is_restricted"
                label={intl.formatMessage({
                  defaultMessage: "Restriction",
                })}
                renderWithFormControl={(field) => (
                  <FormRestriction
                    variant="column"
                    withIcon={true}
                    subject={intl.formatMessage({
                      defaultMessage: "le fichier",
                    })}
                    {...field}
                  />
                )}
              />
            )}
          </>
        )}
      </VStack>

      <VStack spacing="1rem" mt="1rem" align="stretch">
        {context === "create" && (
          <FileDragAndDropRHF
            constraints={constraints}
            name="files"
            fileStates={fileStates}
            setFileStates={setFileStates}
            rules={{
              required: true,
            }}
          />
        )}
      </VStack>
    </>
  );
}

Form.propTypes = {
  context: PropTypes.string.isRequired,
  fileStates: PropTypes.array,
  setFileStates: PropTypes.func,
};

import { Text } from "@chakra-ui/react";
import { TASKS_OBJECT_TYPE_VALUE_FILE } from "@raiden/library/constants/tasks";
import Table from "@splitfire-agency/raiden-library/dist/components/Table";
import { useIntl } from "react-intl";
import FileResult from "./ResultByObjectType/FileResult";

/**
 * @typedef {object} Props
 * @property {import("@raiden/library/types/Task").Task} resource
 */

/**
 * @param {Props} props
 * @return {import("react").FunctionComponentElement<Props>}
 */
export default function TaskResult({ resource }) {
  const intl = useIntl();

  function getTaskResultByObjectType() {
    const getNoResult = () => (
      <Table.Tr>
        <Table.Td>
          {intl.formatMessage({
            defaultMessage: "Résultats",
          })}
        </Table.Td>

        <Table.Td>
          <Text>
            {intl.formatMessage({
              defaultMessage: "Aucun résultat",
            })}
          </Text>
        </Table.Td>
      </Table.Tr>
    );

    if (!resource.results) {
      return getNoResult();
    }

    switch (resource.object_type) {
      case TASKS_OBJECT_TYPE_VALUE_FILE:
        return <FileResult results={resource.results} />;
      default:
        return getNoResult();
    }
  }

  return getTaskResultByObjectType();
}

import {
  MdBlock,
  MdErrorOutline,
  MdFileCopy,
  MdOutlineCancel,
  MdOutlineDone,
  MdOutlinePublic,
  MdOutlinePublicOff,
  MdOutlineQueuePlayNext,
  MdOutlineSend,
  MdOutlineSlideshow,
} from "react-icons/md";
import { defineMessage } from "react-intl";

// Tri
export const TASKS_SORT_VALUE_CREATED_ASC = "created_asc";
export const TASKS_SORT_VALUE_CREATED_DESC = "created_desc";

export const TASKS_SORTS = {
  [TASKS_SORT_VALUE_CREATED_ASC]: {
    id: TASKS_SORT_VALUE_CREATED_ASC,
  },
  [TASKS_SORT_VALUE_CREATED_DESC]: {
    id: TASKS_SORT_VALUE_CREATED_DESC,
  },
};

export const TASKS_SORT_LIST = Object.values(TASKS_SORTS);

export const TASKS_SORT_MESSAGE = defineMessage({
  defaultMessage:
    "{sort, select, created_asc {Date de création croissante} created_desc {Date de création décroissante} other {{sort}}}",
});

export const TASKS_STATE_VALUE_QUEUED = "queued";
export const TASKS_STATE_VALUE_DISPATCHED = "dispatched";
export const TASKS_STATE_VALUE_STARTED = "started";
export const TASKS_STATE_VALUE_COMPLETED = "completed";
export const TASKS_STATE_VALUE_CANCELED = "canceled";
export const TASKS_STATE_VALUE_FAILED = "failed";

export const TASKS_STATES = {
  [TASKS_STATE_VALUE_QUEUED]: {
    id: TASKS_STATE_VALUE_QUEUED,
    label: defineMessage({ defaultMessage: "En file d'attente" }),
    colors: ["purple.600", "purple.300"],
    icon: MdOutlineQueuePlayNext,
  },
  [TASKS_STATE_VALUE_DISPATCHED]: {
    id: TASKS_STATE_VALUE_DISPATCHED,
    label: defineMessage({ defaultMessage: "Envoyée" }),
    colors: ["green.600", "green.300"],
    icon: MdOutlineSend,
  },
  [TASKS_STATE_VALUE_STARTED]: {
    id: TASKS_STATE_VALUE_STARTED,
    label: defineMessage({ defaultMessage: "Démarrée" }),
    colors: ["green.600", "green.300"],
    icon: MdOutlineSlideshow,
  },
  [TASKS_STATE_VALUE_COMPLETED]: {
    id: TASKS_STATE_VALUE_COMPLETED,
    label: defineMessage({ defaultMessage: "Terminée" }),
    colors: ["green.600", "green.300"],
    icon: MdOutlineDone,
  },
  [TASKS_STATE_VALUE_CANCELED]: {
    id: TASKS_STATE_VALUE_CANCELED,
    label: defineMessage({ defaultMessage: "Annulée" }),
    colors: ["orange.600", "orange.300"],
    icon: MdOutlineCancel,
  },
  [TASKS_STATE_VALUE_FAILED]: {
    id: TASKS_STATE_VALUE_FAILED,
    label: defineMessage({ defaultMessage: "Échouée" }),
    colors: ["red.600", "red.300"],
    icon: MdErrorOutline,
  },
};

export const TASKS_STATE_LIST = Object.values(TASKS_STATES);

export const tasksStatesMessage = defineMessage({
  defaultMessage:
    "{state, select, queued {En file d'attente} dispatched {Envoyée} started {Démarrée} completed {Terminée} canceled {Annulée} failed {Échouée} other {state}}",
});

export const TASKS_VISIBILITY_VALUE_PRIVATE = "private";
export const TASKS_VISIBILITY_VALUE_PUBLIC = "public";
export const TASKS_VISIBILITY_VALUE_LIMITED = "limited";

export const TASKS_VISIBILITIES = {
  [TASKS_VISIBILITY_VALUE_PRIVATE]: {
    id: TASKS_VISIBILITY_VALUE_PRIVATE,
    label: defineMessage({ defaultMessage: "Privé" }),
    icon: MdOutlinePublicOff,
    colors: ["purple.600", "purple.300"],
  },
  [TASKS_VISIBILITY_VALUE_PUBLIC]: {
    id: TASKS_VISIBILITY_VALUE_PUBLIC,
    label: defineMessage({ defaultMessage: "Publique" }),
    icon: MdOutlinePublic,
    colors: ["green.600", "green.300"],
  },
  [TASKS_VISIBILITY_VALUE_LIMITED]: {
    id: TASKS_VISIBILITY_VALUE_LIMITED,
    label: defineMessage({ defaultMessage: "Limitée" }),
    icon: MdBlock,
    colors: ["orange.600", "orange.300"],
  },
};

export const TASKS_VISIBILITY_LIST = Object.values(TASKS_VISIBILITIES);

export const taskVisibilityMessage = defineMessage({
  defaultMessage:
    "{visibility, select, public {Publique} private {Privée} limited {Limitée} other {visibility}}",
});

export const TASKS_OBJECT_TYPE_VALUE_FILE = "file";

export const TASKS_OBJECT_TYPES = {
  [TASKS_OBJECT_TYPE_VALUE_FILE]: {
    id: TASKS_OBJECT_TYPE_VALUE_FILE,
    label: defineMessage({ defaultMessage: "Fichier" }),
    icon: MdFileCopy,
  },
};

export const TASKS_OBJECT_TYPES_LIST = Object.values(TASKS_OBJECT_TYPES);

export const tasksObjectsTypesMessage = defineMessage({
  defaultMessage: "{object_type, select, file {Fichier} other {object_type}}",
});

export const TASKS_TYPE_VALUE_DELETE = `App\\Jobs\\File\\Delete`;
export const TASKS_TYPE_VALUE_RESTORE = `App\\Jobs\\File\\Restore`;
export const TASKS_TYPE_VALUE_UPDATE_CHILDREN = `App\\Jobs\\File\\UpdateChildren`;

export const TASKS_TYPES = {
  [TASKS_TYPE_VALUE_DELETE]: {
    id: TASKS_TYPE_VALUE_DELETE,
  },
  [TASKS_TYPE_VALUE_RESTORE]: {
    id: TASKS_TYPE_VALUE_RESTORE,
  },
  [TASKS_TYPE_VALUE_UPDATE_CHILDREN]: {
    id: TASKS_TYPE_VALUE_UPDATE_CHILDREN,
  },
};

export const TASKS_TYPES_LIST = Object.values(TASKS_TYPES);

export const tasksTypesMessage = defineMessage({
  defaultMessage:
    "{type, select, AppJobsFileDelete {Suppression d'un fichier} AppJobsFileRestore {Restauration d'un fichier} AppJobsFileUpdateChildren {Mise à jour des enfants d'un dossier} other {type}}",
});

export const tasksObjectTypeResultMessage = defineMessage({
  defaultMessage:
    "{type, select, directories {Dossiers} files {Fichiers} other {type}}",
});

export const TASKS_OBJECT_TYPE_RESULT_ACTION_VALUE_DELETED = "deleted";
export const TASKS_OBJECT_TYPE_RESULT_ACTION_VALUE_UPDATED = "updated";
export const TASKS_OBJECT_TYPE_RESULT_ACTION_VALUE_RESTORED = "restored";

export const tasksObjectTypeResultActionMessage = defineMessage({
  defaultMessage:
    "{type, select, deleted {Supprimés} updated {Mis à jours} restored {Restaurés} other {type}} : {count}",
});

// deps
import { Text } from "@chakra-ui/react";
import useTranslate from "@raiden/library/hooks/useTranslate";

// constants
import { SEARCH_RESULTS_OBJECT_TYPE_CUSTOMER } from "@raiden/library/constants/search/results";

const textSize = "12px";
const textWeight = 600;

/**
 * @typedef {Object} Props
 * @property {import("@raiden/library/types/search/Result").SearchResult} searchResult
 */

/**
 * @param {Props} props
 */
export default function SearchGlobalDataDetails({ searchResult }) {
  const translate = useTranslate();

  switch (searchResult.object_type) {
    case SEARCH_RESULTS_OBJECT_TYPE_CUSTOMER:
      return (
        <Text fontSize={textSize} fontWeight={textWeight} noOfLines={1}>
          {translate(searchResult.translations?.title)}
        </Text>
      );
    default:
      return (
        <>
          <Text fontSize={textSize} fontWeight={textWeight} noOfLines={1}>
            {translate(searchResult.translations?.title)}
          </Text>

          {searchResult.translations?.subtitle && (
            <Text fontSize={textSize} noOfLines={1}>
              {translate(searchResult.translations?.subtitle)}
            </Text>
          )}
        </>
      );
  }
}

import {
  Box,
  Collapse,
  Flex,
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  Text,
  Textarea,
  useMergeRefs,
} from "@chakra-ui/react";
import DatePicker from "../../DatePicker";
import { cloneElement, forwardRef, isValidElement } from "react";
import { useController, useFormContext } from "react-hook-form";
import FormErrorMessageRHF from "../FormErrorMessageRHF";
import FormErrorObserverRHF from "../FormErrorObserverRHF";
import InputNumber from "../../Form/InputNumber";

// constants
import { RULES_TEXTAREA_MAX_LENGTH } from "../../../constants/form";
import { useIntl } from "react-intl";

/**
 * @typedef {object} Rules
 * @property {boolean} [required]
 * @property {number} [maxLength]
 * @property {number} [minLength]
 * @property {number} [max]
 * @property {number} [min]
 * @property {RegExp} [pattern]
 * @property {(value: any) => boolean | Promise<boolean> | string} [validate]
 * @property {boolean} [valueAsNumber]
 * @property {boolean} [valueAsDate]
 * @property {(value: any) => any} [setValueAs]
 * @property {boolean} [disabled]
 * @property {(e: import("react").SyntheticEvent) => void} [onChange]
 * @property {(e: import("react").SyntheticEvent) => void} [onBlur]
 * @property {any} [value]
 */

/**
 * @template T
 * @typedef {object} FieldProps
 * @property {string} name
 * @property {import("react-hook-form").Noop} onBlur
 * @property {(event: {target: { name: string, value: T}}) => void} onChange
 * @property {T} value
 * @property {boolean} isRequired
 * @property {boolean} isDisabled
 * @property {boolean} isInvalid
 */

/**
 * @template {import("react-hook-form").FieldValues} TFieldValues
 * @template {import("react-hook-form").FieldPath<TFieldValues>} TName
 * @typedef {object} FormControllerRenderParams
 * @property {import("react-hook-form").FieldPath<TFieldValues>} name
 * @property {import("react-hook-form").Noop} onBlur
 * @property {(...event: any[]) => void} onChange
 * @property {import("react-hook-form").RefCallBack | ((node: any) => void) | null} ref
 * @property {import("react-hook-form").FieldPathValue<TFieldValues, TName>} value
 * @property {boolean} isRequired
 * @property {boolean} isDisabled
 * @property {boolean} isInvalid
 * @property {import("react-hook-form").FieldError} [error]
 */

/**
 * A component that is used as a fundamental block of a form. Every field should be wrapped by this component.
 * @template {import("react-hook-form").FieldValues} TFieldValues
 * @template {import("react-hook-form").FieldPath<TFieldValues>} TName
 * @typedef {object} Props
 * @property {import("react-hook-form").Control<TFieldValues>} [control]
 * @property {TName} name
 * @property {Rules} [rules]
 * @property {string} [type]
 * @property {any} [label]
 * @property {any} [placeholder]
 * @property {any} [defaultValue]
 * @property {number} [minValue]
 * @property {number} [maxValue]
 * @property {any} [helperText]
 * @property {object} [labelProps]
 * @property {boolean} [isDisabled]
 * @property {boolean} [displayError]
 * @property {any} [inputProps]
 * @property {boolean} [shouldUnregister]
 * @property {boolean} [withCharactersCounter]
 * @property {(params: FormControllerRenderParams<TFieldValues, TName>) => React.ReactNode} [render]
 * @property {(params: FormControllerRenderParams<TFieldValues, TName>) => React.ReactNode} [renderWithFormControl]
 * @property {React.ReactNode} [children]
 */

/**
 * @template {import("react-hook-form").FieldValues} TFieldValues
 * @template {import("react-hook-form").FieldPath<TFieldValues>} TName
 * @param {Props<TFieldValues, TName> & import("@chakra-ui/react").BoxProps} props
 * @param {import("react").ForwardedRef<any>} ref
 */
function _FormController(
  {
    control,
    name,
    rules,
    type,
    label,
    placeholder,
    isDisabled: _isDisabled,
    inputProps,
    helperText,
    displayError = true,
    defaultValue,
    minValue,
    maxValue,
    render,
    renderWithFormControl,
    labelProps,
    shouldUnregister = false,
    withCharactersCounter = false,
    children,
  },
  ref,
) {
  const form = useFormContext();

  /** @type {Rules} */
  const localRules = {
    ...rules,
    ...(withCharactersCounter && {
      maxLength: rules?.maxLength ?? RULES_TEXTAREA_MAX_LENGTH,
    }),
  };

  const {
    field,
    fieldState: { error },
  } = useController({
    name,
    // @ts-ignore
    control: control ?? form.defaultControl,
    rules,
    shouldUnregister,
    defaultValue,
  });

  const intl = useIntl();

  const mergedRefs = useMergeRefs(ref, field.ref);

  const isDisabled = Boolean(_isDisabled || rules?.disabled);
  const isRequired = Boolean(rules?.required);
  const isInvalid = Boolean(error);

  const commonFieldProps = {
    isDisabled,
    isRequired,
    isInvalid,
  };

  return (
    <Box w="full">
      {typeof render === "function" &&
        render({
          ...field,
          isDisabled,
          isRequired,
          isInvalid,
          error,
          ref: mergedRefs,
        })}

      {render === undefined && (
        <FormControl
          isDisabled={isDisabled}
          isRequired={isRequired}
          isInvalid={isInvalid}>
          {label && <FormLabel {...labelProps}>{label}</FormLabel>}

          {isValidElement(children) &&
            cloneElement(children, {
              ...field,
              ...commonFieldProps,
              ref: mergedRefs,
            })}

          {/* render by calling `children` - DEPRECATED - prefer `renderWithFormControl` */}

          {typeof children === "function" && children(field)}

          {typeof renderWithFormControl === "function" &&
            renderWithFormControl({
              ...field,
              ...commonFieldProps,
              error,
            })}

          {children === undefined &&
            renderWithFormControl === undefined &&
            (() => {
              switch (type) {
                case "datetime-picker":
                  return (
                    <DatePicker
                      {...field}
                      {...commonFieldProps}
                      ref={mergedRefs}
                      {...inputProps}
                    />
                  );
                case "number-input":
                  return (
                    <InputNumber
                      {...field}
                      {...commonFieldProps}
                      ref={mergedRefs}
                      value={field.value ?? defaultValue ?? 0}
                      min={minValue ?? undefined}
                      max={maxValue ?? undefined}
                      placeholder={
                        placeholder ??
                        (typeof label === "string" ? label : undefined)
                      }
                      {...inputProps}
                    />
                  );
                case "textarea":
                  return (
                    <>
                      <Textarea
                        {...field}
                        {...commonFieldProps}
                        ref={mergedRefs}
                        value={String(field?.value ?? "")}
                        placeholder={
                          placeholder ??
                          (typeof label === "string" ? label : undefined)
                        }
                        {...inputProps}
                      />

                      {withCharactersCounter && (
                        <Flex gap="1rem" alignItems="center" w="full">
                          <Text
                            fontSize="sm"
                            color={
                              (field?.value?.length ?? 0) >
                              (localRules?.maxLength ?? 0)
                                ? "red.500"
                                : "gray.500"
                            }
                            ml="auto">
                            {intl.formatMessage(
                              { defaultMessage: "{count}/{max} caractères" },
                              {
                                count: field?.value?.length ?? 0,
                                max: localRules?.maxLength,
                              },
                            )}
                          </Text>
                        </Flex>
                      )}
                    </>
                  );
                default:
                  return (
                    <Input
                      {...field}
                      {...commonFieldProps}
                      ref={mergedRefs}
                      type={type}
                      value={String(field?.value ?? "")}
                      placeholder={
                        placeholder ??
                        (typeof label === "string" ? label : undefined)
                      }
                      {...inputProps}
                    />
                  );
              }
            })()}

          {helperText && <FormHelperText>{helperText}</FormHelperText>}
        </FormControl>
      )}

      {displayError && (
        <FormErrorObserverRHF
          name={name}
          render={({ hasError, error }) => (
            <Collapse in={hasError} unmountOnExit={true}>
              <FormErrorMessageRHF error={error} />
            </Collapse>
          )}
        />
      )}
    </Box>
  );
}

/** @type {<TFieldValues extends import("react-hook-form").FieldValues, TName extends import("react-hook-form").FieldPath<TFieldValues>>(props: Props<TFieldValues, TName> & import("@chakra-ui/react").BoxProps & { ref?: import("react").MutableRefObject<any> }) => React.ReactElement} */
// @ts-ignore
const FormController = forwardRef(_FormController);

export default FormController;

// deps
import { Children, cloneElement, forwardRef } from "react";
import PropTypes from "prop-types";
import { Menu, MenuButton, MenuItem, MenuList } from "@chakra-ui/react";

// components
import MenuSelectItem from "./Item";

const MenuSelect = forwardRef(function (props, ref) {
  const { name, icon, placeholder, children, value, onChange } = props;

  const child = Children.toArray(children).find(function (child) {
    return child.props.value === value;
  });

  return (
    <>
      <input
        name={name}
        ref={ref}
        type="hidden"
        value={value ?? ""}
        data-value={
          child && "string" === typeof child.props.children
            ? child.props.children
            : ""
        }
      />

      <Menu>
        <MenuButton as={icon.type} {...icon.props} />

        <MenuList>
          {placeholder && (
            <MenuItem
              onClick={function () {
                onChange({
                  target: {
                    name,
                    value: "",
                  },
                });
              }}>
              {placeholder}
            </MenuItem>
          )}

          {Children.map(children, function (child) {
            return cloneElement(child, {
              onClick: function () {
                onChange({
                  target: {
                    name,
                    value: child.props.value,
                  },
                });
              },
            });
          })}
        </MenuList>
      </Menu>
    </>
  );
});

MenuSelect.propTypes = {
  name: PropTypes.string,
  placeholder: PropTypes.string,
  icon: PropTypes.node,
  value: PropTypes.any,
  children: PropTypes.node,
  onChange: PropTypes.func,
};

export default Object.assign(MenuSelect, {
  Item: MenuSelectItem,
});

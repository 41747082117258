// deps
import { useRef } from "react";
import { useCalendarState } from "@react-stately/calendar";
import { useCalendar } from "@react-aria/calendar";
import { useLocale } from "@react-aria/i18n";
import { Box, Button, Flex, HStack, Icon } from "@chakra-ui/react";

// components
import CalendarGrid from "./CalendarGrid";
import createCalendar from "./createCalendar";
import { MdOutlineChevronLeft, MdOutlineChevronRight } from "react-icons/md";
import MonthSelector from "./MonthSelector";
import YearSelector from "./YearSelector";
import CalendarIconButton from "./CalendarIconButton";
import { useIntl } from "react-intl";
import dayjs from "dayjs";
import PropTypes from "prop-types";
import { CalendarDate, CalendarDateTime } from "@internationalized/date";

/**
 * @typedef Props
 * @property {import("react").Dispatch<import("react").SetStateAction<any>>} setOpen
 * @property {import("@react-stately/datepicker").DateFieldState} state
 * @property {boolean} [shouldRenderClearButton]
 * @property {boolean} [shouldRenderTodayButton]
 * @property {(event: any) => void} onChange
 */
/**
 * @returns {import("react").FunctionComponentElement<Props>}
 */
function Calendar(props) {
  let { locale } = useLocale();

  const intl = useIntl();

  const {
    onChange,
    setOpen,
    shouldRenderClearButton,
    shouldRenderTodayButton,
    state,
  } = props;

  let localState = useCalendarState({
    ...props,
    locale,
    createCalendar,
  });

  let ref = useRef(null);
  let { calendarProps, prevButtonProps, nextButtonProps } = useCalendar(
    props,
    localState,
  );

  function clear() {
    onChange("");
    setOpen(false);
  }

  function isClearButtonEnabled() {
    return localState?.value;
  }

  function isTodayButtonEnabled() {
    const today = dayjs().format("YYYY-MM-DD");
    const minDate = localState?.minValue?.toString();

    const value = localState?.value?.toString();
    if (minDate) {
      return today < dayjs(minDate).format("YYYY-MM-DD");
    }

    if (value) {
      return today !== dayjs(value).format("YYYY-MM-DD");
    }

    return true;
  }

  function setDateToToday() {
    const today = dayjs();
    const date = new CalendarDate(
      today.year(),
      today.month() + 1,
      today.date(),
    );
    onChange(
      state.granularity !== "day"
        ? new CalendarDateTime(
            today.year(),
            today.month() + 1,
            today.date(),
            today.hour(),
            today.minute(),
            today.second(),
          )
        : date,
    );
    localState.setFocusedDate(date);
    setOpen(false);
  }

  return (
    <div {...calendarProps} ref={ref}>
      <Box display="flex" alignItems="center" paddingBottom="4">
        <CalendarIconButton
          {...prevButtonProps}
          type="previous"
          state={localState}
          icon={<Icon as={MdOutlineChevronLeft} w={6} h={6} />}
        />

        <HStack spacing=".5rem" px=".5rem">
          <MonthSelector state={localState} onChangeMonth={onChange} />

          <YearSelector state={localState} onChangeYear={onChange} />
        </HStack>

        <CalendarIconButton
          {...nextButtonProps}
          type="next"
          state={localState}
          icon={<Icon as={MdOutlineChevronRight} w={6} h={6} />}
        />
      </Box>

      <CalendarGrid
        state={localState}
        setOpen={setOpen}
        shouldCloseOnDaySelect={props.shouldCloseOnDaySelect}
      />

      <Flex justifyContent="space-between">
        {shouldRenderClearButton && (
          <Button
            size="xs"
            onClick={clear}
            isDisabled={!isClearButtonEnabled()}
            colorScheme="red"
            mt=".5rem">
            {intl.formatMessage({
              defaultMessage: "Effacer",
            })}
          </Button>
        )}

        {shouldRenderTodayButton && (
          <Button
            size="xs"
            onClick={setDateToToday}
            isDisabled={!isTodayButtonEnabled()}
            colorScheme="blue"
            mt=".5rem"
            ml="auto">
            {intl.formatMessage({
              defaultMessage: "Aujourd'hui",
            })}
          </Button>
        )}
      </Flex>
    </div>
  );
}

Calendar.propTypes = {
  onChange: PropTypes.func,
  setOpen: PropTypes.func,
  shouldRenderClearButton: PropTypes.bool,
  shouldRenderTodayButton: PropTypes.bool,
};

export default Calendar;

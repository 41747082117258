/**
 * Retourne un objet de file parsé pour le formulaire d'update avec les datas en provenance de l'API
 * @param {import("../../../types/File").File} file
 * @return {Object} file
 */
export default function dataParse(file) {
  return {
    filename: file?.filename ?? "",
    visibility: file?.visibility ?? "",
    is_restricted: file?.is_restricted ?? 0,
    parent_id: file?.parent_id ?? "",
    recursive_visibility: false,
    environment_id: file?.environment_id ?? [],
  };
}

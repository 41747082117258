// deps
import {
  Box,
  Button,
  HStack,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  VStack,
  Wrap,
  WrapItem,
} from "@chakra-ui/react";
import { useSearch } from "@raiden/library/contexts/Search";
import capitalize from "@splitfire-agency/raiden-library/dist/libraries/utils/capitalize";
import { useState } from "react";
import { MdOutlineTune, MdSearch } from "react-icons/md";
import { useIntl } from "react-intl";

// components
import { SearchCategoriesAutocomplete } from "@raiden/library/components/Form/SearchCategoriesAutocomplete";
import FormController from "@raiden/library/components/ReactHookForm/FormController";
import SelectMultiple from "@splitfire-agency/raiden-library/dist/components/SelectMultiple";
import SearchCommonFilterListRHF from "../../../components/Search/CommonFilterListRHF";
import SearchCommonResetAndTag from "../../../components/Search/CommonResetAndTag";
import SearchCommonSubmit from "../../../components/Search/CommonSubmit";

// constants
import { SEARCH_LOCAL_STORAGE_RECENT_SEARCH } from "@raiden/library/constants/search";
import { SEARCH_RESULTS_OBJECT_TYPE_LIST } from "@raiden/library/constants/search/results";
import ObserverRHF from "@raiden/library/components/ReactHookForm/ObserverRHF";

/**
 * @typedef {object} Props
 * @property {React.MutableRefObject<HTMLInputElement | null>} inputRef
 * @property {() => void} onCloseSearch
 */
/**
 * @param {Props} props
 */
function SearchGlobalFilters({ inputRef, onCloseSearch }) {
  const { searchId, form, onSubmit } = useSearch();

  const [isOpen, setIsOpen] = useState(false);

  const intl = useIntl();

  /**
   * @param {{ preventDefault: () => void; }} event
   */
  function handleSubmit(event) {
    event.preventDefault();

    const termValue = form.getValues("term");

    if (termValue.length > 1) {
      let recentSearchesList = JSON.parse(
        window.localStorage.getItem(`${SEARCH_LOCAL_STORAGE_RECENT_SEARCH}`) ??
          "[]",
      );

      // Remove the termValue from the list if it exists
      recentSearchesList = recentSearchesList.filter(
        /** @param {string} search */
        (search) => search !== termValue,
      );

      // Add the termValue to the beginning of the list
      recentSearchesList.unshift(termValue);

      // Limit the list to the last 10 items
      recentSearchesList = recentSearchesList.slice(0, 10);

      window.localStorage.setItem(
        `${SEARCH_LOCAL_STORAGE_RECENT_SEARCH}`,
        JSON.stringify(recentSearchesList),
      );
    }

    onSubmit();
  }

  return (
    <VStack
      as="form"
      spacing=".5rem"
      alignItems="flex-start"
      autoComplete="off"
      noValidate
      onSubmit={handleSubmit}>
      <Box width="full">
        <FormController
          ref={inputRef}
          name="term"
          rules={{
            required: true,
            validate: (value) => {
              if (value.length < 2) {
                return intl.formatMessage({
                  defaultMessage:
                    "La recherche doit contenir au moins 2 caractères",
                });
              }
              return true;
            },
          }}
          render={(fields) => (
            <HStack>
              <InputGroup>
                <InputLeftElement>
                  <Icon as={MdSearch} color="gray.300" />
                </InputLeftElement>

                <Box as="label" display="none" data-search-id={searchId}>
                  {intl.formatMessage({
                    defaultMessage: "Rechercher",
                  })}
                </Box>

                <Input
                  {...fields}
                  placeholder={intl.formatMessage({
                    defaultMessage: "Rechercher",
                  })}
                />
              </InputGroup>

              <Button onClick={() => setIsOpen(!isOpen)}>
                <Icon as={MdOutlineTune} />
              </Button>

              <SearchCommonSubmit alignSelf="flex-start" />
            </HStack>
          )}
        />
      </Box>

      {isOpen && (
        <Wrap>
          <SearchCommonFilterListRHF withEnvironments={true} />

          <WrapItem>
            <FormController
              labelProps={{ "data-search-id": searchId }}
              name="object_type"
              label={intl.formatMessage({
                defaultMessage: "Type",
              })}
              renderWithFormControl={(field) => (
                <SelectMultiple
                  {...field}
                  placeholder={intl.formatMessage({
                    defaultMessage: "Tous",
                    description: "placeholder",
                  })}>
                  {SEARCH_RESULTS_OBJECT_TYPE_LIST.map((objectType) => (
                    <option key={objectType.id} value={objectType.id}>
                      {capitalize(intl.formatMessage(objectType.label))}
                    </option>
                  ))}

                  <option value="other">
                    {intl.formatMessage({
                      defaultMessage: "Autre",
                    })}
                  </option>
                </SelectMultiple>
              )}
            />
          </WrapItem>

          <ObserverRHF
            names={["object_type"]}
            render={({ values: [objectType] }) => (
              <>
                {objectType?.includes("other") && objectType.length === 1 && (
                  <WrapItem>
                    <FormController
                      labelProps={{ "data-search-id": searchId }}
                      name="category_id"
                      label={intl.formatMessage({
                        defaultMessage: "Catégorie",
                      })}
                      renderWithFormControl={(field) => (
                        <SearchCategoriesAutocomplete {...field} />
                      )}
                    />
                  </WrapItem>
                )}
              </>
            )}
          />
        </Wrap>
      )}

      <Wrap>
        <SearchCommonResetAndTag />
      </Wrap>
    </VStack>
  );
}

export default SearchGlobalFilters;

/* istanbul ignore file */

// deps
import { useRouter } from "next/router";
import { useMemo } from "react";

// components
import Pagination from "../../../admin/components/Pagination";

// contexts
import { useSearch } from ".";
import { usePreferences } from "../Preferences";

function SearchPagination() {
  const {
    submittedFields,
    response,
    paginationUrl,
    form,
    displayedPerPages,
    onSubmit,
    routerSyncUrl,
  } = useSearch();

  const router = useRouter();

  const decoratedPaginationUrl = useMemo(
    function () {
      if (paginationUrl) {
        return function ({ perPage, page }) {
          return paginationUrl({
            fields: {
              ...(routerSyncUrl && router.query),
              ...submittedFields,
              per_page: perPage,
              page,
              total: undefined,
            },
          });
        };
      }
    },
    [submittedFields, paginationUrl, router.query, routerSyncUrl],
  );

  const { setPaginationPerPage } = usePreferences();

  const responseTotal = response.data?.["meta"]?.total;
  const responsePerPage = response.data?.["meta"]?.per_page;
  const responsePage = response.data?.["meta"]?.current_page;

  return (
    <>
      {responseTotal && (
        <Pagination
          page={responsePage}
          perPage={responsePerPage}
          total={responseTotal}
          href={decoratedPaginationUrl}
          displayedPerPages={displayedPerPages}
          onChangePage={function (event, { page }) {
            form.setValue("page", page);
            onSubmit();
          }}
          onChangePerPage={function (event, { perPage }) {
            form.setValue("page", 1);
            form.setValue("per_page", perPage);
            setPaginationPerPage(perPage);
            onSubmit();
          }}
        />
      )}
    </>
  );
}

export default SearchPagination;

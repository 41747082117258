/**
 * @typedef {Object} Props
 * @property {import("react").FunctionComponent} SplashNoResult
 * @property {import("react").FC<{ error: import("../../types/Api/ApiError").ApiError}>} SplashError
 * @property {import("react").ReactElement} loadingElement
 *
 * @param {Props} props
 */
export function buildDataHandlerComponent({
  SplashNoResult,
  SplashError,
  loadingElement,
}) {
  /**
   * @template [T=any]
   * @param {object} props
   * @param {import("swr").SWRResponse<T>} props.swrResponse the SWR response
   * @param {boolean} [props.keepDataOnRevalidation] if true, the children will be rendered even if the data is being revalidated
   * @param {boolean} [props.shouldRenderWithEmptyData] if true, the children will be rendered even if the data is empty
   * @param {import("react").FunctionComponent} [props.splash]
   * @param {import("react").ReactElement} [props.noResult] the element to render if the data is empty
   * @param {boolean} [props.renderNoResultSplash] if true, the no result splash will be rendered
   * @param {import("react").ReactElement} [props.loading] the element to render if the data is loading
   * @param {(children: import("react").ReactNode) => import("react").ReactElement} [props.wrapper] the wrapper that will be used to render any interal elements
   * @param {(response: Exclude<T & {}, undefined>) => import("react").ReactNode} props.children
   */
  function DataHandler({
    swrResponse,
    keepDataOnRevalidation = false,
    shouldRenderWithEmptyData = false,
    renderNoResultSplash = true,
    noResult,
    loading,
    wrapper = (children) => <>{children}</>,
    children,
  }) {
    if (
      swrResponse.data !== undefined &&
      swrResponse.data !== null &&
      (keepDataOnRevalidation || swrResponse.isValidating === false)
    ) {
      const data = swrResponse.data["data"];
      if (
        Array.isArray(data) &&
        data.length === 0 &&
        !shouldRenderWithEmptyData
      ) {
        return renderNoResultSplash
          ? noResult ?? wrapper(<SplashNoResult />)
          : null;
      }
      return <>{children(swrResponse.data)}</>;
    }

    if (!swrResponse.isValidating && swrResponse.error) {
      return wrapper(<SplashError error={swrResponse.error} />);
    }

    if (swrResponse.isValidating) {
      return loading ?? wrapper(loadingElement);
    }

    return null;
  }

  return DataHandler;
}

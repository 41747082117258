import Handler from "./Handler";
import { ShowData } from "@raiden/library/contexts/Show";
import PropTypes from "prop-types";

/**
 * @typedef Props
 * @property {() => void} onClose
 * @property {() => void} onSuccess
 */
/**
 * @param {Props} props
 * @return {import("react").FunctionComponentElement<Props>}
 */
function UpdateData({ onClose, onSuccess }) {
  return (
    <ShowData
      data={() => <Handler onClose={onClose} onSuccess={onSuccess} />}
    />
  );
}

export default UpdateData;

UpdateData.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
};

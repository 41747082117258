// contexts
import { forwardRef } from "react";
import SearchProvider from "./Provider";

/**
 * Permet de créer un provider de recherche sous forme de high-order function.
 * @param {import("./Provider").Props|Function} providerProps
 * @return {Function}
 * @example
 *
 * function Search(props) {
 *   const { form, response, submittedFields } = useSearch();
 *   // ...
 * }
 *
 * export default injectSearch({
 *   endpointUrl: endpointUrl,
 *   searchId: "searchId",
 *   routerSyncUrl: false,
 *   useSessionStorageHydration: false,
 *   defaultValues: {
 *     per_page: 10,
 *   },
 * })(Search)
 */
export default function injectSearch(providerProps) {
  return function (WrappedComponent) {
    return forwardRef(function (wrappedComponentProps, ref) {
      return (
        <SearchProvider
          {...("function" === typeof providerProps
            ? providerProps(wrappedComponentProps)
            : providerProps)}>
          <WrappedComponent ref={ref} {...wrappedComponentProps} />
        </SearchProvider>
      );
    });
  };
}

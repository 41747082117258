// deps
import {
  Badge,
  Box,
  Button,
  Collapse,
  Flex,
  FormControl,
  Icon,
  IconButton,
  Spinner,
  Text,
  useColorModeValue,
  useToast,
  VStack,
} from "@chakra-ui/react";
import PropTypes from "prop-types";
import { useCallback, useEffect, useRef, useState } from "react";
import { useController, useFormContext } from "react-hook-form";
import { IoCloseOutline, IoCloudUploadOutline } from "react-icons/io5";
import { MdCheckCircleOutline } from "react-icons/md";
import { useIntl } from "react-intl";
import {
  FILES_STATE_VALUE_ERROR,
  FILES_STATE_VALUE_LOADING,
  FILES_STATE_VALUE_PENDING,
  FILES_STATE_VALUE_SUCCESS,
  FILES_TYPE_VALUE_FILE,
} from "../../../constants/files";
import getFileOrDirectoryIcon from "../../../helpers/files/getFileOrDirectoryIcon";
import getFileSize from "../../../helpers/files/getFileSize";

// components
import FormErrorMessageRHF from "../FormErrorMessageRHF";
import FormErrorObserverRHF from "../FormErrorObserverRHF";

/**
 * @typedef {object} Rules
 * @property {boolean} [required]
 * @property {number} [maxLength]
 * @property {number} [minLength]
 * @property {number} [max]
 * @property {number} [min]
 * @property {RegExp} [pattern]
 * @property {(value: any) => boolean | Promise<boolean> | string} [validate]
 * @property {boolean} [valueAsNumber]
 * @property {boolean} [valueAsDate]
 * @property {(value: any) => any} [setValueAs]
 * @property {boolean} [disabled]
 * @property {(e: import("react").SyntheticEvent) => void} [onChange]
 * @property {(e: import("react").SyntheticEvent) => void} [onBlur]
 * @property {any} [value]
 *
 * @typedef {object} FileDragAndDropConstraints
 * @property {number} [maxFileSize]
 * @property {Array<string>} [mime_types]
 * @property {number} [maxNumberOfFiles]
 */

/**
 * A component that is used to drag and drop files with React hook form validation
 * @template {import("react-hook-form").FieldValues} TFieldValues
 * @template {import("react-hook-form").FieldPath<TFieldValues>} TName
 * @typedef {object} Props
 * @property {import("react-hook-form").Control<TFieldValues>} [control]
 * @property {TName} name
 * @property {FileDragAndDropConstraints} [constraints]
 * @property {Rules} [rules]
 * @property {string} [type]
 * @property {any} [label]
 * @property {any} [defaultValue]
 * @property {boolean} [isDisabled]
 * @property {boolean} [shouldUnregister]
 * @property {Array<Object>} [fileStates]
 * @property {(fileStates: Array<Object>) => void} [setFileStates]
 * @property {React.ReactNode} [children]
 */

export default function FileDragAndDropRHF(props) {
  const {
    constraints,
    mediaTypes,
    name,
    rules,
    _isDisabled,
    shouldUnregister,
    defaultValue,
    control,
    fileStates,
    setFileStates,
  } = props;

  const toast = useToast();

  const intl = useIntl();

  const form = useFormContext();
  const { watch } = form;

  const { control: defaultControl } = useFormContext();

  const {
    fieldState: { error },
  } = useController({
    name,
    // @ts-ignore
    control: control ?? defaultControl,
    rules,
    shouldUnregister,
    defaultValue: defaultValue ?? [],
  });

  const isDisabled = Boolean(_isDisabled || rules?.disabled);
  const isRequired = Boolean(rules?.required);
  const isInvalid = Boolean(error);

  const commonFieldProps = {
    isDisabled,
    isRequired,
    isInvalid,
  };

  const documentIconColor = useColorModeValue(
    "brandPrimary.600",
    "brandPrimary.300",
  );

  const [dragHover, setDragHover] = useState(false);

  const $dropZone = useRef(/** @type {HTMLDivElement | null} */ (null));
  const $input = useRef(/** @type {HTMLInputElement | null} */ (null));

  const files = watch(name);

  const maxNumberOfFiles = constraints?.maxNumberOfFiles ?? 10;

  const strMimeTypes =
    constraints?.mime_types?.length > 0 &&
    constraints.mime_types
      .map(function (mimeType) {
        return mediaTypes?.[mimeType]?.friendlyName ?? mimeType;
      })
      .join(", ");

  const setFile = useCallback(
    /**
     * Met à jour le fichier si les contraintes sont respectées.
     * @param {File} file
     */
    async function (file) {
      if (!file) {
        return;
      }

      if (constraints?.maxFileSize && file.size > constraints.maxFileSize) {
        displayToastError({
          title: intl.formatMessage(
            {
              defaultMessage:
                "La taille du fichier ne doit pas être supérieure à {maxFilesize}.",
            },
            {
              maxFilesize: getFileSize({
                size: constraints.maxFileSize,
                intl,
              }),
            },
          ),
        });

        return;
      }

      if (
        constraints?.mime_types &&
        !constraints.mime_types.includes(file.type)
      ) {
        displayToastError({
          title: intl.formatMessage(
            {
              defaultMessage:
                "Le format du fichier doit être du type {mimeTypes}.",
            },
            {
              mimeTypes: strMimeTypes,
            },
          ),
        });

        return;
      }

      const currentFiles = watch(name);

      if (setFileStates) {
        const fileState = {
          state: "pending",
          name: file.name,
        };

        setFileStates((prevFileStates) => [...prevFileStates, fileState]);
      }

      form.setValue(
        name,
        currentFiles?.length > 0 ? [...currentFiles, file] : [file],
        {
          shouldDirty: true,
        },
      );
    },
    [
      constraints.maxFileSize,
      constraints.mime_types,
      displayToastError,
      intl,
      strMimeTypes,
      watch,
      name,
      setFileStates,
      form,
    ],
  );

  useEffect(
    function () {
      /**
       * Gère l’entrée d’un élément de la zone de drop.
       * @param {DragEvent} event
       */
      function handleDragEnter(event) {
        if ($dropZone.current === event.target) {
          setDragHover(true);
        }
      }

      /**
       * Gère la sortie d’un élément de la zone de drop.
       * @param {DragEvent} event
       */
      function handleDragLeave(event) {
        if ($dropZone.current === event.target) {
          setDragHover(false);
        }
      }

      /**
       * Gère le laché dans la zone de drop.
       * @param {DragEvent} event
       */
      function handleDrop(event) {
        event.preventDefault();

        if ($dropZone.current === event.target) {
          setDragHover(false);

          const newFiles = Array.from(event.dataTransfer?.files ?? []);

          if (
            newFiles.length > maxNumberOfFiles ||
            newFiles.length + files?.length > maxNumberOfFiles
          ) {
            displayToastError({
              title: intl.formatMessage(
                {
                  defaultMessage:
                    "Vous ne pouvez pas ajouter plus de {maxNumberOfFiles} fichiers.",
                },
                {
                  maxNumberOfFiles: maxNumberOfFiles,
                },
              ),
            });
            return;
          }

          newFiles.forEach((file) => setFile(file));
        }
      }

      /**
       * Gère le laché dans la zone de drop.
       * @param {DragEvent} event
       */
      function handleDrogOver(event) {
        event.preventDefault();
      }

      document.addEventListener("dragenter", handleDragEnter);
      document.addEventListener("dragleave", handleDragLeave);
      document.addEventListener("drop", handleDrop);
      document.addEventListener("dragover", handleDrogOver);

      return function () {
        document.removeEventListener("dragenter", handleDragEnter);
        document.removeEventListener("dragleave", handleDragLeave);
        document.removeEventListener("drop", handleDrop);
        document.removeEventListener("dragover", handleDrogOver);
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [displayToastError, intl, maxNumberOfFiles, setFile],
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  function displayToastError({ title }) {
    toast({
      status: "warning",
      title,
      position: "bottom-right",
    });
  }

  function onRemoveFile(file) {
    if (setFileStates) {
      setFileStates((prevFileStates) =>
        prevFileStates.filter(
          (prevFileState) => prevFileState.name !== file.name,
        ),
      );
    }

    form.setValue(
      name,
      files.filter(function (f) {
        return f !== file;
      }),
    );
  }

  /**
   * @param {import("react").SyntheticEvent<HTMLInputElement>} event
   */
  function handleChange(event) {
    const newFiles = Array.from(event.currentTarget.files ?? []);

    if (
      newFiles.length > maxNumberOfFiles ||
      newFiles.length + files.length > maxNumberOfFiles
    ) {
      displayToastError({
        title: intl.formatMessage(
          {
            defaultMessage:
              "Vous ne pouvez pas ajouter plus de {maxNumberOfFiles} fichiers.",
          },
          {
            maxNumberOfFiles: maxNumberOfFiles,
          },
        ),
      });
      return;
    }

    newFiles.forEach((file) => setFile(file));
  }

  return (
    <VStack alignItems="stretch" w="full">
      {files?.length && (
        <Text color="gray.400" fontSize="0.875rem">
          {intl.formatMessage(
            {
              defaultMessage:
                "{nbrOfFiles, plural, one {1 fichier} other {# fichiers}}",
            },
            {
              nbrOfFiles: files.length,
            },
          )}
        </Text>
      )}

      <Box
        ref={$dropZone}
        maxHeight="25rem"
        overflowY="auto"
        borderWidth="0.125rem"
        borderStyle="dashed"
        borderColor={error ? "red.500" : "gray.500"}
        transition="background-color 160ms linear 0ms"
        bgColor={dragHover ? "orange.100" : undefined}
        px={{ base: "1rem", md: "3.125rem" }}
        py="1rem">
        <FormControl {...commonFieldProps}>
          <input
            ref={$input}
            type="file"
            multiple={true}
            accept={constraints?.mime_types?.join(",")}
            style={{ width: 0, height: 0, opacity: 0, visibility: "hidden" }}
            onChange={handleChange}
            onClick={(event) => {
              event.target["value"] = null;
            }}
          />

          <VStack>
            {files?.length > 0 ? (
              files.map((file, i) => (
                <Flex gap="1rem" w="100%" alignItems="center" key={i}>
                  <Icon
                    as={getFileOrDirectoryIcon({
                      file: {
                        ...file,
                        mime_type: file.type,
                        type: FILES_TYPE_VALUE_FILE,
                      },
                    })}
                  />

                  <Text
                    isTruncated
                    fontSize="xl"
                    textAlign="left"
                    maxWidth="20rem"
                    minWidth="20rem">
                    {file.name}
                  </Text>

                  <Badge
                    fontSize=".85rem"
                    p="4px"
                    borderRadius="8px"
                    marginRight="auto">
                    {getFileSize({ size: file.size, intl })}
                  </Badge>

                  {fileStates?.length > 0 &&
                    (fileStates[i].state === FILES_STATE_VALUE_ERROR ? (
                      <Text
                        colorScheme="red"
                        maxWidth="20rem"
                        isTruncated
                        title={fileStates[i]?.error?.body?.errors?.[0]?.detail}>
                        {fileStates[i]?.error?.body?.errors?.[0]?.detail}
                      </Text>
                    ) : fileStates[i].state === FILES_STATE_VALUE_PENDING ? (
                      <Text>
                        {intl.formatMessage({ defaultMessage: "En attente" })}
                      </Text>
                    ) : fileStates[i].state === FILES_STATE_VALUE_LOADING ? (
                      <Flex alignItems="center" gap=".75rem">
                        <Spinner size="sm" />

                        <Text>
                          {intl.formatMessage({ defaultMessage: "Envoi" })}
                        </Text>
                      </Flex>
                    ) : fileStates[i].state === FILES_STATE_VALUE_SUCCESS ? (
                      <Flex alignItems="center" gap=".75rem" color="green.500">
                        <Icon as={MdCheckCircleOutline} />

                        <Text color="green.500">
                          {intl.formatMessage({ defaultMessage: "Envoyé" })}
                        </Text>
                      </Flex>
                    ) : null)}

                  <IconButton
                    cursor="pointer"
                    onClick={() => onRemoveFile(file)}
                    icon={<Icon as={IoCloseOutline} />}
                    aria-label={intl.formatMessage({
                      defaultMessage: "Supprimer le fichier",
                    })}
                    variant="outline"
                    colorScheme="darkGray"
                    size="sm"
                  />
                </Flex>
              ))
            ) : (
              <Flex alignItems="center" gap="1rem" mb="1rem">
                <Icon
                  as={IoCloudUploadOutline}
                  color={documentIconColor}
                  width="2rem"
                  height="2rem"
                />

                <Text fontSize="xl" textAlign="center">
                  {intl.formatMessage(
                    {
                      defaultMessage:
                        "Ajouter des fichiers ({maxNumberOfFiles} max)",
                    },
                    {
                      maxNumberOfFiles: maxNumberOfFiles,
                    },
                  )}
                </Text>
              </Flex>
            )}

            <FormErrorObserverRHF
              name={name}
              render={({ hasError, error }) => (
                <Collapse in={hasError} unmountOnExit={true}>
                  <FormErrorMessageRHF error={error} />
                </Collapse>
              )}
            />

            <Button
              pointerEvents="all"
              onClick={function () {
                $input.current?.click();
              }}>
              {intl.formatMessage({
                defaultMessage: "Chercher sur mon ordinateur",
              })}
            </Button>
          </VStack>
        </FormControl>
      </Box>

      <Flex justifyContent="space-between">
        <Text color="gray.400" fontSize="0.875rem">
          {constraints?.maxFileSize &&
            intl.formatMessage(
              {
                defaultMessage: "Poids maximal par fichier : {maxFilesize} ",
              },
              {
                maxFilesize: getFileSize({
                  size: constraints.maxFileSize,
                  intl,
                }),
              },
            )}
        </Text>

        {strMimeTypes && (
          <Text color="gray.400" fontSize="0.875rem">
            {intl.formatMessage(
              {
                defaultMessage: "Formats supportés : {mimeTypes}.",
              },
              {
                mimeTypes: strMimeTypes,
              },
            )}
          </Text>
        )}
      </Flex>
    </VStack>
  );
}

FileDragAndDropRHF.propTypes = {
  constraints: PropTypes.object,
  name: PropTypes.string,
  mediaTypes: PropTypes.object,
  onRemoveFile: PropTypes.func,
  rules: PropTypes.object,
  _isDisabled: PropTypes.bool,
  shouldUnregister: PropTypes.bool,
  defaultValue: PropTypes.any,
  control: PropTypes.object,
  fileStates: PropTypes.array,
  setFileStates: PropTypes.func,
};

FileDragAndDropRHF.defaultProps = {};

// deps
import { Box, useColorModeValue } from "@chakra-ui/react";
import useTranslate from "@raiden/library/hooks/useTranslate";
import generateAdminPath from "@raiden/library/libraries/utils/generateAdminPath";
import { useCallback, useMemo } from "react";

// components
import NextLink from "next/link";
import SearchActionsViewFiles from "../../Actions/ViewFiles";
import { SearchGlobalItemTemplate } from "./Template";

// constants
import {
  SEARCH_RESULTS_OBJECT_TYPE_CUSTOMER,
  SEARCH_RESULTS_OBJECT_TYPE_FILE,
  SEARCH_RESULTS_OBJECT_TYPE_POST,
} from "@raiden/library/constants/search/results";
import { FILES_TYPE_VALUE_DIRECTORY } from "@raiden/library/constants/files";

/**
 * @typedef {Object} Props
 * @property {import("@raiden/library/types/search/Result").SearchResult} searchResult
 */
/**
 * @param {Props} props
 */
export default function SearchGlobalItem({ searchResult }) {
  const translate = useTranslate();

  const backgroundColor = useColorModeValue("darkGray.200", "darkGray.600");

  const generateUrlByObjectType = useCallback(
    /**
     * Generate URL by object type
     * @param {Object} params
     * @param {import("@raiden/library/types/search/Result").SearchResult} params.searchResult
     * @return {string | null}
     */
    function generateUrlByObjectType({ searchResult }) {
      switch (searchResult.object_type) {
        case SEARCH_RESULTS_OBJECT_TYPE_FILE:
          if (searchResult.metadata?.type === FILES_TYPE_VALUE_DIRECTORY) {
            return generateAdminPath({
              id: "@admin.files.search",
              query: {
                parent_id: searchResult.object_id,
              },
            });
          }
          return null;
        case SEARCH_RESULTS_OBJECT_TYPE_CUSTOMER:
          return generateAdminPath({
            id: "@admin.customers.view",
            parameters: {
              customerId: searchResult.object_id,
            },
          });
        case SEARCH_RESULTS_OBJECT_TYPE_POST:
          return generateAdminPath({
            id: "@admin.posts.view",
            parameters: {
              postType: searchResult.metadata.type,
              postId: searchResult.object_id,
            },
          });
        default:
          if (translate(searchResult.translations?.url)) {
            return translate(searchResult.translations?.url) ?? null;
          }
          break;
      }
      return null;
    },
    [translate],
  );

  const href = useMemo(
    () => generateUrlByObjectType({ searchResult }),
    [generateUrlByObjectType, searchResult],
  );

  return (
    <Box
      flex="1 0 auto"
      width="270px"
      maxWidth="270px"
      padding=".3rem"
      _hover={{
        backgroundColor: backgroundColor,
        borderRadius: "md",
      }}>
      {(() => {
        switch (searchResult.object_type) {
          case SEARCH_RESULTS_OBJECT_TYPE_FILE:
            if (searchResult.object_id !== null) {
              // If the file is not a directory, we need to open it
              if (searchResult.metadata?.type !== FILES_TYPE_VALUE_DIRECTORY) {
                return (
                  <SearchActionsViewFiles fileId={searchResult.object_id}>
                    {({ disclosure }) => (
                      <Box onClick={disclosure.onOpen} tabIndex={0}>
                        <SearchGlobalItemTemplate
                          searchResult={searchResult}
                          isClickable={true}
                        />
                      </Box>
                    )}
                  </SearchActionsViewFiles>
                );
              }

              // If the file is a directory, we need to redirect to the file page
              if (href !== null) {
                return (
                  <NextLink href={href}>
                    <a>
                      <SearchGlobalItemTemplate
                        searchResult={searchResult}
                        isClickable={true}
                      />
                    </a>
                  </NextLink>
                );
              }
            }
            break;
          default:
            return (
              <>
                {href !== null ? (
                  <NextLink href={href}>
                    <a>
                      <SearchGlobalItemTemplate
                        searchResult={searchResult}
                        isClickable={true}
                      />
                    </a>
                  </NextLink>
                ) : (
                  <SearchGlobalItemTemplate
                    searchResult={searchResult}
                    isClickable={false}
                  />
                )}
              </>
            );
        }
      })()}
    </Box>
  );
}

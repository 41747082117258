import { Box, Button, HStack, Icon, Text } from "@chakra-ui/react";
import { forwardRef } from "react";
import { MdCheck } from "react-icons/md";

export const DefaultOption = forwardRef(
  /**
   * @param {import(".").AutocompleteRenderOptionParams} props
   */
  function CommonOption(
    { value, item, itemValue, name, toggleValue, focusPrev, focusNext },
    ref,
  ) {
    return (
      <Box>
        <Button
          ref={ref}
          onClick={() => {
            toggleValue({ value: itemValue, item });
          }}
          onKeyDown={(event) => {
            if (event.key === "ArrowUp") {
              event.preventDefault();
              focusPrev();
            }
            if (event.key === "ArrowDown") {
              event.preventDefault();
              focusNext();
            }
          }}
          alignItems="center"
          justifyContent="flex-start"
          variant="ghost"
          size="sm"
          pl="1rem"
          pr="1rem"
          w="100%"
          textAlign="left">
          <Text
            flexGrow={1}
            overflow="hidden"
            textOverflow="ellipsis"
            whiteSpace="nowrap"
            as="span">
            {name ?? value}
          </Text>

          {(value === itemValue ||
            // @ts-ignore
            (Array.isArray(value) && value?.includes?.(itemValue))) && (
            <HStack pr=".5rem">
              <Icon as={MdCheck} />
            </HStack>
          )}
        </Button>
      </Box>
    );
  },
);

import { Button, ButtonGroup, ModalBody, ModalFooter } from "@chakra-ui/react";
import {
  useApiError,
  withApiError,
} from "@raiden/library/components/ReactHookForm/ApiError";
import { useShow } from "@raiden/library/contexts/Show";
import useRequest from "@raiden/library/hooks/useRequest";
import generateApiUrl from "@raiden/library/libraries/utils/generateApiUrl";
import {
  filesSerializeCreateOrUpdate,
  fileParse,
} from "@raiden/library/normalizers/files";
import PropTypes from "prop-types";
import { FormProvider, useForm } from "react-hook-form";
import { useIntl } from "react-intl";
import Form from "../../Form/File/Form";

/**
 * @typedef Props
 * @property {() => void} onSuccess
 * @property {() => void} onClose
 */
/**
 * @param {Props} props
 * @return {import("react").FunctionComponentElement<Props>}
 */
function Handler({ onSuccess, onClose }) {
  const intl = useIntl();

  const { request, isLoading, toastSuccess } = useRequest();

  const { resolveApiError } = useApiError();

  /** @type {import("@raiden/library/contexts/Show").UseShow<import("@raiden/library/types/File").File>} */
  const { data: file, mutate } = useShow();

  const form = useForm({
    defaultValues: {
      data: fileParse(file.data),
    },
  });
  const { handleSubmit } = form;

  /**
   * Gère la soumission du formulaire.
   */
  function onSubmit(fields) {
    request(
      generateApiUrl({
        id: "@api.files.update",
        parameters: {
          fileId: file.data.id,
        },
      }),
      {
        method: "POST",
        body: filesSerializeCreateOrUpdate({
          fields: { ...fields },
        }),
      },
      {
        inputMode: "formData",
      },
    )
      .then(function () {
        mutate();
        onSuccess();
        toastSuccess({
          title: intl.formatMessage({
            defaultMessage: "Le fichier a bien été modifié !",
          }),
        });
      })
      .catch((response) => {
        resolveApiError({ response, form });
      });
  }

  return (
    <FormProvider {...form}>
      <ModalBody>
        <form
          onSubmit={handleSubmit(onSubmit)}
          autoComplete="off"
          noValidate
          id={`${file.data.id}`}>
          <Form context="edit" />
        </form>
      </ModalBody>

      <ModalFooter mb="1rem">
        <ButtonGroup>
          <Button variant="outline" onClick={onClose}>
            {intl.formatMessage({
              defaultMessage: "Fermer",
            })}
          </Button>

          <Button
            form={`${file.data.id}`}
            type="submit"
            isLoading={isLoading}
            colorScheme="green">
            {intl.formatMessage({
              defaultMessage: "Enregistrer",
            })}
          </Button>
        </ButtonGroup>
      </ModalFooter>
    </FormProvider>
  );
}

export default withApiError(Handler);

Handler.propTypes = {
  onSuccess: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

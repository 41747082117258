import { useDisclosure } from "@chakra-ui/react";
import { POLICIES_POLICY_VALUE_RETRY } from "@raiden/library/constants/policies";
import useCanPerform from "@raiden/library/hooks/useCanPerform";
import { TaskType } from "@raiden/library/types/Task";
import PropTypes from "prop-types";
import TaskRetry from "../Retry";

/**
 * @callback childrenRender
 * @param {{disclosure: import("@chakra-ui/react").UseDisclosureReturn}} disclosure
 */

/**
 * @typedef {Object} Props
 * @property {import("@raiden/library/types/Task").Task} resource
 * @property {() => void} onSuccess
 * @property {childrenRender} children
 * @return {import("react").FunctionComponentElement<Props>}
 *
 * @param {Props} props
 */

function TasksActionsRetry(props) {
  const { resource, onSuccess, children } = props;

  const disclosure = useDisclosure();

  const { isOpen, onClose } = disclosure;

  /**
   * Gère le succès de la relance d'une tâche
   */
  function handleSuccess() {
    onClose();
    onSuccess();
  }

  const canPerform = useCanPerform();

  const taskPolicy = resource?.meta?.policies ?? [];

  return (
    <>
      {canPerform({
        policies: POLICIES_POLICY_VALUE_RETRY,
        resourcePolicies: taskPolicy,
      }) && (
        <>
          <TaskRetry
            task={resource}
            isOpen={isOpen}
            onClose={onClose}
            onSuccess={handleSuccess}
          />

          {children({ disclosure })}
        </>
      )}
    </>
  );
}

TasksActionsRetry.propTypes = {
  children: PropTypes.func,
  onSuccess: PropTypes.func,
  resource: TaskType,
};

export default TasksActionsRetry;

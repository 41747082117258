import { defineMessage } from "react-intl";
import {
  RESOURCES_COVERIZABLE_OBJECT_TYPE_VALUE_POSTS_REVISIONS_ARTICLE,
  RESOURCES_COVERIZABLE_OBJECT_TYPE_VALUE_POSTS_REVISIONS_ASSOCIATION,
  RESOURCES_COVERIZABLE_OBJECT_TYPE_VALUE_POSTS_REVISIONS_EVENT,
  RESOURCES_COVERIZABLE_OBJECT_TYPE_VALUE_POSTS_REVISIONS_PAGE,
} from "../resources";

export const POSTS_TYPE_VALUE_PAGE = "page";
export const POSTS_TYPE_VALUE_ARTICLE = "article";
export const POSTS_TYPE_VALUE_EVENT = "event";
export const POSTS_TYPE_VALUE_ASSOCIATION = "association";

export const POSTS_TYPES = {
  [POSTS_TYPE_VALUE_PAGE]: {
    id: POSTS_TYPE_VALUE_PAGE,
    objectType: RESOURCES_COVERIZABLE_OBJECT_TYPE_VALUE_POSTS_REVISIONS_PAGE,
  },
  [POSTS_TYPE_VALUE_ARTICLE]: {
    id: POSTS_TYPE_VALUE_ARTICLE,
    objectType: RESOURCES_COVERIZABLE_OBJECT_TYPE_VALUE_POSTS_REVISIONS_ARTICLE,
  },
  [POSTS_TYPE_VALUE_EVENT]: {
    id: POSTS_TYPE_VALUE_EVENT,
    objectType: RESOURCES_COVERIZABLE_OBJECT_TYPE_VALUE_POSTS_REVISIONS_EVENT,
  },
  [POSTS_TYPE_VALUE_ASSOCIATION]: {
    id: POSTS_TYPE_VALUE_ASSOCIATION,
    objectType:
      RESOURCES_COVERIZABLE_OBJECT_TYPE_VALUE_POSTS_REVISIONS_ASSOCIATION,
  },
};

export const POSTS_TYPE_LIST = Object.values(POSTS_TYPES);

export const postsTypeMessage = defineMessage({
  id: "raiden.library.constants.posts.type",
  defaultMessage:
    "{type, select, page {page} article {article} event {événement} association {association} other {{type}}}",
});

export const postsTypePluralMessage = defineMessage({
  id: "raiden.library.constants.posts.type.plural",
  defaultMessage:
    "{type, select, page {pages} article {articles} event {événements} association {associations} other {{type}}}",
});

export const postsTypePartitiveElisiveSingularMessage = defineMessage({
  id: "raiden.library.constants.posts.type.partitive.elisive.singular",
  defaultMessage:
    "{type, select, page {d'une page} article {d'un article} event {d'un événement} association {d'une association} other {d'un/d'une {type}}}",
});

export const postsTypePartitiveSingularMessage = defineMessage({
  id: "raiden.library.constants.posts.type.partitive.singular",
  defaultMessage:
    "{type, select, page {de page} article {d’article} event {d'événement} association {d'association} other {d'un/d'une {type}}}",
});

export const postsTypePartitivePluralMessage = defineMessage({
  id: "raiden.library.constants.posts.type.partitive.plural",
  defaultMessage:
    "{type, select, page {des pages} article {des articles} event {des événements} association {des associations} other {des {type}}}",
});

export const postsTypeIndefiniteSingularMessage = defineMessage({
  id: "raiden.library.constants.posts.type.indefinite.singular",
  defaultMessage:
    "{type, select, page {une page} article {un article} event {une événement} association {une association} other {un/une {type}}}",
});

export const postsTypeDefiniteSingularMessage = defineMessage({
  id: "raiden.library.constants.posts.type.definite.singular",
  defaultMessage:
    "{type, select, page {la page} article {l’article} event {l'événement} association {l'association} other {le/la {type}}}",
});

export const postsTypeDefinitePluralMessage = defineMessage({
  id: "raiden.library.constants.posts.type.indefinite.plural",
  defaultMessage:
    "{type, select, page {les pages} article {les articles} event {les événements} association {les associations} other {les {type}}}",
});

export const postsTypeDemonstrativeSingularMessage = defineMessage({
  id: "raiden.library.constants.posts.type.demonstrative.singular",
  defaultMessage:
    "{type, select, page {cette page} article {cet article} event {cet événement} association {cette association} other {les {type}}}",
});

export const postsTypeDemonstrativePluralMessage = defineMessage({
  id: "raiden.library.constants.posts.type.demonstrative.plural",
  defaultMessage:
    "{type, select, page {ces pages} article {ces articles} event {ces événements} association {ces associations} other {les {type}}}",
});

export const postsTypePossessiveSingularMessage = defineMessage({
  id: "raiden.library.constants.posts.type.possessive.singular",
  defaultMessage:
    "{type, select, page {votre page} article {votre article} event {votre événement} association {votre association} other {votre {type}}}",
});

export const postsTypePossessivePluralMessage = defineMessage({
  id: "raiden.library.constants.posts.type.possessive.plural",
  defaultMessage:
    "{type, select, page {vos pages} article {vos articles} event {vos événements} association {vos associations} other {vos {type}}}",
});

// États

export const POSTS_STATE_VALUE_DRAFT = "draft";
export const POSTS_STATE_VALUE_PUBLISHED = "published";
export const POSTS_STATE_VALUE_DISABLED = "disabled";

export const POSTS_STATES = {
  [POSTS_STATE_VALUE_DRAFT]: {
    id: POSTS_STATE_VALUE_DRAFT,
    realColor: ["gray.600", "gray.500"],
    color: "gray",
    io5Name: "IoPencilOutline",
  },
  [POSTS_STATE_VALUE_PUBLISHED]: {
    id: POSTS_STATE_VALUE_PUBLISHED,
    realColor: ["green.400", "green.400"],
    color: "green",
    io5Name: "IoPlayOutline",
  },
  [POSTS_STATE_VALUE_DISABLED]: {
    id: POSTS_STATE_VALUE_DISABLED,
    realColor: ["purple.600", "purple.600"],
    color: "purple",
    io5Name: "IoPauseOutline",
  },
};

export const POSTS_STATE_LIST = Object.values(POSTS_STATES);

export const postsStateMessage = defineMessage({
  id: "raiden.library.constants.posts.state",
  defaultMessage:
    "{state, select, draft {Brouillon} published {Publié} disabled {Désactivé} other {{type}}}",
});

export const postsStateVerbMessage = defineMessage({
  id: "raiden.library.constants.posts.state.verb",
  defaultMessage:
    "{state, select, draft {passer en brouillon} published {publier} disabled {désactiver} other {{type}}}",
});

export const postsStateParticipleMessage = defineMessage({
  id: "raiden.library.constants.posts.state.participle",
  defaultMessage:
    "{state, select, draft {passé(e) en brouillon} published {publié(e)} disabled {désactivé(e)} other {{type}}}",
});

// Tris

export const POSTS_SORT_VALUE_CREATED_ASC = "created_asc";
export const POSTS_SORT_VALUE_CREATED_DESC = "created_desc";
export const POSTS_SORT_VALUE_UPDATED_ASC = "updated_asc";
export const POSTS_SORT_VALUE_UPDATED_DESC = "updated_desc";
export const POSTS_SORT_VALUE_POSITION_ASC = "position_asc";
export const POSTS_SORT_VALUE_POSITION_DESC = "position_desc";
export const POSTS_SORT_VALUE_PUBLICATION_DATE_ASC = "publication_date_asc";
export const POSTS_SORT_VALUE_PUBLICATION_DATE_DESC = "publication_date_desc";

export const POSTS_SORTS = {
  [POSTS_SORT_VALUE_CREATED_ASC]: {
    id: POSTS_SORT_VALUE_CREATED_ASC,
  },
  [POSTS_SORT_VALUE_CREATED_DESC]: {
    id: POSTS_SORT_VALUE_CREATED_DESC,
  },
  [POSTS_SORT_VALUE_UPDATED_ASC]: {
    id: POSTS_SORT_VALUE_UPDATED_ASC,
  },
  [POSTS_SORT_VALUE_UPDATED_DESC]: {
    id: POSTS_SORT_VALUE_UPDATED_DESC,
  },
  [POSTS_SORT_VALUE_POSITION_ASC]: {
    id: POSTS_SORT_VALUE_POSITION_ASC,
  },
  [POSTS_SORT_VALUE_POSITION_DESC]: {
    id: POSTS_SORT_VALUE_POSITION_DESC,
  },
  [POSTS_SORT_VALUE_PUBLICATION_DATE_ASC]: {
    id: POSTS_SORT_VALUE_PUBLICATION_DATE_ASC,
  },
  [POSTS_SORT_VALUE_PUBLICATION_DATE_DESC]: {
    id: POSTS_SORT_VALUE_PUBLICATION_DATE_DESC,
  },
};

export const POSTS_SORT_LIST = Object.values(POSTS_SORTS);

export const POSTS_SORT_MESSAGE = defineMessage({
  id: "raiden.library.constants.posts.sort",
  defaultMessage:
    "{sort, select, created_asc {Date de création croissante} created_desc {Date de création décroissante} publication_date_asc {Date de publication croissante} publication_date_desc {Date de publication décroissante} updated_asc {Date de modification croissante} updated_desc {Date de modification décroissante} position_asc {Position croissante} position_desc {Position décroissante} other {{type}}}",
});

// Tris par date de l'événement
export const POSTS_SORT_DATE_VALUE_EVENT_DATE_TODAY = "today";
export const POSTS_SORT_DATE_VALUE_EVENT_DATE_WEEKEND = "weekend";
export const POSTS_SORT_DATE_VALUE_EVENT_DATE_WEEK = "week";
export const POSTS_SORT_DATE_VALUE_EVENT_DATE_MONTH = "month";
export const POSTS_SORT_DATE_VALUE_EVENT_DATE_YEAR = "year";

export const POSTS_SORT_DATE = {
  [POSTS_SORT_DATE_VALUE_EVENT_DATE_TODAY]: {
    id: POSTS_SORT_DATE_VALUE_EVENT_DATE_TODAY,
    label: defineMessage({ defaultMessage: "aujourd'hui" }),
  },
  [POSTS_SORT_DATE_VALUE_EVENT_DATE_WEEKEND]: {
    id: POSTS_SORT_DATE_VALUE_EVENT_DATE_WEEKEND,
    label: defineMessage({ defaultMessage: "ce week-end" }),
  },
  [POSTS_SORT_DATE_VALUE_EVENT_DATE_WEEK]: {
    id: POSTS_SORT_DATE_VALUE_EVENT_DATE_WEEK,
    label: defineMessage({ defaultMessage: "cette semaine" }),
  },
  [POSTS_SORT_DATE_VALUE_EVENT_DATE_MONTH]: {
    id: POSTS_SORT_DATE_VALUE_EVENT_DATE_MONTH,
    label: defineMessage({ defaultMessage: "ce mois" }),
  },
  [POSTS_SORT_DATE_VALUE_EVENT_DATE_YEAR]: {
    id: POSTS_SORT_DATE_VALUE_EVENT_DATE_YEAR,
    label: defineMessage({ defaultMessage: "cette année" }),
  },
};

export const POSTS_SORT_DATE_LIST = Object.values(POSTS_SORT_DATE);

// Mode de sauvegarde (côté front)

export const POSTS_FRONT_FORM_SUBMIT_MODE_VALUE_SAVE_AS_DRAFT = "saveAsDraft";
export const POSTS_FRONT_FORM_SUBMIT_MODE_VALUE_REQUEST_REVIEW =
  "requestReview";
export const POSTS_FRONT_FORM_SUBMIT_MODE_VALUE_PROGRAMMATE_PUBLISH =
  "programmatePublish";
export const POSTS_FRONT_FORM_SUBMIT_MODE_VALUE_PUBLISH = "publish";

export const POSTS_FRONT_FORM_SUBMIT_MODES = {
  [POSTS_FRONT_FORM_SUBMIT_MODE_VALUE_SAVE_AS_DRAFT]: {
    id: POSTS_FRONT_FORM_SUBMIT_MODE_VALUE_SAVE_AS_DRAFT,
  },
  [POSTS_FRONT_FORM_SUBMIT_MODE_VALUE_REQUEST_REVIEW]: {
    id: POSTS_FRONT_FORM_SUBMIT_MODE_VALUE_REQUEST_REVIEW,
  },
  [POSTS_FRONT_FORM_SUBMIT_MODE_VALUE_PROGRAMMATE_PUBLISH]: {
    id: POSTS_FRONT_FORM_SUBMIT_MODE_VALUE_PROGRAMMATE_PUBLISH,
  },
  [POSTS_FRONT_FORM_SUBMIT_MODE_VALUE_PUBLISH]: {
    id: POSTS_FRONT_FORM_SUBMIT_MODE_VALUE_PUBLISH,
  },
};

export const POSTS_FRONT_FORM_SUBMIT_MODE_LIST = Object.values(
  POSTS_FRONT_FORM_SUBMIT_MODES,
);

export const postsFrontFormSubmitModeMessage = defineMessage({
  id: "raiden.library.constants.posts.frontFormSubmitMode",
  defaultMessage:
    "{frontFormSubmitMode, select, saveAsDraft {Enregistrer comme brouillon} requestReview {Envoyer en modération} programmatePublish {Programmer la publication} publish {Publier immédiatement} other {{type}}}",
});

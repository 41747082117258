import Data from "./Data";
import { useIntl } from "react-intl";
import { ShowProvider } from "@raiden/library/contexts/Show";
import generateApiUrl from "@raiden/library/libraries/utils/generateApiUrl";
import PropTypes from "prop-types";
import {
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";

function endpointUrl(id) {
  return generateApiUrl({
    id: "@api.files.view",
    parameters: {
      fileId: id,
    },
    query: {
      fields: [""],
    },
  });
}

/**
 * @typedef Props
 * @property {number} id
 * @property {() => void} onClose
 * @property {boolean} isOpen
 * @property {() => void} onSuccess
 * @property {boolean} [isInModal]
 */
/**
 * @param {Props} props
 * @return {import("react").FunctionComponentElement<Props>}
 */
export default function UpdateFile(props) {
  const { id, isOpen, onClose, onSuccess, isInModal = false } = props;

  const intl = useIntl();

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size="4xl"
      scrollBehavior="inside"
      closeOnOverlayClick={false}
      blockScrollOnMount={!isInModal}
      isCentered>
      <ModalOverlay />

      <ModalContent>
        <ModalCloseButton />

        <ModalHeader>
          {intl.formatMessage({
            defaultMessage: "Modifier le fichier",
          })}
        </ModalHeader>

        <ShowProvider endpointUrl={endpointUrl(id)}>
          <Data onSuccess={onSuccess} onClose={onClose} />
        </ShowProvider>
      </ModalContent>
    </Modal>
  );
}

UpdateFile.propTypes = {
  id: PropTypes.number.isRequired,
  onClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onSuccess: PropTypes.func.isRequired,
  isInModal: PropTypes.bool,
};

import { Box } from "@chakra-ui/react";
import ResourceLoading from "@splitfire-agency/raiden-library/dist/components/Resource/Loading";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";
import { useShow } from ".";
import Error from "../../components/Error";

export default function ShowData({ data }) {
  const { data: response, error, loading } = useShow();

  const intl = useIntl();

  if (!error && response?.data) {
    if (typeof data === "function") {
      return data();
    }
    return data;
  }

  if (loading) {
    return (
      <Box p="1rem">
        <ResourceLoading
          label={intl.formatMessage({
            id: "raiden.admin.contexts.Show.Data.texts.loading.title",
            defaultMessage: "Chargement de la ressource…",
          })}
        />
      </Box>
    );
  }

  return <Error.Global type="view" error={error} />;
}

ShowData.propTypes = {
  data: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
};

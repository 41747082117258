import { createContext, useCallback, useMemo } from "react";
import useSWR from "swr";
import { usersCan, usersIsRoot } from "../../helpers/users";
import { browserApiFetcher } from "../../hooks/useApiFetcher";
import generateApiUrl from "../../libraries/utils/generateApiUrl";

/**
 * @callback mutateType
 * @param {object} [param]
 * @returns {void}
 */

/**
 * @template [T=any]
 * @typedef {object} AuthContextValue
 * @property {boolean} loading
 * @property {boolean} logged
 * @property {T} [user]
 * @property {mutateType} mutate
 * @property {(user: import("../../types/User").User) => void} login
 * @property {() => void} logout
 * @property {(params: { authorizations: any, environmentId: any }) => boolean} can
 * @property {boolean} root
 */

/** @type {AuthContextValue} */
const DefaultValue = {
  loading: false,
  logged: false,
  user: undefined,
  mutate: () => {},
  login: () => {},
  logout: () => {},
  can: () => false,
  root: false,
};

/** @type {React.Context<AuthContextValue>} */
export const AuthContext = createContext(DefaultValue);

/**
 * @typedef {object} Props
 * @property {import("../../types/User").User} [initialUser]
 * @property {boolean} isEnabled
 * @property {import("react").ReactNode} children
 *
 * @param {Props} props
 */
function AuthProvider({ initialUser, isEnabled, children }) {
  const { data, mutate, isLoading } = useSWR(
    isEnabled
      ? generateApiUrl({
          id: "@api.auth.me",
          query: {
            fields: ["user.avatar", "customer.default_address", "groups"],
          },
        })
      : null,
    browserApiFetcher,
    {
      revalidateOnMount: false,
      revalidateOnFocus: true,
      refreshInterval: 0,
      dedupingInterval: 0,
      keepPreviousData: true,
      fallbackData: isEnabled // we don’t want to fetch the user if the auth is disabled
        ? {
            data: initialUser,
          }
        : undefined,
    },
  );

  const user = data?.data;

  const logged = user?.id !== undefined;

  const root = usersIsRoot({ user });

  /**
   * Déconnecte l’utilisateur.
   * À n’appeler qu’après avoir invoqué l’endpoint de
   *   déconnexion ou après avoir été déconnecté naturellement (par expiration).
   */
  const logout = useCallback(
    function () {
      mutate();
    },
    [mutate],
  );

  /**
   * Connecte l’utilisateur.
   */
  const login = useCallback(
    function (user) {
      mutate(user);
    },
    [mutate],
  );

  const can = useCallback(
    function ({ authorizations, environmentId }) {
      return usersCan({ authorizations, environmentId, user });
    },
    [user],
  );

  const value = useMemo(() => {
    const value = {
      loading: isLoading,
      logged,
      user: user?.id ? user : undefined,
      mutate,
      login,
      logout,
      can,
      root,
    };
    return value;
  }, [can, isLoading, logged, login, logout, mutate, root, user]);

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export default AuthProvider;

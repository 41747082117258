import {
  Alert,
  AlertDescription,
  AlertTitle,
  Box,
  Collapse,
  ListItem,
  UnorderedList,
} from "@chakra-ui/react";
import { useMemo } from "react";
import { useIntl } from "react-intl";
import { useApiError } from "../ApiError";

/**
 * @param {import("@chakra-ui/react").BoxProps & {
 * withCaughtErrors?: boolean,
 * }} props
 */
function ApiErrorMessage({ withCaughtErrors = false, ...otherProps }) {
  const intl = useIntl();

  const { message, uncaughtErrors, caughtErrors } = useApiError();

  const uncaughtErrorsList = useMemo(() => {
    return Object.values(uncaughtErrors);
  }, [uncaughtErrors]);

  const caughtErrorsList = useMemo(() => {
    return Object.values(caughtErrors);
  }, [caughtErrors]);

  return (
    <Collapse
      in={
        Boolean(message) ||
        (withCaughtErrors && caughtErrorsList.length > 0) ||
        uncaughtErrorsList.length > 0
      }
      unmountOnExit={true}>
      <Box pb="1rem" {...otherProps}>
        <Alert status="error" variant="top-accent">
          <AlertTitle display="block">
            {message ??
              intl.formatMessage({
                defaultMessage: "Les données spécifiées sont incorrectes",
              })}
          </AlertTitle>
        </Alert>

        {(uncaughtErrorsList?.length > 0 ||
          (withCaughtErrors && caughtErrorsList?.length > 0)) && (
          <Alert status="error">
            <AlertDescription>
              <UnorderedList display="block" opacity={0.75}>
                {uncaughtErrorsList?.map?.((value, index) => (
                  <ListItem key={index}>{value}</ListItem>
                ))}

                {withCaughtErrors && (
                  <>
                    {caughtErrorsList?.map?.((value, index) => (
                      <ListItem key={index}>{value}</ListItem>
                    ))}
                  </>
                )}
              </UnorderedList>
            </AlertDescription>
          </Alert>
        )}
      </Box>
    </Collapse>
  );
}

export default ApiErrorMessage;

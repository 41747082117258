// deps
import {
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  WrapItem,
} from "@chakra-ui/react";
import PropTypes from "prop-types";
import { MdSearch } from "react-icons/md";
import { useIntl } from "react-intl";

// components
import FormControlRHF from "@raiden/library/components/ReactHookForm/FormController";
import EnvironmentSelect from "../EnvironmentSelectWithAuthorizations";

// contexts
import { usePreferences } from "@raiden/library/contexts/Preferences";
import { useSearch } from "@raiden/library/contexts/Search";

/**
 * @typedef {object} Props
 * @property {boolean} [withTerm]
 * @property {boolean} [withTermLabel]
 * @property {string} [termPlaceholder]
 * @property {boolean} [withEnvironments]
 * @property {string} [environmentPlaceholder]
 * @property {boolean} [shouldBypassRenderEnvironments]
 */

/**
 * @param {Props} props
 * @return {import("react").FunctionComponentElement<Props>}
 */
function SearchCommonFilterListRHF(props) {
  const {
    withTerm = false,
    withTermLabel = true,
    termPlaceholder,
    withEnvironments = false,
    environmentPlaceholder,
    shouldBypassRenderEnvironments = false,
  } = props;

  const intl = useIntl();

  const { searchId } = useSearch();

  const { shouldRenderEnvironments } = usePreferences();

  return (
    <>
      {withTerm && (
        <WrapItem>
          <FormControlRHF
            name="term"
            {...(withTermLabel && {
              label: intl.formatMessage({
                defaultMessage: "Rechercher",
              }),
            })}
            labelProps={{ "data-search-id": searchId }}
            placeholder={termPlaceholder}
            defaultValue="">
            {(props) => (
              <InputGroup>
                <InputRightElement
                  zIndex="auto"
                  pointerEvents="none"
                  children={<Icon as={MdSearch} />}
                />

                <Input {...props} type="search" placeholder={termPlaceholder} />
              </InputGroup>
            )}
          </FormControlRHF>
        </WrapItem>
      )}

      {withEnvironments &&
        (shouldRenderEnvironments || shouldBypassRenderEnvironments) && (
          <WrapItem>
            <FormControlRHF
              labelProps={{ "data-search-id": searchId }}
              name="environment_id"
              defaultValue=""
              label={intl.formatMessage({
                defaultMessage: "Environnement",
              })}
              placeholder={environmentPlaceholder}>
              <EnvironmentSelect isMultiple={true} placeholder="-" />
            </FormControlRHF>
          </WrapItem>
        )}
    </>
  );
}

SearchCommonFilterListRHF.propTypes = {
  withTerm: PropTypes.bool,
  withEnvironments: PropTypes.bool,
  children: PropTypes.node,
  termPlaceholder: PropTypes.string,
  environmentPlaceholder: PropTypes.string,
  shouldBypassRenderEnvironments: PropTypes.bool,
};

export default SearchCommonFilterListRHF;

import PropTypes from "prop-types";
import { useDisclosure } from "@chakra-ui/react";
import FilesDelete from "../Delete";
import {
  POLICIES_POLICY_VALUE_DELETE,
  POLICIES_POLICY_VALUE_SOFT_DELETE,
} from "@raiden/library/constants/policies";
import useCanPerform from "@raiden/library/hooks/useCanPerform";
import { FileType } from "@raiden/library/types/File";

export function canPerformFileDelete({ policies, canPerform }) {
  return (
    canPerform({
      policies: POLICIES_POLICY_VALUE_SOFT_DELETE,
      resourcePolicies: policies,
    }) ||
    canPerform({
      policies: POLICIES_POLICY_VALUE_DELETE,
      resourcePolicies: policies,
    })
  );
}

/**
 * @callback childrenRender
 * @param {{disclosure: import("@chakra-ui/react").UseDisclosureReturn}} disclosure
 */

/**
 * @typedef {Object} Props
 * @property {import("@raiden/library/types/File").File} resource
 * @property {() => void} onSuccess
 * @property {childrenRender} children
 * @property {boolean} [isInModal]
 */
/**
 * @param {Props} props
 * @return {import("react").FunctionComponentElement<Props>}
 */
function FilesActionsDelete(props) {
  const { resource, onSuccess, children, isInModal = false } = props;

  const disclosure = useDisclosure();

  const { isOpen, onClose } = disclosure;

  /**
   * Gère le succès de la suppression d'un menu
   */
  function handleSuccess() {
    onClose();
    onSuccess();
  }

  const canPerform = useCanPerform();

  return (
    <>
      {canPerformFileDelete({
        policies: resource?.meta?.policies,
        canPerform,
      }) && (
        <>
          <FilesDelete
            file={resource}
            isOpen={isOpen}
            onClose={onClose}
            onSuccess={handleSuccess}
            isInModal={isInModal}
          />

          {children({ disclosure })}
        </>
      )}
    </>
  );
}

FilesActionsDelete.propTypes = {
  children: PropTypes.func,
  onSuccess: PropTypes.func,
  resource: FileType.isRequired,
  isInModal: PropTypes.bool,
};

export default FilesActionsDelete;

import { Button, Icon, Tag } from "@chakra-ui/react";
import { useCallback } from "react";
import { MdOutlineTimelapse } from "react-icons/md";
import { useIntl } from "react-intl";
import ActionTaskViewAny from "../../../containers/Tasks/Actions/ViewAny";
import { useTaskDrawer } from "../../../hooks/useTaskDrawer";

const MAX_NUMBER_OF_TASKS_TO_DISPLAY = 9;

export default function TasksButton() {
  const { onTaskDrawerOpen, numberOfStartedTasks } = useTaskDrawer();

  const intl = useIntl();

  const handleClick = useCallback(() => {
    onTaskDrawerOpen();
  }, [onTaskDrawerOpen]);

  return (
    <ActionTaskViewAny>
      <Button
        position="relative"
        size="md"
        onClick={handleClick}
        variant="outline"
        aria-label={intl.formatMessage({
          defaultMessage: "Voir les tâches",
        })}>
        <Icon as={MdOutlineTimelapse} />

        {numberOfStartedTasks > 0 && (
          <Tag
            variant="solid"
            opacity="1"
            background="brandPrimary.500"
            position="absolute"
            borderRadius="full"
            top="-10px"
            right="-15px">
            {numberOfStartedTasks > MAX_NUMBER_OF_TASKS_TO_DISPLAY
              ? "9+"
              : numberOfStartedTasks}
          </Tag>
        )}
      </Button>
    </ActionTaskViewAny>
  );
}
